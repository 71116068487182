import React, { useEffect } from 'react';
import { PropertyCard } from './components/property-list-card';

import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useMediaQuery,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Add, Search, Close } from '@mui/icons-material';
import {
  useInjectableComponents,
  BottomMenuItems,
} from '../../system/services/injectableComponentsManager';
import { useProperties } from '../../system/EntityManager';
import { Notifier } from '../../system/services/notificationManager';

export function PropertiesList() {
  const { properties, loading, error } = useProperties();

  useEffect(() => {
    if (error) {
      Notifier.error('There was a problem fetching the property list: ', error);
    }
  }, [error]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('q');

  const numProperties = properties.length ?? 0;
  const hideSearchBar = loading || !!error || numProperties < 10;

  const filteredProperties =
    properties.filter((p) =>
      search && search.length >= 3
        ? p.title.toLowerCase().includes(search.toLowerCase())
        : true,
    ) ?? [];

  const { setContextMenuItems } = useInjectableComponents();

  useEffect(() => {
    const bottomMenu: BottomMenuItems = [
      {
        items: [
          {
            id: 'menu-item-add-property',
            label: 'Add Property',
            icon: <Add fontSize="small" />,
            action: () => navigate('/properties/create'),
            permit: {
              action: 'create',
              subject: 'Property',
            },
          },
        ],
      },
    ];
    setContextMenuItems(bottomMenu);

    return () => {
      setContextMenuItems(undefined);
    };
  }, [navigate, setContextMenuItems]);

  function renderLoadingSkeletons() {
    const loadingSkeletons = [];
    for (let i = 0; i < 3; i++) {
      loadingSkeletons.push(
        // Same styling as the divs that wrap each PropertyCard
        <div
          key={i}
          style={{
            padding: isSmall ? '6px' : '10px',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 400px',
            alignItems: 'center',
          }}
        >
          <Skeleton
            variant="rounded"
            height={isSmall ? 200 : 224}
            width={isSmall ? 350 : 400}
          />
        </div>,
      );
    }
    return loadingSkeletons;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        alignItems: 'center',
        overflow: 'scroll',
        flex: 1,
        width: '100%',
        backgroundColor: 'rgb(228 244 248 / 60%)',
      }}
    >
      <div
        style={{
          marginTop: '20px',
          width: isSmall ? 350 : 400,
        }}
      >
        <SearchBar hidden={hideSearchBar} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '10px',
          marginTop: '10px',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        {error ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              padding: 20,
            }}
          >
            Error encountered loading property data: {error.message}.
          </div>
        ) : loading ? (
          renderLoadingSkeletons()
        ) : filteredProperties.length > 0 ? (
          filteredProperties.map((property, index) => (
            <div
              key={index}
              style={{
                padding: isSmall ? '6px' : '10px',
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 400px',
                alignItems: 'center',
              }}
            >
              <PropertyCard key={index} property={property} detailButton />
            </div>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              padding: 20,
            }}
          >
            No properties found
          </div>
        )}
      </div>
    </div>
  );
}

function SearchBar(props: { hidden: boolean }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('q');

  return !props.hidden ? (
    <TextField
      id="property"
      type="text"
      variant="outlined"
      color="secondary"
      size="small"
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setSearchParams({})}
              edge="end"
              disabled={!search}
              color="secondary"
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={search ?? ''}
      onChange={(e) => setSearchParams({ q: e.target.value })}
      sx={{
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 1,
      }}
    />
  ) : null;
}
