import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  useVsdPumpDetailQuery,
  useVsdPumpDetailUpdateSubscription,
  VsdPump,
} from '../../../../types/generated-types';
import BorderedSection from '../../shared/borderedSection';
import { DisplayValue } from '../shared-ui';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import { Notifier } from '../../../system/services/notificationManager';
import dayjs from 'dayjs';
import { PoweredDownDevice } from '../shared-ui/powered-down-device';
import { useDeviceIsOnline } from '../../../system/AlertsManager';

export function VsdPumpComponent({
  deviceId,
  // TODO: CleanUp: if we don't need these props, remove it.
  // onLoadingStateChange,
  // onLoadingError,
  onOperatingModeChange = (newOperatingMode: number) => {
    // TODO: CleanUp: we should have a better default for this or make it required that it be provided.
    console.log('onOperatingModeChange', newOperatingMode);
  },
  onAwaitingChange = (newAwaiting: boolean) => {
    // TODO: CleanUp: we should have a better default for this or make it required that it be provided.
    console.log('onAwaitingChange', newAwaiting);
  },
  editing = false,
  value = 0,
  editingEnabled = false,
}: {
  deviceId: string;
  editing: boolean;
  value: number;
  onLoadingStateChange?: (
    isLoading: ReturnType<typeof useVsdPumpDetailQuery>['loading'],
  ) => void;
  onLoadingError?: (error: boolean) => void;
  onOperatingModeChange?: (newOperatingMode: number) => void;
  onAwaitingChange?: (newAwaiting: boolean) => void;
  editingEnabled?: boolean;
}) {
  const [device, setDevice] = useState<Partial<VsdPump>>({});
  const [subtitle, setSubtitle] = useState('');
  const [isPoweredOff, setPoweredOff] = useState<boolean>(false);
  const {
    data,
    // TODO: Loading_error: do we need to handle loading/errors for this query?
    // loading,
    // error,
  } = useVsdPumpDetailQuery({
    variables: { id: deviceId },
    fetchPolicy: 'network-only',
  });
  const [newOperatingMode, setNewOperatingMode] = useState<boolean>(
    value !== 0,
  );

  useVsdPumpDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const isOnline = useDeviceIsOnline(device);

  useEffect(() => {
    if (data?.vSDPumpById?._id) {
      setDevice(data.vSDPumpById as Partial<VsdPump>);
    }
  }, [data, onAwaitingChange]);

  useEffect(() => {
    if (!editingEnabled && device) {
      const newVal = value === -1 ? !!device.stopOrStart?.value : !!value;
      setNewOperatingMode(newVal);
      onOperatingModeChange(newVal ? 1 : 0);
    }
  }, [editingEnabled, device, value, onOperatingModeChange]);

  useEffect(() => {
    if (device) {
      const pending = device.hasPendingUpdates;
      if (pending === undefined) {
        Notifier.warn(
          'VSD Pump query resulted in inconsistent data. Please contact Embue Support.',
        );
      } else {
        onAwaitingChange(pending);
      }
      setPoweredOff(!device.isPoweredOn);
    }
  }, [device, onAwaitingChange, setPoweredOff]);

  const deviceName = device?.name ?? device?.meta?.name;
  const deviceLabel = deviceName ? ` (${deviceName})` : '';
  const driveMode = device?.driveMode?.value || 'Auto';
  const deviceIsOn = 1 === device?.stopOrStart?.value;

  useEffect(() => {
    // driveMode: off, hand and auto.
    // off: drive mode is off.
    // hand: drive mode is hand.
    // standby: drive mode is auto and stopOrStart is off
    // active:  drive mode is auto and stopOrStart is on
    const mySubTitle = isPoweredOff
      ? 'Powered Off'
      : !isOnline
        ? `Offline since ${dayjs(device?.timestamp).format('LLL')}`
        : driveMode === 'Auto'
          ? deviceIsOn
            ? 'Active'
            : 'Standby'
          : driveMode;

    setSubtitle((mySubTitle || '').trim() || '');
  }, [device?.timestamp, deviceIsOn, driveMode, isOnline, isPoweredOff]);

  return !device ? (
    <DeviceLoadingSkeleton size="medium" sx={{ mt: '4px' }} />
  ) : device?.isConfigured ? (
    /**
     * only allow the edit view to be rendered if the device is online
     */
    editing && isOnline ? (
      <div style={{ width: '100%' }}>
        <BorderedSection
          title={`Editing ${device.role}${deviceLabel} Pump:`}
          subTitle={subtitle}
          labelStyle={{ fontSize: '10px' }}
          style={{ marginTop: '16px', marginBottom: '-6px' }}
          ccStyle={{
            margin: '0px',
            padding: '4px 6px',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
          }}
        >
          <FormGroup style={{ paddingLeft: '6px' }}>
            <FormControlLabel
              disabled={!editingEnabled}
              control={
                <Switch
                  size="small"
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setNewOperatingMode(checked);
                    onOperatingModeChange(checked ? 1 : 0);
                  }}
                  name="stopOrStart"
                  id="stopOrStart"
                  checked={newOperatingMode}
                />
              }
              label={`${device.role}${deviceLabel}`}
            />
          </FormGroup>
        </BorderedSection>
      </div>
    ) : (
      <div style={{ width: '100%' }}>
        <BorderedSection
          title={`${device.role}${deviceLabel} Pump:`}
          subTitle={subtitle}
          labelStyle={{ fontSize: '10px' }}
          style={{ marginTop: '16px', marginBottom: '-6px' }}
          error={!isOnline}
          ccStyle={{
            margin: '0px',
            padding: '4px 6px',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
          }}
        >
          {isPoweredOff ? (
            <PoweredDownDevice sinceTimestamp={device.powerState?.timestamp} />
          ) : device?.hasCurrentSpeed || device?.hasDriveOutputEnergy ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {device?.hasCurrentSpeed && (
                <BorderedSection
                  title="Speed"
                  labelStyle={{ fontSize: '10px' }}
                  ccStyle={{ padding: '6px' }}
                  style={{
                    padding: '0px',
                    height: '45px',
                    width: 'auto',
                    marginRight: '5px',
                  }}
                >
                  <DisplayValue
                    value={device?.currentSpeed?.value}
                    units={'%'}
                    valueSize={'16px'}
                    unitsSize={'16px'}
                  />
                </BorderedSection>
              )}
              {device?.hasDriveOutputEnergy && (
                <BorderedSection
                  title="Total Energy Used"
                  labelStyle={{ fontSize: '10px' }}
                  ccStyle={{ padding: '6px' }}
                  style={{
                    padding: '0px',
                    height: '45px',
                    width: 'auto',
                    marginLeft: '5px',
                  }}
                >
                  <DisplayValue
                    value={device?.driveOutputEnergy?.value}
                    units={'kWh'}
                    valueSize={'16px'}
                    unitsSize={'16px'}
                  />
                </BorderedSection>
              )}
            </div>
          ) : null}
          {!isPoweredOff &&
            (device?.hasTargetSpeed || device?.hasDriveOutputPower) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop:
                    device?.hasCurrentSpeed || device?.hasDriveOutputEnergy
                      ? '8px'
                      : '0px',
                }}
              >
                {device?.hasTargetSpeed && (
                  <BorderedSection
                    title="Target"
                    labelStyle={{ fontSize: '10px' }}
                    ccStyle={{ padding: '6px' }}
                    style={{
                      padding: '0px',
                      height: '45px',
                      width: 'auto',
                      marginRight: '5px',
                    }}
                  >
                    <DisplayValue
                      value={device?.targetSpeed?.value}
                      units={'%'}
                      valueSize={'16px'}
                      unitsSize={'16px'}
                    />
                  </BorderedSection>
                )}
                {device?.hasDriveOutputPower && (
                  <BorderedSection
                    title="Current Power"
                    labelStyle={{ fontSize: '10px' }}
                    ccStyle={{ padding: '6px' }}
                    style={{
                      padding: '0px',
                      height: '45px',
                      width: 'auto',
                      marginLeft: '5px',
                    }}
                  >
                    <DisplayValue
                      value={device?.driveOutputPower?.value}
                      digits={1}
                      units={'kW'}
                      valueSize={'16px'}
                      unitsSize={'16px'}
                    />
                  </BorderedSection>
                )}
              </div>
            )}
        </BorderedSection>
      </div>
    )
  ) : null;
}
