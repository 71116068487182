import {
  Fahrenheit,
  TemperatureUnit,
} from '../../system/models/temperatureUnits';
import { BulkUpdateEntities } from '../../system/EntityManager';

export type Properties = BulkUpdateEntities['properties'];
export type Property = Properties[number];
export type Unit = BulkUpdateEntities['units'][number];
export type Building = BulkUpdateEntities['buildings'][number];
export type Floor = BulkUpdateEntities['floors'][number];

// Steps for bulk configuring thermostats
export enum BulkThermostatsStep {
  SelectProperty,
  SelectUnitTypes,
  SelectBuildings,
  SelectFloors,
  SelectCUGroups,
  ConfigureTStats,
  BulkVerify,
  BulkCommit,
}

// Steps for bulk configuring setpoint limits
export enum BulkSetpointLimitsStep {
  SelectProperty,
  BulkVerify,
  BulkCommit,
}

// TStat attributes that can be bulk configured
export interface TStatConfig {
  lockoutLevel: string | 'no-change';
  systemType: string | 'no-change';
  fanMode: string | 'no-change';
  fanButton: string | 'no-change';
  opMode: string | 'no-change';
  setpoints: {
    modify: boolean;
    heat: number;
    cool: number;
    units: TemperatureUnit;
  };
}

// Initial default TStat configuration
// The majority of users have Fahrenheit as their preferred temperature unit, so default to that.
// Bulk config includes a dropdown to easily switch between Fahrenheit and Celsius.
export const initialTStatConfig: TStatConfig = {
  lockoutLevel: 'no-change',
  systemType: 'no-change',
  fanMode: 'no-change',
  fanButton: 'no-change',
  opMode: 'no-change',
  setpoints: {
    modify: false,
    heat: 68,
    cool: 72,
    units: Fahrenheit,
  },
};

// Attributes used to filter TStats to apply changes to
export interface TStatFilters {
  systemType: string | 'any';
  opMode: string | 'any';
}

// Initial default TStat filters
export const initialTStatFilters: TStatFilters = {
  systemType: 'any',
  opMode: 'any',
};

// Attributes used to filter units to apply changes to
export interface UnitFilters {
  selectedUnitTypes: string[];
  selectedBuildings: string[];
  selectedFloors: string[];
  selectedCUGroups: string[];
}

// Progress while committing bulk config changes
export interface BulkCommitProgress {
  deviceIds: string[];
  numConfigured: number;
  numFailed: number;
  terminated: boolean;
}

// Initial commit progress
export const initialCommitProgress: BulkCommitProgress = {
  deviceIds: [],
  numConfigured: 0,
  numFailed: 0,
  terminated: false,
};
