/**
 * ViewSelector
 * - UI component used by Property component
 * - Renders drop-down view selector on small screens and tab selector on larger screens
 * - Used to select property-detail view.
 */
import React, { useEffect } from 'react';
import { ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useNavigate, useParams } from 'react-router-dom';

type SourceTypeOptionKey = 'All' | 'Core' | 'BNG' | 'HMM';

export interface SourceTypeOption {
  name: SourceTypeOptionKey;
  value: string;
}

const defaultSourceTypeOption: SourceTypeOption = {
  name: 'All',
  value: 'all',
};

const sourceTypeOptions: SourceTypeOption[] = [
  defaultSourceTypeOption,
  {
    name: 'Core',
    value: 'core',
  },
  {
    name: 'BNG',
    value: 'bng',
  },
  {
    name: 'HMM',
    value: 'hmm',
  },
];

interface SourceTypeSelectorProps {
  options: SourceTypeOption[];
}

export function SourceTypeSelector() {
  return <OptionDropdown options={sourceTypeOptions} />;
}

function OptionDropdown(props: SourceTypeSelectorProps) {
  const navigate = useNavigate();
  const { options } = props;
  const { type, search } = useParams<{ type: string; search: string }>();
  const [value, setValue] = React.useState<string>(type ?? 'all');
  const selectHandler = (event: SelectChangeEvent) => {
    const newVal = event.target.value ?? 'all';
    setValue(newVal);
    if (newVal === 'all') {
      const searchString = search ? '?search=' + search : '';
      navigate(`/sources${searchString}`);
    } else {
      const searchString = search ? '&search=' + search : '';
      navigate(`/sources?type=${newVal}${searchString}`);
    }
  };

  useEffect(() => {
    setValue(type ?? 'all');
  }, [type]);

  return (
    <FormControl
      variant="standard"
      sx={{
        mx: 3,
        my: '.4rem',
        paddingLeft: '.3rem',
      }}
    >
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        label="Property Menu"
        onChange={selectHandler}
        value={value}
        style={{ marginRight: '55', paddingRight: '0px' }}
        disableUnderline
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            <ListItemText>{option.name}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
