import { useFormik } from 'formik';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import React, { useEffect, useState } from 'react';
import { Property, Unit } from '../../../../types/generated-types';
import { Fade } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { FormValues } from '../types/userDetailFormValues';
import {
  useProperties,
  useUnitsByPropertyId,
} from '../../../system/EntityManager';

interface ResidentState {
  properties: Partial<Property>[];
  units: Partial<Unit>[];
  propertyId: string;
  unitId: string;
}

export function UserResidentTab(props: {
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) {
  const {
    formik,
    // TODO: Peter: if we don't need onChange as a prop, delete it.
    // onChange,
  } = props;
  const [residentState, setResidentState] = useState<ResidentState>({
    properties: [],
    units: [],
    propertyId: '',
    unitId: '',
  });
  const { properties } = useProperties();
  const { units } = useUnitsByPropertyId(residentState.propertyId);

  useEffect(() => {
    let units: Partial<Unit>[] = [];
    let propertyId = '';
    let unitId = '';
    if (properties?.length) {
      const selProp = properties.find(
        (prop) => prop._id === formik.values.residentProperty,
      );
      if (selProp) {
        const selUnit = (units || []).find(
          (unit) => unit._id === formik.values.residentUnit,
        );
        if (selUnit?._id) {
          unitId = selUnit._id;
        }
        propertyId = selProp._id;
      }
    }
    setResidentState({ properties, units, propertyId, unitId });
  }, [properties, units, formik]);

  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div style={{ width: '100%' }}>
        <h2 style={{ margin: 0 }}>Resident Settings</h2>
        <br />
        {residentState.properties && (
          <TextField
            id="residentProperty"
            name="residentProperty"
            fullWidth
            select
            label="Property"
            defaultValue="none"
            value={residentState.propertyId}
            onChange={props.onChange}
            error={
              !!props.formik.touched.residentProperty &&
              !!props.formik.errors.residentProperty
            }
            helperText={
              props.formik.touched.residentProperty &&
              props.formik.errors.residentProperty
            }
          >
            <MenuItem key="none" value="none">
              None
            </MenuItem>
            {residentState.properties.map((property) => (
              <MenuItem key={property._id} value={property._id}>
                {property.title}
              </MenuItem>
            ))}
          </TextField>
        )}
        <br />
        <br />
        {!!residentState.properties.length && !!residentState.units.length && (
          <TextField
            disabled={!residentState.propertyId}
            id="residentUnit"
            name="residentUnit"
            fullWidth
            select
            label="Unit"
            defaultValue="none"
            value={residentState.unitId}
            onChange={props.onChange}
            helperText={
              props.formik.touched.residentUnit &&
              props.formik.errors.residentUnit
            }
          >
            <MenuItem key="none" value="none">
              None
            </MenuItem>
            {(residentState.units || []).map((unit, index) => (
              <MenuItem key={unit?._id || index} value={unit?._id || index}>
                {unit?.name || 'Unnamed unit'}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
    </Fade>
  );
}
