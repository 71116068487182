import AlertIcon from '@mui/icons-material/Warning';
import { Collapse } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Maybe } from '../../../../types/generated-types';
import {
  useDeviceAlerts,
  usePanelAlerts,
  useZoneAlerts,
} from '../../../system/AlertsManager';
import BorderedSection from '../../shared/borderedSection';
import { humanize } from '../multi-sensor/multi-sensor';

interface ExpandSectionButtonProps extends IconButtonProps {
  expand: boolean;
  showDivider?: boolean;
  showLeftDivider?: boolean;
  showRightDivider?: boolean;
  buttonLabel: string;
  onClick: (event: any) => void;
}

export const ExpandSectionButton = styled((props: ExpandSectionButtonProps) => {
  const {
    expand,
    buttonLabel,
    onClick,
    showDivider,
    showRightDivider,
    showLeftDivider,
    ...other
  } = props;
  return (
    <div
      style={
        showDivider
          ? {
              padding: '5px',
              height: '100%',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
            }
          : showRightDivider && showLeftDivider
            ? {
                height: '100%',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderRight: '1px solid rgba(0, 0, 0, 0.23)',
                borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
              }
            : showLeftDivider
              ? {
                  height: '100%',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
                }
              : showRightDivider
                ? {
                    height: '100%',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRight: '1px solid rgba(0, 0, 0, 0.23)',
                  }
                : {}
      }
    >
      <IconButton
        style={showDivider ? { padding: '6px 8px' } : {}}
        onClick={onClick}
        aria-expanded={expand}
        aria-label={buttonLabel}
        {...other}
      />
    </div>
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function ToggleAlertDetailsButton<
  D extends {
    _id?: string;
    deviceId?: string | Maybe<string> | undefined;
    panelId?: string | Maybe<string> | undefined;
    zoneId?: string | Maybe<string> | undefined;
    sourceId?: string | Maybe<string> | undefined;
    __typename?: string;
  },
>(
  props: {
    showLeftDivider?: boolean;
    showRightDivider?: boolean;
    showDivider?: boolean;
    device: D;
  } & ExpandSectionButtonProps,
) {
  const deviceAlerts = useDeviceAlerts(props.device);
  const zoneAlerts = useZoneAlerts(props.device.zoneId);
  const panelAlerts = usePanelAlerts(props.device.panelId);

  const alerts = useMemo(() => {
    if (!props.device._id) return [];

    switch (props.device.__typename) {
      case 'Panel':
        return panelAlerts;
      case 'Zone':
        return zoneAlerts;
      default:
        return deviceAlerts;
    }
  }, [
    props.device._id,
    props.device.__typename,
    deviceAlerts,
    zoneAlerts,
    panelAlerts,
  ]);

  const showDivider = props.showDivider ?? false;
  const showLeftDivider = props.showLeftDivider ?? false;
  const showRightDivider = props.showRightDivider ?? false;

  return alerts.length > 0 ? (
    <ExpandSectionButton
      showDivider={showDivider}
      showLeftDivider={showLeftDivider}
      showRightDivider={showRightDivider}
      expand={props.expand}
      buttonLabel={props.buttonLabel}
      onClick={props.onClick}
    >
      <AlertIcon sx={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="inherit" />
    </ExpandSectionButton>
  ) : null;
}

export function DeviceAlerts<
  D extends {
    _id?: Maybe<string> | string | undefined;
    deviceId?: Maybe<string> | string | undefined;
    sourceId?: Maybe<string> | string | undefined;
    __typename?: string;
  },
>({ device, alertsExpanded }: { device: D; alertsExpanded: boolean }) {
  const deviceAlerts = useDeviceAlerts(device);
  const zoneAlerts = useZoneAlerts(device._id);
  const panelAlerts = usePanelAlerts(device._id);

  const alerts = React.useMemo(() => {
    if (!device?._id) return [];

    switch (device.__typename) {
      case 'Panel':
        return panelAlerts;
      case 'Zone':
        return zoneAlerts;
      default:
        return deviceAlerts;
    }
  }, [device?._id, device?.__typename, deviceAlerts, zoneAlerts, panelAlerts]);

  const hasAlerts = alerts.length > 0;

  return hasAlerts ? (
    <Collapse in={alertsExpanded} timeout="auto">
      <BorderedSection
        title="Alerts"
        error={true}
        style={{ margin: '8px 0px 0px 0px' }}
        ccStyle={{ display: 'flex', flexDirection: 'row' }}
      >
        <List
          dense={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '-14px',
            marginBottom: '-14px',
          }}
        >
          {hasAlerts ? (
            alerts.map(
              (alert, index) =>
                alert && (
                  <ListItem
                    key={index}
                    sx={{
                      width: '100%;',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: 0,
                      paddingTop: '4px',
                      marginTop: '4px',
                      borderTop: '1px solid lightgray',

                      '&:first-of-type': {
                        borderTop: 'none !important',
                        paddingTop: '12px',
                        marginTop: 'inherit',
                      },
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {alert.device?.name || 'Unnamed Device'}
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {humanize(alert.device?.type || 'Unknown Device')}
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {alert.type?.name || 'Unknown Alert Type'} since{' '}
                          {dayjs(alert.initiated).format('LLL')}
                        </Typography>
                      </div>
                    </div>
                  </ListItem>
                ),
            )
          ) : (
            <ListItem>
              <div>No Alerts!</div>
            </ListItem>
          )}
        </List>
      </BorderedSection>
    </Collapse>
  ) : null;
}
