import React, { useEffect } from 'react';
import { SourceList } from './source-list';
import { useInjectableComponents } from '../../system/services/injectableComponentsManager';
import { SourceTypeSelector } from './ui/source-type-selector';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// TODO: Peter: Move this to use the managed entities 'sources' rather than querying for them separately.
export function SourceMain() {
  const { setPrimaryBottomNavigationWidget } = useInjectableComponents();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setPrimaryBottomNavigationWidget(<SourceTypeSelector />);
    return () => {
      setPrimaryBottomNavigationWidget(undefined);
    };
  }, [setPrimaryBottomNavigationWidget]);

  return (
    <div
      style={{
        margin: isSmall ? '0px 4px' : '0 4px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
        height: '100%',
      }}
    >
      <SourceList />
    </div>
  );
}
