import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Check from '@mui/icons-material/Check';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BulkConfigStep from '../ui/bulk-config-step';
import { Unit, UnitFilters } from '../types';

export interface SelectCUGroupsProps {
  cuGroups: string[];
  units: Unit[];
  unitFilters: UnitFilters;
  error?: string;
  onSelectCUGroup: (cuGroup: string, checked: boolean) => void;
  toggleCUGroups: (cuGroups: string[]) => void;
  onBack: () => void;
  onNext: () => void;
}

// Step for selecting specific CU groups to configure
export default function SelectCUGroups(props: SelectCUGroupsProps) {
  const {
    cuGroups,
    units,
    unitFilters,
    error,
    onSelectCUGroup,
    toggleCUGroups,
    onBack,
    onNext,
  } = props;

  const [showUnits, setShowUnits] = useState(false);

  function toggleAll() {
    toggleCUGroups(cuGroups);
  }

  // Select all CU Groups by default
  useEffect(() => {
    if (unitFilters.selectedCUGroups.length === 0) {
      toggleAll();
    }
    // eslint-disable-next-line
  }, []);

  const unitsName = showUnits ? 'Hide Units' : 'Show Units';
  const unitsIcon = showUnits ? <ExpandLess /> : <ExpandMore />;

  const topActions = [
    { name: 'Toggle All', action: toggleAll, startIcon: <Check /> },
    {
      name: unitsName,
      action: () => setShowUnits(!showUnits),
      startIcon: unitsIcon,
    },
  ];

  const bottomActions = [
    { name: 'Back', action: onBack, startIcon: <ArrowLeft /> },
    { name: 'Next', action: onNext, endIcon: <ArrowRight /> },
  ];

  return (
    <BulkConfigStep
      title="Select CU Groups"
      bottomActions={bottomActions}
      topActions={topActions}
      error={error}
    >
      <FormControl>
        <FormGroup>
          {cuGroups.map((cuid) => {
            const cuGroupUnits = units.filter((u) => u?.cuid === cuid);
            return (
              <>
                <FormControlLabel
                  key={cuid}
                  value={cuid}
                  control={
                    <Checkbox
                      checked={unitFilters.selectedCUGroups.includes(cuid)}
                      onChange={(_, v) => onSelectCUGroup(cuid, v)}
                      name={cuid}
                      color="secondary"
                    />
                  }
                  label={'CU #' + cuid}
                />
                {showUnits && (
                  <Typography key={cuid + '_units'} color="GrayText">
                    {cuGroupUnits.map((u, i) => {
                      return u && u.name ? (
                        <span key={u?._id}>
                          {u.name.includes('Master')
                            ? u.name.split('Master')[0] + '*'
                            : u.name}
                          {i < cuGroupUnits.length - 1 && <>,&nbsp;&nbsp;</>}
                        </span>
                      ) : null;
                    })}
                  </Typography>
                )}
              </>
            );
          })}
        </FormGroup>
      </FormControl>
    </BulkConfigStep>
  );
}
