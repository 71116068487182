import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Property } from '../../../../types/generated-types';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
import { AlertsList } from '../../alerts/alerts-list';
import { MyProperty } from '../property-detail';
import { basePropertyMenuItems } from '../ui/base-property-context-menu';

interface PropertyAlertsProps {
  data?: MyProperty;
}

export function PropertyAlerts(props: PropertyAlertsProps) {
  const propertyData = props.data;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    setContextMenuItems,
    setSubtitle,
    setTitle,
    setTitlePath,
    setSubtitlePath,
  } = useInjectableComponents();
  const { pathname } = useLocation();

  useEffect(() => {
    if (propertyData?._id) {
      const contextMenuItems: BottomMenuItems = basePropertyMenuItems(
        propertyData._id,
        pathname,
      );

      setTitle(propertyData.title ?? 'Unnamed Property');
      setTitlePath(`/properties/${propertyData?._id}/summary`);

      setSubtitle('Property Alerts');
      setSubtitlePath(`/properties/${propertyData._id}/units`);

      setContextMenuItems(contextMenuItems, propertyData as Property);
    }
    return () => {
      setTitle(undefined);
      setTitlePath(undefined);
      setSubtitle(undefined);
      setSubtitlePath(undefined);
      setContextMenuItems(undefined);
    };
  }, [
    propertyData,
    pathname,
    setTitle,
    setTitlePath,
    setSubtitle,
    setSubtitlePath,
    setContextMenuItems,
  ]);

  return (
    <div
      style={{
        margin: isSmall ? '0px' : '10px 4px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <AlertsList propertyId={propertyData?._id} />
    </div>
  );
}
