import React from 'react';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { GetBulkSetpointLimitsQuery } from '../../../../types/generated-types';
import { Notifier } from '../../../system/services/notificationManager';
import { SetPoints } from '../../devices/thermostat/components';
import BulkConfigStep from '../ui/bulk-config-step';
import { convertToTempUnits } from '../../devices/util';
import { TStatConfig, TStatFilters } from '../types';
import { convertSetpointLimits } from '../utils/bulk-utils';
import {
  systemChoices,
  Choice,
} from '../../devices/thermostat/components/helpers/choices';
import {
  Celsius,
  Fahrenheit,
  TemperatureUnit,
} from '../../../system/models/temperatureUnits';

export interface ConfigureTStatsProps {
  setpointLimits: GetBulkSetpointLimitsQuery['getBulkSetpointLimits'];
  tStatConfig: TStatConfig;
  tStatFilters: TStatFilters;
  error?: string;
  onTStatConfigChange: (changes: Partial<TStatConfig>) => void;
  onTStatFilterChange: (changes: Partial<TStatFilters>) => void;
  onBack: () => void;
  onNext: () => void;
}

// Step for configuring the thermostat attributes to change
export default function ConfigureTStats(props: ConfigureTStatsProps) {
  const {
    setpointLimits,
    onBack,
    onNext,
    tStatConfig,
    tStatFilters,
    error,
    onTStatConfigChange,
    onTStatFilterChange,
  } = props;
  const { lockoutLevel, systemType, opMode, fanMode, fanButton, setpoints } =
    tStatConfig;
  const opModeDisabled = systemType === 'no-change';

  const noChangeChoice: Choice = { id: 'no-change', label: 'No Change' };
  const anyChoice: Choice = { id: 'any', label: 'Any' };

  // Choices for each configurable attribute
  const lockoutLevels = [noChangeChoice, ...systemChoices.lockoutLevels];
  const fromSystemTypes = [anyChoice, ...systemChoices.systemTypes];
  const toSystemTypes = [noChangeChoice, ...systemChoices.systemTypes];
  const fanModes = [noChangeChoice, { id: 'Auto', label: 'Automatic' }]; // Only allow Auto or no change
  const fanButtons = [noChangeChoice, ...systemChoices.showFanButtons];
  const fromOpModes = [anyChoice, ...systemChoices.operatingModes];
  const toOpModes = [noChangeChoice, ...validOperatingModes()];

  function validOperatingModes(): Choice[] {
    switch (systemType) {
      case 'no-change':
      case 'HeatAndCool':
      case 'HeatPump':
        // Anything goes
        return systemChoices.operatingModes;
      case 'CoolOnly':
        // Valid operatingModes are: Cool, Off, no-change
        return systemChoices.operatingModes.filter((mode) => {
          return (
            mode.id === 'Cool' || mode.id === 'Off' || mode.id === 'no-change'
          );
        });
      default:
        // All other Heat types
        // Valid operatingModes are: Heat, Off, no-change
        return systemChoices.operatingModes.filter((mode) => {
          return (
            mode.id === 'Heat' || mode.id === 'Off' || mode.id === 'no-change'
          );
        });
    }
  }

  function allowSetpointModification(opMode: string) {
    return (
      (opMode === 'Heat' || opMode === 'Cool' || opMode === 'Auto') &&
      setpointLimits
    );
  }

  function handleOpModeChange(newMode: string) {
    const changes: Partial<TStatConfig> = { opMode: newMode };
    if (!allowSetpointModification(newMode)) {
      changes.setpoints = { ...setpoints, modify: false };
    }
    onTStatConfigChange(changes);
  }

  function handleSystemTypeChange(newType: string) {
    const changes: Partial<TStatConfig> = { systemType: newType };
    // Ensure opMode is valid for the new systemType
    switch (newType) {
      case 'no-change':
      case 'SmartAhu':
      case 'HeatAndCool':
      case 'HeatPump':
        // Anything goes
        break;
      case 'CoolOnly':
        // Valid operatingModes are: Cool, Off, no-change. Otherwise, change to Off
        if (
          !(opMode === 'Cool' || opMode === 'Off' || opMode === 'no-change')
        ) {
          changes.opMode = 'Off';
        }
        break;
      default:
        // All other Heat types
        // Valid operatingModes are: Heat, Off, no-change. Otherwise, change to Off
        if (
          !(opMode === 'Heat' || opMode === 'Off' || opMode === 'no-change')
        ) {
          changes.opMode = 'Off';
        }
        break;
    }
    onTStatConfigChange(changes);
  }

  function handleSetpointUnitsChange(newUnits: TemperatureUnit) {
    const { heat, cool, units } = setpoints;
    onTStatConfigChange({
      setpoints: {
        ...setpoints,
        units: newUnits,
        heat: convertToTempUnits(heat, newUnits, units, 0) || heat,
        cool: convertToTempUnits(cool, newUnits, units, 0) || cool,
      },
    });
  }

  function handleSetpointChange(mode: 'heat' | 'cool', delta: number) {
    // Setpoint is only editable once limits have been loaded
    if (!setpointLimits) return;

    // Set other mode so can apply deadband as needed
    const otherMode = mode === 'heat' ? 'cool' : 'heat';

    // New setpoints from edited value
    const newSetpoints: TStatConfig['setpoints'] = { ...setpoints };
    newSetpoints[mode] += delta;

    // Ensure setpoint limits are using the same unit as setpoints for comparisons.
    // Setpoint limits will always be returned from the server in Celsius.
    const limits = convertSetpointLimits(
      setpointLimits,
      newSetpoints.units,
      Celsius,
    );

    // Compare new setpoint to the limits for the selected properties/units/tStats
    // Only allow the change if within limits
    if (
      newSetpoints[mode] > limits[mode].max ||
      newSetpoints[mode] < limits[mode].min
    ) {
      // New setpoint value exceeds the limits - reject the change
      let minMax = 'Minimum';
      let limit = formatTemperature(limits[mode].min);
      if (newSetpoints[mode] > limits[mode].max) {
        minMax = 'Maximum';
        limit = formatTemperature(limits[mode].max);
      }
      Notifier.warn(`${minMax} ${mode} setpoint limit exceeded: ${limit}`);
      return;
    }

    // Technically only need to worry about deadband if operatingMode is Auto,
    // but for bulk config we will maintain the separation.

    // Compare new setpoint to other mode setpoint to ensure deadband separation
    if (
      Math.abs(newSetpoints[mode] - newSetpoints[otherMode]) < getDeadband()
    ) {
      console.log(
        `Change crossed the deadband, so push otherMode by same delta`,
      );
      // Adjust otherMode setpoint to preserve deadband difference
      newSetpoints[otherMode] += delta;
    }

    // New setpoint(s) preserve the deadband and are within the limits - save and update
    onTStatConfigChange({
      setpoints: newSetpoints,
    });
  }

  function formatTemperature(value: number) {
    return `${value} \xB0${setpoints.units}`;
  }

  function getDeadband() {
    return setpoints.units === Celsius ? 2 : 4;
  }

  const bottomActions = [
    { name: 'Back', action: onBack, startIcon: <ArrowLeft /> },
    { name: 'Next', action: onNext, endIcon: <ArrowRight /> },
  ];

  return (
    <BulkConfigStep
      title="Configure Thermostats"
      bottomActions={bottomActions}
      topActions={[]}
      error={error}
    >
      <Box sx={{ marginBottom: 3 }}>
        <InputLabel sx={{ marginTop: 1 }}>Menu Lockout</InputLabel>
        <FormControl fullWidth>
          <Select
            id="menu-lockout"
            value={lockoutLevel}
            onChange={(e) => {
              onTStatConfigChange({ lockoutLevel: e.target.value });
            }}
            color="secondary"
          >
            {lockoutLevels.map((l) => (
              <MenuItem key={l.id} value={l.id}>
                {l.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginBottom: 3 }}>
        <InputLabel sx={{ marginBottom: 1 }}>HVAC System Type</InputLabel>
        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="system-type-from-label" color="secondary">
              From
            </InputLabel>
            <Select
              id="system-type-from"
              labelId="system-type-from-label"
              value={tStatFilters.systemType}
              label="From"
              onChange={(e) =>
                onTStatFilterChange({ systemType: e.target.value })
              }
              color="secondary"
            >
              {fromSystemTypes.map((t) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="system-type-to-label" color="secondary">
              To
            </InputLabel>
            <Select
              id="system-type-to"
              labelId="system-type-to-label"
              value={systemType}
              label="To"
              onChange={(e) => handleSystemTypeChange(e.target.value)}
              color="secondary"
            >
              {toSystemTypes.map((t) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ marginBottom: 3 }}>
        <InputLabel sx={{ marginBottom: 1 }}>Fan Control</InputLabel>
        <FormControl fullWidth>
          <Select
            id="fan-control"
            value={fanMode}
            onChange={(e) => onTStatConfigChange({ fanMode: e.target.value })}
            color="secondary"
          >
            {fanModes.map((m) => (
              <MenuItem key={m.id} value={m.id}>
                {m.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginBottom: 3 }}>
        <InputLabel sx={{ marginBottom: 1 }}>Show Fan Button</InputLabel>
        <FormControl fullWidth>
          <Select
            id="fan-button"
            value={fanButton}
            onChange={(e) => onTStatConfigChange({ fanButton: e.target.value })}
            color="secondary"
          >
            {fanButtons.map((b) => (
              <MenuItem key={b.id} value={b.id}>
                {b.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginBottom: 3 }}>
        <InputLabel sx={{ marginBottom: 1 }}>Operating Mode</InputLabel>
        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="op-mode-from-label" color="secondary">
              From
            </InputLabel>
            <Select
              id="op-mode"
              labelId="op-mode-from-label"
              value={tStatFilters.opMode}
              onChange={(e) => onTStatFilterChange({ opMode: e.target.value })}
              color="secondary"
              label="From"
              disabled={opModeDisabled}
            >
              {fromOpModes.map((m) => (
                <MenuItem key={m.id} value={m.id}>
                  {m.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="op-mode-to-label" color="secondary">
              To
            </InputLabel>
            <Select
              id="op-mode"
              labelId="op-mode-to-label"
              value={opMode}
              onChange={(e) => handleOpModeChange(e.target.value)}
              color="secondary"
              label="To"
              disabled={opModeDisabled}
            >
              {toOpModes.map((m) => (
                <MenuItem key={m.id} value={m.id}>
                  {m.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {opModeDisabled && (
          <FormHelperText sx={{ textAlign: 'center' }}>
            Select HVAC System Type to modify Operating Mode
          </FormHelperText>
        )}
      </Box>
      {allowSetpointModification(opMode) && (
        <Box sx={{ marginBottom: 3 }}>
          <InputLabel sx={{ marginBottom: 1 }}>Setpoints</InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setpoints.modify}
                    onChange={(_, checked) =>
                      onTStatConfigChange({
                        setpoints: {
                          ...setpoints,
                          modify: checked,
                        },
                      })
                    }
                    color="secondary"
                  />
                }
                label="Edit Setpoints"
              />
            </FormGroup>
            {setpoints.modify && (
              <ToggleButtonGroup
                exclusive
                value={setpoints.units}
                onChange={(e, v) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSetpointUnitsChange(v);
                }}
                size="small"
              >
                <ToggleButton value={Fahrenheit}>DEG_F</ToggleButton>
                <ToggleButton value={Celsius}>DEG_C</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Box>
          {setpoints.modify && (
            <SetPoints
              heatSetpoint={setpoints.heat}
              coolSetpoint={setpoints.cool}
              operatingMode={opMode}
              units={setpoints.units}
              editable
              handleTargetChange={(targetMode, delta) =>
                handleSetpointChange(targetMode, delta)
              }
              handleChange={() => null}
            />
          )}
        </Box>
      )}
    </BulkConfigStep>
  );
}
