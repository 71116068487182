import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Device, Maybe, Panel, Zone } from '../../../../types/generated-types';
import {
  DeviceInfo,
  DeviceInformation,
  DevicePowerControl,
} from './device-info';
import { Collapse, Grid, ListItemIcon, Typography } from '@mui/material';
import {
  DeviceAlerts,
  ExpandSectionButton,
  ToggleAlertDetailsButton,
} from './alerts-info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BorderedSection from '../../shared/borderedSection';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InsightsIcon from '@mui/icons-material/Insights';
import UndoIcon from '@mui/icons-material/Undo';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import {
  useContainerHasAlerts,
  useDeviceHasAlerts,
} from '../../../system/AlertsManager';

export enum ViewMode {
  NORMAL = 'normal',
  EDIT_UNCHANGED = 'edit_unchanged',
  EDIT = 'edit',
  UPDATING = 'updating',
  AWAITING = 'awaiting',
}

export enum MenuAction {
  EDIT_VALUES = 'edit_values',
  SAVE_CHANGES = 'save_changes',
  REVERT_CHANGES = 'revert_changes',
  CANCEL = 'cancel',
}

interface EditButtonsProps {
  viewMode: ViewMode;
  disabled: boolean;
  handleClick: (param: MenuAction) => void;
}

const EditButtons = (props: EditButtonsProps) => {
  const { viewMode, handleClick, disabled } = props;
  let buttons: MenuAction[];

  switch (viewMode) {
    case ViewMode.NORMAL:
      buttons = [MenuAction.EDIT_VALUES];
      break;
    case ViewMode.EDIT_UNCHANGED:
      buttons = [MenuAction.CANCEL];
      break;
    case ViewMode.EDIT:
      buttons = [MenuAction.SAVE_CHANGES, MenuAction.REVERT_CHANGES];
      break;
    default:
      buttons = [];
  }

  const iconMap = {
    [MenuAction.CANCEL]: <ClearIcon fontSize="inherit" />,
    [MenuAction.EDIT_VALUES]: <EditIcon fontSize="inherit" />,
    [MenuAction.REVERT_CHANGES]: <UndoIcon fontSize="inherit" />,
    [MenuAction.SAVE_CHANGES]: <CheckIcon fontSize="inherit" />,
  };

  return (
    <ButtonGroup
      variant="text"
      color="inherit"
      style={{
        padding: '0px, 5px',
        // height: '100%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: '1px solid rgba(0, 0, 0, 0.23)',
      }}
    >
      {buttons &&
        buttons.map((val) => (
          <Button
            key={`${val}_button`}
            onClick={() => handleClick(val)}
            disabled={disabled}
          >
            <Avatar
              sx={{
                color: 'inherit',
                width: {
                  xs: '16px',
                  sm: '24px',
                  md: '32px',
                },
                height: {
                  xs: '16px',
                  sm: '24px',
                  md: '32px',
                },
                fontSize: {
                  xs: 'medium',
                  sm: 'large',
                  md: 'x-large',
                },
                backgroundColor: 'inherit',
              }}
            >
              {iconMap[val]}
            </Avatar>
          </Button>
        ))}
    </ButtonGroup>
  );
};

const GraphSelectionIcon = (props: {
  buttonLabel: string;
  toggled: boolean;
  showDivider: boolean;
  onClick: (event: any) => void;
  enableExecuteGraphWizard?: boolean;
}) => {
  const {
    buttonLabel = 'Start Graph Selection',
    toggled = false,
    showDivider = false,
    enableExecuteGraphWizard = false,
    onClick,
    ...other
  } = props;

  return (
    <div
      style={
        showDivider
          ? {
              padding: '5px',
              height: '100%',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: '1px solid rgba(0, 0, 0, 0.23)',
            }
          : {}
      }
    >
      {toggled ? (
        <QueryStatsIcon
          aria-disabled={enableExecuteGraphWizard}
          style={
            showDivider
              ? {
                  padding: '6px 0px 0px 0px',
                  cursor: enableExecuteGraphWizard ? 'pointer' : 'not-allowed',
                }
              : { cursor: 'pointer' }
          }
          onClick={onClick}
          aria-label={buttonLabel}
          {...other}
        />
      ) : (
        <InsightsIcon
          style={
            showDivider
              ? {
                  padding: '6px 0px 0px 0px',
                  cursor: 'pointer',
                }
              : { cursor: 'pointer' }
          }
          onClick={onClick}
          aria-label={buttonLabel}
          {...other}
        />
      )}
    </div>
  );
};

const CancelGraphIcon = (props: {
  buttonLabel: string;
  toggled: boolean;
  showDivider: boolean;
  onClick: (event: any) => void;
}) => {
  const {
    buttonLabel = 'Cancel Graph Selection',
    toggled = false,
    showDivider = false,
    onClick,
    ...other
  } = props;

  return (
    <div
      style={
        showDivider
          ? {
              padding: '5px',
              height: '100%',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: '1px solid rgba(0, 0, 0, 0.23)',
            }
          : {}
      }
    >
      {toggled ? (
        <UndoIcon
          style={
            showDivider
              ? { padding: '6px 0px 0px 0px', cursor: 'pointer' }
              : { cursor: 'pointer' }
          }
          onClick={onClick}
          aria-label={buttonLabel}
          {...other}
        />
      ) : null}
    </div>
  );
};

const PanelIcon = (
  device: Partial<Panel> | Partial<Zone> | undefined | null,
  iconType: string,
  isPoweredOn = false,
  hasAlerts = false,
) => {
  if (device && !isPoweredOn) {
    return <Icon icon={iconType} color="#80BB52" width="30" height="30" />;
  } else if (!device || hasAlerts) {
    // return <Icon sx={{ color: 'red', fontSize: '35px' }} />;
    return <Icon icon={iconType} color="red" width="30" height="30" />;
  } else {
    // return <Icon sx={{ color: '#80BB52', fontSize: '35px' }} />;
    return <Icon icon={iconType} color="#80BB52" width="30" height="30" />;
  }
};

export function PanelFrame({
  title,
  panel,
  zone,
  device: suppliedDevice,
  label,
  iconType,
  infoEntries,
  devicePowerControl,
  children,
  toggleGraphWizard,
  cancelGraphWizard,
  enableExecuteGraphWizard,
  handleMenuButtonClick = () => {},
  viewMode = ViewMode.NORMAL,
  editingEnabled = true,
  editable = false,
  isPoweredOn = true,
}: {
  title?: string;
  children: React.ReactNode;
  iconType: string;
  panel?: Maybe<Panel> | Partial<Panel>;
  zone?: Maybe<Zone> | Partial<Zone>;
  device?: Maybe<Device> | Partial<Device>;
  label?: Maybe<string>;
  infoEntries: DeviceInformation[] | Record<string, DeviceInformation>;
  devicePowerControl?: DevicePowerControl | undefined | null;
  toggleGraphWizard?: () => void;
  cancelGraphWizard?: () => void;
  enableExecuteGraphWizard?: boolean;
  handleMenuButtonClick?: (action: MenuAction) => void;
  viewMode?: ViewMode;
  editingEnabled?: boolean;
  editable?: boolean;
  isPoweredOn?: Maybe<boolean> | undefined;
}) {
  const [alertsExpanded, setAlertsExpanded] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [hasAlerts, setHasAlerts] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [toggled, setToggled] = React.useState(false);

  const deviceHasAlerts = useDeviceHasAlerts(suppliedDevice ?? {});
  const zoneHasAlerts = useContainerHasAlerts(zone);
  const panelHasAlerts = useContainerHasAlerts(panel);

  useEffect(() => {
    setHasAlerts(deviceHasAlerts || zoneHasAlerts || panelHasAlerts);
  }, [deviceHasAlerts, zoneHasAlerts, panelHasAlerts]);

  const handleToggleClicked = () => {
    if (toggled) {
      if (enableExecuteGraphWizard) {
        setToggled(!toggled);
        if (toggleGraphWizard) {
          toggleGraphWizard();
        }
      } else {
        // No op by design
      }
    } else {
      setToggled(!toggled);
      if (toggleGraphWizard) {
        toggleGraphWizard();
      }
    }
  };

  const handleCancelGraphingClicked = () => {
    setToggled(false);
    if (cancelGraphWizard) {
      cancelGraphWizard();
    }
  };

  let displayLabel =
    label ?? panel?.name ?? panel?.role ?? zone?.name ?? 'Unnamed';
  const labelSuffix = panel
    ? panel.type === 'Device' || panel.type === 'GPIO'
      ? '' // no suffix for Device/GPIO panels
      : displayLabel.endsWith(panel.type ?? 'no type')
        ? '' // Loop panel, type already included in label, no suffix
        : panel.type ?? '' // Loop panel, type not included in label, append it as suffix
    : '';
  displayLabel = `${displayLabel}${labelSuffix.length > 0 ? ` ${labelSuffix}` : ''}`;

  displayLabel = `${displayLabel}`;

  const showGraphingIcons = !!toggleGraphWizard;

  return panel || zone ? (
    <div
      style={{
        width: '100%',
        padding: '0px',
        margin: '0px',
        backgroundColor: (suppliedDevice || panel || zone ? hasAlerts : false)
          ? '#ffd5d5'
          : '#edfcf4',
        border: hasAlerts ? '2px solid red' : '1px solid darkgray',
        paddingTop: '0px',
        borderRadius: '4px',
      }}
    >
      <div
        style={{
          width: '100%',
          padding: '0px',
          margin: '0px',
          zIndex: '10',
          backgroundColor: 'transparent',
        }}
      >
        <div
          style={{
            padding: '0px',
            margin: '0px',
            width: '100%',
            // backgroundColor: hasAlerts(panel) ? 'lightpink' : 'lightgreen',
            backgroundColor: hasAlerts ? '#ffd5d5' : '#ebf7f6',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              borderBottom: hasAlerts ? '2px solid red' : '1px solid darkgray',
              marginTop: '0px',
              marginBottom: '12px',
              backgroundColor: isPoweredOn ? 'transparent' : '#ffffaf',
              padding: '0px 5px',
            }}
          >
            <Grid container>
              <Grid item xs={1}>
                <div
                  style={{
                    display: 'flex',
                    flex: '0 1 auto',
                    position: 'relative',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '55%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {PanelIcon(
                      panel || zone,
                      iconType,
                      !!isPoweredOn,
                      hasAlerts,
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={7}>
                <div
                  style={{
                    paddingTop: '8px',
                    marginLeft: '0px',
                    display: 'flex',
                    flex: '1 0 auto',
                  }}
                >
                  <Typography noWrap>{displayLabel}</Typography>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    padding: 0,
                    display: 'flex',
                    flex: '0 1 auto',
                    justifyContent: 'right',
                  }}
                >
                  <ListItemIcon>
                    {editable ? (
                      <EditButtons
                        disabled={!editingEnabled}
                        viewMode={viewMode}
                        handleClick={handleMenuButtonClick}
                      />
                    ) : (
                      <div
                        style={{
                          width: '20px',
                          marginRight: '4px',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderRight: '1px solid rgba(0, 0, 0, 0.23)',
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                    {showGraphingIcons ? (
                      <>
                        {cancelGraphWizard ? (
                          <CancelGraphIcon
                            buttonLabel="Cancel Graph Selection"
                            toggled={toggled}
                            showDivider={toggled}
                            onClick={handleCancelGraphingClicked}
                          />
                        ) : null}
                        <GraphSelectionIcon
                          buttonLabel="Load Selected Graphs"
                          toggled={toggled}
                          showDivider={true}
                          onClick={handleToggleClicked}
                          enableExecuteGraphWizard={enableExecuteGraphWizard}
                        />
                      </>
                    ) : null}
                    {suppliedDevice ? (
                      <ToggleAlertDetailsButton
                        device={suppliedDevice}
                        expand={alertsExpanded}
                        showRightDivider={true}
                        buttonLabel="Show alerts"
                        onClick={(event) => {
                          event.preventDefault();
                          setAlertsExpanded(!alertsExpanded);
                        }}
                      />
                    ) : panel ? (
                      <ToggleAlertDetailsButton
                        device={panel}
                        expand={alertsExpanded}
                        showRightDivider={true}
                        buttonLabel="Show alerts"
                        onClick={(event) => {
                          event.preventDefault();
                          setAlertsExpanded(!alertsExpanded);
                        }}
                      />
                    ) : zone ? (
                      <ToggleAlertDetailsButton
                        device={zone}
                        expand={alertsExpanded}
                        showRightDivider={true}
                        buttonLabel="Show alerts"
                        onClick={(event) => {
                          event.preventDefault();
                          setAlertsExpanded(!alertsExpanded);
                        }}
                      />
                    ) : null}
                    <ExpandSectionButton
                      expand={expanded}
                      buttonLabel="Show more"
                      onClick={handleExpandClick}
                    >
                      <ExpandMoreIcon />
                    </ExpandSectionButton>
                  </ListItemIcon>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            padding: '0px',
            margin: '0px',
            marginBottom: '8px',
            width: '100%',
          }}
        >
          {title ? (
            <BorderedSection
              title={title}
              labelStyle={{ fontSize: '10px' }}
              style={{
                display: 'flex',
                marginBottom: '-6px',
              }}
              ccStyle={{
                padding: '4px ',
                margin: '4px',
                marginBottom: '-6px',
                flex: '1 1 auto',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              {children}
            </BorderedSection>
          ) : (
            children
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <DeviceInfo
              infoEntries={infoEntries}
              devicePowerControl={devicePowerControl}
            />
          </Collapse>
          <DeviceAlerts
            device={suppliedDevice ?? panel ?? zone ?? {}}
            alertsExpanded={alertsExpanded}
          />
        </div>
      </div>
    </div>
  ) : null;
}
