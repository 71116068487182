/**
 * MultiSensorComponent
 * - display sensor readings
 * - display pairing information
 * - display device info
 */

import React, { useEffect, useState } from 'react';

/* MUI */
import { Avatar, Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

/* Icons */
import SensorsIcon from '@mui/icons-material/Sensors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';

/* Types */
import {
  IndoorSensor,
  Thermostat,
  useIndoorSensorDetailQuery,
  useIndoorSensorDetailUpdateSubscription,
} from '../../../../types/generated-types';
import Divider from '@mui/material/Divider';
import {
  Battery,
  getBatteryLevel,
  formatVoltage,
} from '../../../ui/shared/battery';
import { DisplayValue } from '../shared-ui';

/* Shared */
import { DeviceInfo, DeviceInformation } from '../shared-ui/device-info';
import {
  DeviceAlerts,
  ToggleAlertDetailsButton,
} from '../shared-ui/alerts-info';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import { TemperatureUnit } from '../../../system/models/temperatureUnits';
import { convertToTempUnits } from '../util';
import moment from 'moment/moment';
import { useDeviceHasAlerts } from '../../../system/AlertsManager';

/* Utilities */
export function humanize(str: string) {
  return _.capitalize(
    _.trim(_.snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')),
  );
}

interface MultiSensorProps {
  deviceId: string;
  pairedThermostat?: Partial<Thermostat>;
  preferredUnits: TemperatureUnit;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// TODO: Testing_required: Peter: convert temps to preferred units.
export function MultiSensorComponent({
  deviceId,
  preferredUnits,
  pairedThermostat,
}: MultiSensorProps) {
  const [device, setDevice] = useState<Partial<IndoorSensor>>();
  const { data, loading, error } = useIndoorSensorDetailQuery({
    variables: { id: deviceId },
  });
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformation[]>([]);

  useEffect(() => {
    if (data?.indoorSensorById) {
      setDevice(data.indoorSensorById as any);
    }
  }, [data]);

  useEffect(() => {
    if (device) {
      const panelInfo: DeviceInformation = {
        Zone: device.zone?.name ?? 'Unnamed',
        'Zone ID': device.zoneId ?? 'Unidentified',
        Source: device.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] = [
        {
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          firmwareInfo: {
            Model:
              device.meta?.model || device.meta?.modelNumber || 'Unknown model',
            Version: device.meta?.version || 'Unknown version',
          },
          'Battery Voltage': formatVoltage(device.voltage?.value),
          'Last Updated': moment(device.timestamp).fromNow(),
        },
      ];

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [device]);

  useIndoorSensorDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const [expanded, setExpanded] = useState(false);
  const [alertsExpanded, setAlertsExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const hasAlerts = useDeviceHasAlerts(device);

  return error ? (
    <div>Error</div>
  ) : loading ? (
    <DeviceLoadingSkeleton />
  ) : device ? (
    <Card
      sx={{
        backgroundColor: hasAlerts ? '#ffd5d5' : '#ebf7f6',
        boxShadow: 1,
        p: 0,
        width: '100%',
        border: hasAlerts ? '1px solid red' : '',
        borderRadius: '4px',
        marginTop: '4px',
      }}
    >
      <CardHeader
        sx={{
          marginLeft: '-10px',
          paddingTop: '0px',
          paddingLeft: '10px',
          paddingRight: '10ox',
          paddingBottom: '0px',
          border: '1px solid rgba(0,0,0, 0.1)',
        }}
        title={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: '0 0 auto', flexDirection: 'column' }}>
              <Box
                sx={{
                  padding: '0px',
                }}
              >
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: '6px',
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '3px',
                    }}
                  >
                    {device?.name}
                  </Typography>
                  {pairedThermostat ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '0px',
                        marginLeft: '10px',
                      }}
                    >
                      {pairedThermostat.isPairingActive ? (
                        <LinkIcon />
                      ) : (
                        <LinkOffIcon color={hasAlerts ? 'error' : 'warning'} />
                      )}
                    </div>
                  ) : null}
                </div>
              </Box>
            </div>
            <div style={{ flex: '1 1 auto', flexDirection: 'column' }} />
            <div style={{ flex: '0 0 auto', flexDirection: 'column' }}>
              <Box sx={{ p: 0 }}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <div style={{ marginLeft: '6px' }}>&nbsp;</div>
                  <DisplayValue
                    value={convertToTempUnits(
                      device.meta?.display?.temperature ??
                        device.temperature?.value,
                      preferredUnits,
                    )}
                    units={preferredUnits}
                    size={'h3'}
                    unitsSize={'14px'}
                    style={{
                      paddingTop: '8px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  />
                  <DisplayValue
                    value={
                      device.meta?.display?.humidity ?? device.humidity?.value
                    }
                    units={'%'}
                    size={'h3'}
                    unitsSize={'14px'}
                    style={{
                      paddingTop: '8px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  />
                  <div style={{ marginRight: '6px' }}>&nbsp;</div>
                </Stack>
              </Box>
            </div>
          </div>
        }
        avatar={
          <Avatar
            sx={{
              backgroundColor: 'transparent',
              padding: '0px',
              margin: '0px',
              marginRight: '4px',
              width: '25px',
              height: '25px',
            }}
          >
            <SensorsIcon
              sx={{
                color: 'black',
                fontSize: '1rem',
                margin: '0px -10px 0px -6px',
              }}
            />
          </Avatar>
        }
        action={
          <List dense={false}>
            <ListItem
              sx={{
                '&:hover': { backgroundColor: 'InfoBackground' },
                padding: 0,
              }}
            >
              <ListItemIcon>
                <ToggleAlertDetailsButton
                  device={device}
                  expand={alertsExpanded}
                  onClick={(event) => {
                    event.preventDefault();
                    setAlertsExpanded(!alertsExpanded);
                  }}
                  buttonLabel="Show alerts"
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <IconButton
                    sx={{
                      padding: '0px 6px 0px px',
                      marginLeft: '-10px',
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <Battery charge={getBatteryLevel(device.voltage?.value)} />
                  </IconButton>
                  <div
                    style={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                      borderRightWidth: 'thin',
                      margin: '2px 6px',
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                  sx={{ padding: '0px', margin: '0px' }}
                >
                  <ExpandMoreIcon style={{ padding: '0px', margin: '0px' }} />
                </ExpandMore>
              </ListItemIcon>
            </ListItem>
          </List>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <DeviceInfo infoEntries={deviceInfo} />
      </Collapse>
      <DeviceAlerts device={device} alertsExpanded={alertsExpanded} />
    </Card>
  ) : null;
}
