import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';

/* MUTATIONS */

/* TYPES */
import {
  Building,
  Property,
  useBuildingListByPropertyIdQuery,
  useBuildingListByPropertyIdUpdateSubscription,
  usePropertyQuery,
} from '../../../types/generated-types';

/* SHARED COMPONENTS */
import DataTable from '../shared/data-table';

/* MUI */
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../system/services/injectableComponentsManager';
import AddIcon from '@mui/icons-material/Add';
import { Notifier } from '../../system/services/notificationManager';
import { basePropertyMenuItems } from './ui/base-property-context-menu';
import { useAuthorizer } from '../../auth/AuthorizationContext';

interface RowData {
  id: string;
  name: string;
  number: number;
  inUse: boolean;
}

function formatData(data: Partial<Building>[]): RowData[] {
  const formattedData: RowData[] = [];
  data.forEach((row) => {
    if (row?._id) {
      formattedData.push({
        id: row._id,
        name: row.name ?? 'Unnamed building',
        number: row.number ?? 0,
        inUse: !!row.inUse,
      });
    }
  });
  return formattedData;
}

export function PropertyBuildingsList() {
  const { setContextMenuItems, setSubtitle } = useInjectableComponents();
  const [buildings, setBuildings] = React.useState<RowData[]>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { id } = useParams<{ id: string }>();
  const { data: propertyData } = usePropertyQuery({ variables: { _id: id } });

  const { data, error } = useBuildingListByPropertyIdQuery({
    variables: { propertyId: id ?? '' },
    fetchPolicy: 'network-only',
  });

  const { can } = useAuthorizer();

  useBuildingListByPropertyIdUpdateSubscription({
    variables: { propertyId: id ?? '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (error) {
      Notifier.error('Error loading buildings: ' + error.message);
    } else if (data?.buildingsByPropertyId) {
      setBuildings(formatData(data.buildingsByPropertyId));
    }
  }, [data, error]);

  const handleRowClick = (params: GridRowParams) => {
    if (can('update', 'Building')) {
      const { row } = params;
      const bid = row?.id;
      navigate(`./buildings/${bid}/edit-building`);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 230 },
    { field: 'number', headerName: 'Number', width: 70 },
  ];

  useEffect(() => {
    if (propertyData?.property) {
      const contextMenuItems: BottomMenuItems = [
        ...basePropertyMenuItems(id, pathname, [
          {
            id: 'add-building-menu-item',
            label: 'Add Building',
            icon: <AddIcon />,
            navTarget: (path: string) => {
              const pathComponent = path.split('/').slice(0, 4);
              return pathComponent.slice(0, 4).join('/') + '/add-building';
            },
            permit: 'administer',
          },
        ]),
      ];

      setSubtitle('Buildings List');
      setContextMenuItems(contextMenuItems, propertyData.property as Property);
    }
    return () => {
      setSubtitle(undefined);
      setContextMenuItems(undefined, undefined);
    };
  }, [propertyData, id, pathname]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
      }}
    >
      <DataTable
        additionalProps={
          can('update', 'Building')
            ? {
                onRowClick: (i: GridRowParams) => handleRowClick(i),
              }
            : {}
        }
        rows={buildings ?? []}
        columns={columns}
        pageSize={10}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        loading={!buildings}
      />
    </Box>
  );
}
