import { Property } from '../../../../types/generated-types';
import { useFormik } from 'formik';
import { FormValues } from '../types/setpointLimitProfileDetailFormValues';
import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import BorderedSection from '../../shared/borderedSection';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useProperties } from '../../../system/EntityManager';

interface SetpointLimitProfilePropertiesState {
  properties: Partial<Property>[];
  selectedPropertyIds: string[];
}

export function SetpointLimitProfilePropertiesTab(props: {
  formik: ReturnType<typeof useFormik<FormValues>>;
}) {
  const { formik } = props;
  const [
    setpointLimitProfilePropertiesState,
    setSetpointLimitProfilePropertiesState,
  ] = useState<SetpointLimitProfilePropertiesState>({
    properties: [],
    selectedPropertyIds: [],
  });
  const { properties } = useProperties();

  useEffect(() => {
    let selectedPropertyIds: string[] = [];
    if (properties?.length) {
      selectedPropertyIds = properties
        .filter((prop) => formik.values.properties.includes(prop._id))
        .map((prop) => prop._id);
    }
    setSetpointLimitProfilePropertiesState({ properties, selectedPropertyIds });
  }, [properties, formik]);

  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div>
        <h2>Enabled Properties</h2>
        <BorderedSection
          title="Properties enabled to use this setpoint limit profile"
          error={!!formik.errors.properties}
          hint={formik.errors.properties || ''}
        >
          <FormGroup style={{ marginTop: '-6px', marginLeft: '6px' }}>
            {setpointLimitProfilePropertiesState.properties.length
              ? setpointLimitProfilePropertiesState.properties.map(
                  (property) => {
                    return property._id ? (
                      <FormControlLabel
                        key={property._id}
                        onChange={(e, checked) => {
                          e.preventDefault();
                          formik.setFieldTouched('properties');
                          const newPropIds = checked
                            ? [...formik.values.properties, property._id]
                            : formik.values.properties.filter(
                                (id) => id !== property._id,
                              );
                          formik.setFieldValue('properties', newPropIds);
                        }}
                        control={
                          <Checkbox
                            id={property._id}
                            value={property._id}
                            checked={setpointLimitProfilePropertiesState.selectedPropertyIds.includes(
                              property._id,
                            )}
                          />
                        }
                        label={property.title || 'no title property'}
                      />
                    ) : null;
                  },
                )
              : null}
          </FormGroup>
        </BorderedSection>
      </div>
    </Fade>
  );
}
