/**
 * DetailHeader
 * - header component for property-detail component
 * - expands to show summary information about the property
 * - subheader hidden by default
 */

import React from 'react';

/* MUI */
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

/* Icons */
import ApartmentIcon from '@mui/icons-material/Apartment';

/* Local */
import { Avatar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { Unit } from '../../../../types/generated-types';
import { useInjectableComponents } from '../../../system/services/injectableComponentsManager';
import { MyProperty } from '../property-detail';

export function DetailHeader({
  property,
  unit,
}: {
  property: MyProperty;
  unit: Partial<Unit> | undefined;
}) {
  const { subtitle, subtitleActionWidget, navigateToSubtitlePath } =
    useInjectableComponents();
  const { pathname } = useLocation();
  const { _id: propertyId } = property || {};

  let viewType: 'managerUnits' | 'installerUnits' | 'unknown';

  if (pathname.includes('units') && unit?._id) {
    viewType = 'managerUnits';
  } else if (pathname.includes('installer')) {
    viewType = 'installerUnits';
  } else {
    viewType = 'unknown';
  }

  const setView = () => {
    switch (viewType) {
      case 'managerUnits':
        return {
          avatar: (
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                fontSize: '60px',
              }}
              aria-label="Property Icon"
            >
              <ApartmentIcon color="secondary" />
            </Avatar>
          ),
          headerTitle: (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'start',
                textTransform: 'none',
                width: '100%',
              }}
              onClick={() =>
                navigateToSubtitlePath(`/properties/${propertyId}`)
              }
            >
              <Typography
                variant="h3"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                noWrap
              >
                {subtitle ?? ''}
              </Typography>
            </Button>
          ),
        };
      case 'installerUnits':
        return {
          avatar: (
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                fontSize: '60px',
              }}
              aria-label="Property Icon"
            >
              <ApartmentIcon color="secondary" />
            </Avatar>
          ),
          headerTitle: (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'start',
                textTransform: 'none',
                width: '100%',
              }}
              onClick={() =>
                navigateToSubtitlePath(`/properties/${propertyId}/installer`)
              }
            >
              <Typography
                variant="h3"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                noWrap
              >
                {subtitle ?? ''}
              </Typography>
            </Button>
          ),
        };

      default:
        return {
          avatar: (
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                fontSize: '60px',
              }}
              aria-label="Property Icon"
            >
              <ApartmentIcon color="secondary" />
            </Avatar>
          ),
          headerTitle: (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'start',
                textTransform: 'none',
                width: '100%',
              }}
              onClick={() =>
                navigateToSubtitlePath(`/properties/${propertyId}`)
              }
            >
              <Typography
                variant="h3"
                sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                noWrap
              >
                {subtitle ?? ''}
              </Typography>
            </Button>
          ),
        };
    }
  };
  const { avatar, headerTitle } = setView();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CardHeader
      avatar={avatar}
      action={subtitleActionWidget}
      title={headerTitle}
      spacing={0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 'unset',
        borderTop: '1px solid #dff4f2',
        '& .MuiCardHeader-content': {
          maxWidth: isSmall ? 'unset' : 'calc(100vw - 150px)',
          marginLeft: '4px',
          marginRight: '4px',
        },
      }}
    />
  );
}
