import React from 'react';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Thermistor } from '../../../../types/generated-types';
import {
  getDefaultAlertedEntities,
  IAlertedEntities,
} from '../../../system/AlertsManager';
import { TemperatureUnit } from '../../../system/models/temperatureUnits';
import { DisplayValue } from '../../devices/shared-ui';
import { convertToTempUnits } from '../../devices/util';
import {
  MyUnit,
  UnitDataColumn,
  UnitDataRow,
  UnitType,
} from '../types/unitListTypes';
import { DeviceHealthBar } from '../ui/device-health-bar';

enum InstallationStatus {
  SOME_DEVICES = 'Some Devices Installed',
  ALL_DEVICES = 'All Devices Installed',
  NO_DEVICES = 'No Devices Installed',
}

const statusColumn = (_: number): UnitDataColumn => ({
  accessorKey: 'status',
  header: '\u0020',
  enableSorting: false,
  size: 20,
  maxSize: 20,
  minSize: 20,
  grow: false,
  muiTableHeadCellProps: {
    sx: {
      width: '20px',
      padding: '0px',
    },
  },
  muiTableBodyCellProps: {
    align: 'center',
    classes: {
      root: 'status-cell',
    },
    sx: {
      padding: '0px',
    },
  },
  Cell: ({ row }) => {
    return (
      <div style={{ width: '20px', padding: '2px' }}>
        <DeviceHealthBar
          devicesCount={row.original.status?.count}
          majorAlerts={row.original.status?.majorAlerts}
          minorAlerts={row.original.status?.minorAlerts}
          height={31}
        />
      </div>
    );
  },
});

const nameColumn = (screenBreakLevel: number): UnitDataColumn => ({
  accessorKey: 'name',
  header: 'Unit',
  grow: true,
  muiTableHeadCellProps: {
    sx:
      screenBreakLevel > 4
        ? {
            maxWidth: screenBreakLevel > 6 ? 'calc(100vw - 216px)' : undefined,
            minWidth: screenBreakLevel > 6 ? 'calc(100vw - 216px)' : undefined,
          }
        : {},
  },
  muiTableBodyCellProps: {
    style: {
      paddingLeft: '4px',
    },
    sx:
      screenBreakLevel > 4
        ? {
            maxWidth: screenBreakLevel > 6 ? 'calc(100vw - 216px)' : undefined,
            minWidth: screenBreakLevel > 6 ? 'calc(100vw - 216px)' : undefined,
          }
        : {},
  },
});

const buildingColumn = (screenBreakLevel: number): UnitDataColumn => ({
  accessorKey: 'buildingName',
  header: screenBreakLevel > 3 ? 'Bldg' : 'Building',
});

const floorColumn = (_: number): UnitDataColumn => ({
  accessorKey: 'floorName',
  header: 'Floor',
});

const centralEquipmentColumns = (
  preferredUnits: TemperatureUnit,
  screenBreakLevel: number,
): UnitDataColumn[] => [
  statusColumn(screenBreakLevel),
  nameColumn(screenBreakLevel),
  buildingColumn(screenBreakLevel),
  floorColumn(screenBreakLevel),
  {
    accessorKey: 'loopSupply',
    header:
      screenBreakLevel > 5
        ? 'Sply'
        : screenBreakLevel > 2
          ? 'Supply'
          : 'Loop Supply',
    grow: false,
    size: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 80 : 120,
    maxSize: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 80 : 120,
    minSize: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 80 : 120,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row }) => {
      return (
        <div style={{ paddingTop: '4px' }}>
          <DisplayValue
            blankZeros
            valueSize={'16px'}
            value={row.original.loopSupply}
            mode={row.original.operatingState}
            units={preferredUnits}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'loopReturn',
    header:
      screenBreakLevel > 5
        ? 'Rtn'
        : screenBreakLevel > 2
          ? 'Return'
          : 'Loop Return',
    grow: false,

    size: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 80 : 120,
    maxSize: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 80 : 120,
    minSize: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 80 : 120,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row }) => {
      return (
        <div style={{ paddingTop: '4px' }}>
          <DisplayValue
            blankZeros
            valueSize={'16px'}
            value={row.original.loopReturn}
            mode={row.original.operatingState}
            units={preferredUnits}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'delta',
    header:
      screenBreakLevel > 5
        ? '\u0394T'
        : screenBreakLevel > 2
          ? 'Delta'
          : 'Delta Tmp',
    grow: false,

    size: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    maxSize: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    minSize: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row }) => {
      return (
        <div style={{ paddingTop: '4px' }}>
          <DisplayValue
            blankZeros
            valueSize={'16px'}
            value={row.original.delta}
            mode={row.original.operatingState}
            units={preferredUnits}
          />
        </div>
      );
    },
  },
];

const unitTableColumns = (
  preferredUnits: TemperatureUnit,
  screenBreakLevel: number,
): UnitDataColumn[] => [
  statusColumn(screenBreakLevel),
  nameColumn(screenBreakLevel),
  buildingColumn(screenBreakLevel),
  floorColumn(screenBreakLevel),
  {
    accessorKey: 'temperature',
    header:
      screenBreakLevel > 4
        ? 'Tmp'
        : screenBreakLevel > 2
          ? 'Temp'
          : 'Temperature',
    grow: false,
    size: screenBreakLevel > 4 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    maxSize: screenBreakLevel > 4 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    minSize: screenBreakLevel > 4 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row }) => {
      return (
        <div style={{ paddingTop: '4px' }}>
          <DisplayValue
            blankZeros
            valueSize={'16px'}
            value={row.original.temperature}
            mode={row.original.operatingState}
            units={preferredUnits}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'setpoint',
    header: screenBreakLevel > 4 ? 'Tgt' : 'Target',
    size: screenBreakLevel > 4 ? 70 : 90,
    maxSize: screenBreakLevel > 4 ? 70 : 90,
    minSize: screenBreakLevel > 4 ? 70 : 90,
    grow: false,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row }) => {
      return (
        <div style={{ paddingTop: '4px' }}>
          {row.original.operatingMode === 'Auto' ? (
            <div style={{ display: 'inline-flex' }}>
              <DisplayValue
                blankZeros
                valueSize={'16px'}
                value={row.original.heatSetpoint}
                mode={row.original.operatingState}
                units={preferredUnits}
              />
              <span
                style={{ borderRight: '1px solid darkgrey', margin: '2px 4px' }}
              ></span>
              <DisplayValue
                blankZeros
                valueSize={'16px'}
                value={row.original.coolSetpoint}
                mode={row.original.operatingState}
                units={preferredUnits}
              />
            </div>
          ) : (
            <DisplayValue
              blankZeros
              valueSize={'16px'}
              value={row.original.setpoint}
              mode={row.original.operatingState}
              units={preferredUnits}
            />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'humidity',
    header: screenBreakLevel > 2 ? 'RH' : 'Humidity',
    grow: false,
    size: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    maxSize: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    minSize: screenBreakLevel > 5 ? 60 : screenBreakLevel > 2 ? 64 : 120,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row }) => {
      return (
        <div style={{ paddingTop: '4px' }}>
          <DisplayValue
            blankZeros
            valueSize={'16px'}
            value={row.original.humidity}
            units={'%'}
          />
        </div>
      );
    },
  },
];

const installerColumns = (screenBreakLevel: number): UnitDataColumn[] => [
  statusColumn(screenBreakLevel),
  nameColumn(screenBreakLevel),
  buildingColumn(screenBreakLevel),
  floorColumn(screenBreakLevel),
  {
    accessorKey: 'installation',
    header: 'Installed Devices',
    size: screenBreakLevel > 5 ? 192 : undefined,
    maxSize: screenBreakLevel > 5 ? 192 : undefined,
    minSize: screenBreakLevel > 5 ? 192 : undefined,
    Cell: ({ row }) => {
      const icon = ((status) => {
        switch (status) {
          case InstallationStatus.ALL_DEVICES:
            return <CheckCircleIcon color={'success'} />;
          case InstallationStatus.SOME_DEVICES:
            return <RemoveCircleIcon color={'warning'} />;
          case InstallationStatus.NO_DEVICES:
            return <CancelIcon color={'error'} />;
        }
      })(row.original.installation);

      return (
        <ListItem dense>
          <ListItemIcon
            sx={
              screenBreakLevel > 5
                ? {
                    padding: '0px',
                    margin: '0px',
                    minWidth: '0px',
                    marginLeft: '-16px',
                    marginRight: '6px',
                  }
                : {
                    padding: '0px',
                    margin: '0px 10px 0px -10px',
                    minWidth: '0px',
                  }
            }
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            sx={screenBreakLevel > 5 ? { padding: '0px', margin: '0px' } : {}}
            primary={row.original.installation}
          />
        </ListItem>
      );
    },
  },
];

/**
 * columnsForUnitType
 * - select which columns to display, based on type of unit and view mode (installerView)
 * @param t
 * @param installerView
 * @param preferredUnits
 * @param screenBreakLevel
 * @returns Array<UnitDataColumn>
 */
export function columnsForUnitType(
  t: UnitType,
  installerView: boolean,
  preferredUnits: TemperatureUnit,
  screenBreakLevel: number,
): UnitDataColumn[] {
  return (() => {
    switch (t.key) {
      case 'centralEquipment':
        return centralEquipmentColumns(preferredUnits, screenBreakLevel);
      case 'commonArea':
      case 'residential':
        if (installerView) {
          return installerColumns(screenBreakLevel);
        } else {
          return unitTableColumns(preferredUnits, screenBreakLevel);
        }
    }
  })();
}

/**
 * toUnitRow (map function callback)
 * - matches unit data to the format of the data table
 * - returns a function that returns the unit data in row format
 * - wrapped in 'useCallback' because of required external values
 *   of 'preferredUnits', and 'installerView'
 * @param t UnitType
 * @param propertyAlertedEntities
 * @param preferredUnits
 * @param installerView
 * @returns fn
 */
export function toUnitRow(
  t: UnitType,
  propertyAlertedEntities: IAlertedEntities,
  preferredUnits: TemperatureUnit,
  installerView: boolean,
): (u: MyUnit) => UnitDataRow {
  switch (t.key) {
    case 'centralEquipment':
      return (u: MyUnit) => {
        const myAlertedEntities =
          propertyAlertedEntities ?? getDefaultAlertedEntities();
        const status = u.devices.reduce(
          (acc, device) => {
            if (device.installation?.status === 3) {
              acc.count++;
              if (
                !device.sourceId ||
                myAlertedEntities.sources[device.sourceId]
              ) {
                acc.majorAlerts++;
              } else if (
                !device.deviceId ||
                myAlertedEntities.major[device.deviceId]
              ) {
                acc.majorAlerts++;
              } else if (myAlertedEntities.minor[device.deviceId]) {
                acc.minorAlerts++;
              }
            }
            return acc;
          },
          {
            count: 0,
            majorAlerts: 0,
            minorAlerts: 0,
          },
        );

        const supplyTherm = u.devices.find(
          (d) => d.__typename === 'Thermistor' && d.role === 'Supply',
        ) as Thermistor;
        const returnTherm = u.devices.find(
          (d) => d.__typename === 'Thermistor' && d.role === 'Return',
        ) as Thermistor;
        const loopSupply = convertToTempUnits(
          supplyTherm?.temperature?.value,
          preferredUnits,
        );
        const loopReturn = convertToTempUnits(
          returnTherm?.temperature?.value,
          preferredUnits,
        );

        return {
          id: u._id,
          status,
          name: u.name,
          buildingName: u.building?.name ?? '',
          floorName: u.floor?.name ?? '',
          loopSupply: loopSupply?.toString() ?? '',
          loopReturn: loopReturn?.toString() ?? '',
          delta:
            loopSupply && loopReturn
              ? (loopSupply - loopReturn).toString()
              : '',
          operatingState: u.operatingState,
        };
      };
    case 'commonArea':
    case 'residential':
      if (installerView) {
        /* check if any or all devices are installed */
        return (u: MyUnit) => {
          const installedDevices =
            u.devices.filter((d) => d.installation?.status === 3).length ?? 0;
          const totalDevices = u.devices.length ?? 0;

          return {
            id: u._id,
            name: u.name,
            buildingName: u.building?.name ?? '',
            floorName: u.floor?.name ?? '',
            installation:
              installedDevices === 0
                ? InstallationStatus.NO_DEVICES //'No Devices Installed'
                : installedDevices < totalDevices
                  ? InstallationStatus.SOME_DEVICES //'Some Devices Installed'
                  : installedDevices === totalDevices
                    ? InstallationStatus.ALL_DEVICES //'All Devices Installed'
                    : undefined,
          };
        };
      } else {
        return (u: MyUnit) => {
          const myAlertedEntities =
            propertyAlertedEntities ?? getDefaultAlertedEntities();
          const status = u.devices.reduce(
            (acc, device) => {
              if (device.installation?.status === 3) {
                acc.count++;
                if (
                  !device.sourceId ||
                  myAlertedEntities.sources[device.sourceId]
                ) {
                  acc.majorAlerts++;
                } else if (
                  !device.deviceId ||
                  myAlertedEntities.major[device.deviceId]
                ) {
                  acc.majorAlerts++;
                } else if (myAlertedEntities.minor[device.deviceId]) {
                  acc.minorAlerts++;
                }
              }

              return acc;
            },
            {
              count: 0,
              majorAlerts: 0,
              minorAlerts: 0,
            },
          );

          const temperature = convertToTempUnits(u.temperature, preferredUnits);
          const setpoint = convertToTempUnits(u.setpoint, preferredUnits);
          const heatSetpoint = convertToTempUnits(
            u.heatSetpoint,
            preferredUnits,
          );
          const coolSetpoint = convertToTempUnits(
            u.coolSetpoint,
            preferredUnits,
          );

          return {
            id: u._id,
            status,
            name: u.name,
            buildingName: u.building?.name ?? '',
            floorName: u.floor?.name ?? '',
            temperature: temperature?.toString() ?? '',
            heatSetpoint: heatSetpoint?.toString() ?? '',
            coolSetpoint: coolSetpoint?.toString() ?? '',
            setpoint: setpoint?.toString() ?? '',
            humidity: u.humidity?.toString() ?? '',
            operatingState: u.operatingState,
            operatingMode: u.operatingMode,
          };
        };
      }
  }
}

/**
 * bySearchTerm (filter function callback)
 * - perform a non-case-sensitive string search across all values in a UnitDataRow
 * - ignores fields that are not of type string
 * - ignores the id field
 * @param term
 * @returns
 */
export function bySearchTerm(term: string): (u: UnitDataRow) => boolean {
  return (u: UnitDataRow) => {
    /* include all rows if no search term is present */
    if (!term || term === '') return true;

    /* make search case-insensitive */
    const regex = new RegExp(term.toLowerCase());

    /**
     * Collect the values of the unit-data-row except for the id field.
     * Values must also be of type string, so we filter out fields
     * that have a custom-renderer.
     **/
    const searchableFields = Object.entries(u)
      .filter(([k, v]) => k !== 'id' && typeof v === 'string')
      .map((o) => o[1]) as string[];

    /* match on the search term against any of the searchable fields */
    for (const f of searchableFields) {
      if (f.toLowerCase().match(regex)) return true;
    }
    return false;
  };
}

/**
 * onBuildingCount (filter callback)
 * - used to filter table columns to exclude 'buildingName' if only one building exists
 * @param hasBuildings
 * @returns boolean
 */
export function onBuildingCount(
  hasBuildings: boolean,
): (c: UnitDataColumn) => boolean {
  return (c: UnitDataColumn) =>
    hasBuildings ? true : c.accessorKey !== 'buildingName';
}

/**
 * onFloorCount (filter callback)
 * - used to filter table columns to exclude 'floorName' if only one floor exists
 * @param hasFloors boolean
 * @returns boolean
 */
export function onFloorCount(
  hasFloors: boolean,
): (c: UnitDataColumn) => boolean {
  return (c: UnitDataColumn) =>
    hasFloors ? true : c.accessorKey !== 'floorName';
}

/**
 * byUnitType (filter callback)
 * - filters the list of units by type (residential | commonArea | centralEquipment)
 * @param t UnitType
 * @returns boolean
 */
export function byUnitType(t: UnitType): (u: MyUnit) => boolean {
  return (u: MyUnit) => u.type === t.key;
}

/**
 * bySelectedBuildings (filter callback)
 * - filter the list of units by the array of selected buildings
 * @returns fn
 * @param buildings
 */
export function bySelectedBuildings(
  buildings: string[],
): (u: MyUnit) => boolean {
  return (u: MyUnit) => buildings.includes(u.buildingId);
}

/**
 * bySelectedFloors (filter callback)
 * - filter the list of units by the array of selected floors
 * @returns fn
 * @param floors
 */
export function bySelectedFloors(floors: string[]): (u: MyUnit) => boolean {
  return (u: MyUnit) => {
    if (!u.floorId) return true;
    return floors.includes(u.floorId);
  };
}

/**
 * onScreenSize (filter callback)
 * - filters columns from the table if the viewport is small
 * @param isSmall
 * @returns (UnitDataColumn) => boolean
 */
export function onScreenSize(isSmall: boolean): (c: UnitDataColumn) => boolean {
  return (c: UnitDataColumn) =>
    isSmall
      ? c.accessorKey !== 'buildingName' && c.accessorKey !== 'floorName'
      : true;
}

/**
 * bySpecificDevice (filter callback)
 * @param deviceIds string[]
 * @returns (MyUnit) => boolean
 */
export function bySpecificDevices(deviceIds: string[]) {
  return (u: MyUnit) => {
    return Array.isArray(deviceIds) && deviceIds.length
      ? u.devices.some((d) => deviceIds.includes(d._id))
      : true;
  };
}
