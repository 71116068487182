import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BulkThermostats from './thermostats/bulk-thermostats';
// import BulkSetpointLimits from './setpoint-limits/bulk-setpoint-limits';
import Skeleton from '@mui/material/Skeleton';

import { useBulkUpdateEntities } from '../../system/EntityManager';

// Top level container for bulk config
export default function BulkConfig() {
  const [tab, setTab] = useState(0);

  const { properties, error, loading } = useBulkUpdateEntities();

  return (
    // TODO: John: large amount of padding (xs: 12) seems to be required so that
    //  content isn't covered up by top and bottom nav bars maybe our theme or
    //  layout components need modification?
    <Grid
      container
      spacing={4}
      sx={{ paddingTop: 4, justifyContent: 'center' }}
    >
      <Grid item xs={12}>
        <Tabs
          value={tab}
          onChange={(_, v) => setTab(v)}
          textColor="secondary"
          indicatorColor="secondary"
          centered
        >
          <Tab value={0} label="Thermostats" />
          <Tab value={1} label="Setpoint Limits" />
        </Tabs>
      </Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Paper sx={{ padding: 4, justifyContent: 'center' }}>
          {loading && (
            <>
              <Skeleton variant="text" sx={{ fontSize: '1.5em' }} />
              <Skeleton variant="text" sx={{ fontSize: '1.5em' }} />
              <Skeleton variant="text" sx={{ fontSize: '1.5em' }} />
            </>
          )}
          {error && (
            <Typography color="danger">
              Error loading bulk config data.
            </Typography>
          )}
          {properties && (
            <>
              {tab === 0 ? (
                <BulkThermostats />
              ) : (
                // TODO: Feature: Implement bulk setpoint limits or remove this
                //  and the commented import above.
                // <BulkSetpointLimits properties={properties} />
                <div>Not yet implemented</div>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
