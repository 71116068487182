import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useManagedEntitiesQuery,
  useManagedPropertyUpdatesSubscription,
  useManagedUnitUpdatesSubscription,
  useManagedDeviceUpdatesSubscription,
  ManagedEntitiesQuery,
  ManagedPropertyUpdatesSubscription,
  ManagedUnitUpdatesSubscription,
  ManagedDeviceUpdatesSubscription,
  ManagedBuildingUpdatesSubscription,
  ManagedFloorUpdatesSubscription,
  useManagedBuildingUpdatesSubscription,
  useManagedFloorUpdatesSubscription,
  useManagedCoreUpdatesSubscription,
  useManagedHmmUpdatesSubscription,
  useManagedBngUpdatesSubscription,
  ManagedCoreUpdatesSubscription,
  ManagedHmmUpdatesSubscription,
  ManagedBngUpdatesSubscription,
} from '../../types/generated-types';

import { Log, LogCategory } from './services/logger';

import { OnDataOptions } from '@apollo/client/react/types/types';
import { singularize } from 'inflected';
import { ApolloError } from '@apollo/client';

export type ManagedProperty = NonNullable<
  NonNullable<ManagedEntitiesQuery>
>['properties'][number];

export type UpdatedManagedProperty = NonNullable<
  NonNullable<ManagedPropertyUpdatesSubscription>
>['propertyEvents'];

export type ManagedHmm = NonNullable<
  NonNullable<ManagedEntitiesQuery>
>['hMMs'][number];

export type UpdatedManagedHmm = NonNullable<
  NonNullable<ManagedHmmUpdatesSubscription>
>['hMMEvents'];

export type ManagedCore = NonNullable<
  NonNullable<ManagedEntitiesQuery>
>['cores'][number];

export type UpdatedManagedCore = NonNullable<
  NonNullable<ManagedCoreUpdatesSubscription>
>['coreEvents'];

export type ManagedBng = NonNullable<
  NonNullable<ManagedEntitiesQuery>
>['bNGs'][number];

export type UpdatedManagedBng = NonNullable<
  NonNullable<ManagedBngUpdatesSubscription>
>['bNGEvents'];

export type ManagedSource = ManagedHmm | ManagedCore | ManagedBng;
export type UpdatedManagedSource =
  | UpdatedManagedHmm
  | UpdatedManagedCore
  | UpdatedManagedBng;

export type ManagedBuilding = NonNullable<
  NonNullable<ManagedEntitiesQuery>
>['buildings'][number];

export type UpdatedManagedBuilding = NonNullable<
  NonNullable<ManagedBuildingUpdatesSubscription>
>['buildingEvents'];

export type ManagedFloor = NonNullable<
  NonNullable<ManagedEntitiesQuery>
>['floors'][number];

export type UpdatedManagedFloor = NonNullable<
  NonNullable<ManagedFloorUpdatesSubscription>
>['floorEvents'];

export type ManagedUnit = NonNullable<
  NonNullable<ManagedEntitiesQuery>
>['units'][number];

export type UpdatedManagedUnit = NonNullable<
  NonNullable<ManagedUnitUpdatesSubscription>
>['unitEvents'];

export type ManagedDevice = NonNullable<
  NonNullable<ManagedEntitiesQuery>
>['devices'][number];

export type UpdatedManagedDevice = NonNullable<
  NonNullable<ManagedDeviceUpdatesSubscription>
>['deviceEvents'];

export type ManagedPropertiesMap = Record<string, ManagedProperty>;

export type ManagedCoresMap = Record<string, ManagedCore>;

export type ManagedHmmsMap = Record<string, ManagedHmm>;

export type ManagedBngsMap = Record<string, ManagedBng>;

export type ManagedSourcesMap = Record<string, ManagedSource>;

export type ManagedBuildingsMap = Record<string, ManagedBuilding>;

export type ManagedFloorsMap = Record<string, ManagedFloor>;

export type ManagedUnitsMap = Record<string, ManagedUnit>;

export type ManagedDevicesMap = Record<string, ManagedDevice>;

type UpdatedEntity =
  | UpdatedManagedProperty
  | UpdatedManagedSource
  | UpdatedManagedBuilding
  | UpdatedManagedFloor
  | UpdatedManagedUnit
  | UpdatedManagedDevice;

export interface IEntityManagerData {
  properties: ManagedPropertiesMap;
  cores: ManagedCoresMap;
  hmms: ManagedHmmsMap;
  bngs: ManagedBngsMap;
  sources: ManagedSourcesMap;
  buildings: ManagedBuildingsMap;
  floors: ManagedFloorsMap;
  units: ManagedUnitsMap;
  devices: ManagedDevicesMap;
}

export interface IEntityManager extends IEntityManagerData {
  loading: boolean;
  error?: ApolloError;
  properties: ManagedPropertiesMap;
  cores: ManagedCoresMap;
  hmms: ManagedHmmsMap;
  bngs: ManagedBngsMap;
  sources: ManagedSourcesMap;
  buildings: ManagedBuildingsMap;
  floors: ManagedFloorsMap;
  units: ManagedUnitsMap;
  devices: ManagedDevicesMap;
}

const defaultEntityMap: IEntityManagerData = {
  properties: {},
  cores: {},
  hmms: {},
  bngs: {},
  sources: {},
  buildings: {},
  floors: {},
  units: {},
  devices: {},
};

const defaultValue: IEntityManager = { ...defaultEntityMap, loading: false };

interface ManagedEntitiesProviderProps {
  children: React.ReactNode;
}

const ManagedEntitiesContext = createContext<IEntityManager>(defaultValue);

ManagedEntitiesContext.displayName = 'ManagedEntitiesContext';

export const ManagedEntitiesProvider: FC<ManagedEntitiesProviderProps> = ({
  children,
}) => {
  const [managedEntities, setManagedEntities] =
    useState<IEntityManager>(defaultValue);

  const { data, error, loading } = useManagedEntitiesQuery({
    fetchPolicy: 'network-only',
  });

  function updateSourceMap(newManagedEntities: IEntityManager) {
    newManagedEntities.sources = [
      ...Object.values(newManagedEntities.cores),
      ...Object.values(newManagedEntities.hmms),
      ...Object.values(newManagedEntities.bngs),
    ].reduce((res, entity) => {
      res[entity._id] = entity;
      return res;
    }, {} as ManagedSourcesMap);
  }

  useEffect(() => {
    const managedEntitiesData = data;
    if (managedEntitiesData) {
      const newManagedEntities: IEntityManager =
        loading || error
          ? {
              error,
              loading,
              properties: {} as ManagedPropertiesMap,
              cores: {} as ManagedCoresMap,
              hmms: {} as ManagedHmmsMap,
              bngs: {} as ManagedBngsMap,
              sources: {} as ManagedSourcesMap,
              buildings: {} as ManagedBuildingsMap,
              floors: {} as ManagedFloorsMap,
              units: {} as ManagedUnitsMap,
              devices: {} as ManagedDevicesMap,
            }
          : {
              error,
              loading,
              properties: managedEntitiesData.properties.reduce(
                (res, entity) => {
                  res[entity._id] = entity;
                  return res;
                },
                {} as ManagedPropertiesMap,
              ),
              cores: managedEntitiesData.cores.reduce((res, entity) => {
                res[entity._id] = entity;
                return res;
              }, {} as ManagedCoresMap),
              hmms: managedEntitiesData.hMMs.reduce((res, entity) => {
                res[entity._id] = entity;
                return res;
              }, {} as ManagedHmmsMap),
              bngs: managedEntitiesData.bNGs.reduce((res, entity) => {
                res[entity._id] = entity;
                return res;
              }, {} as ManagedBngsMap),
              sources: {} as ManagedSourcesMap,
              buildings: managedEntitiesData.buildings.reduce((res, entity) => {
                res[entity._id] = entity;
                return res;
              }, {} as ManagedBuildingsMap),
              floors: managedEntitiesData.floors.reduce((res, entity) => {
                res[entity._id] = entity;
                return res;
              }, {} as ManagedFloorsMap),
              units: managedEntitiesData.units.reduce((res, entity) => {
                res[entity._id] = entity;
                return res;
              }, {} as ManagedUnitsMap),
              devices: managedEntitiesData.devices.reduce((res, entity) => {
                res[entity._id] = entity;
                return res;
              }, {} as ManagedDevicesMap),
            };
      updateSourceMap(newManagedEntities);

      setManagedEntities(newManagedEntities);
    } else {
      const newManagedEntities: IEntityManager = {
        error,
        loading,
        properties: {} as ManagedPropertiesMap,
        cores: {} as ManagedCoresMap,
        hmms: {} as ManagedHmmsMap,
        bngs: {} as ManagedBngsMap,
        sources: {} as ManagedSourcesMap,
        buildings: {} as ManagedBuildingsMap,
        floors: {} as ManagedFloorsMap,
        units: {} as ManagedUnitsMap,
        devices: {} as ManagedDevicesMap,
      };
      setManagedEntities(newManagedEntities);
    }
  }, [data, error, loading]);

  function updateManagedEntitiesCollection<T extends UpdatedEntity>(
    updatedEntity: T,
    collectionKey: keyof IEntityManagerData,
    prevManagedEntities: IEntityManager,
  ) {
    if (!updatedEntity) return prevManagedEntities;

    const entityType = singularize(collectionKey);

    const newEntityCollection = { ...prevManagedEntities[collectionKey] };
    const newManagedEntities = {
      ...prevManagedEntities,
      [collectionKey]: newEntityCollection,
    };

    const entityToUpdate = { ...updatedEntity };
    delete entityToUpdate.__typename;
    delete entityToUpdate.modelChangeOperation;

    switch (updatedEntity.modelChangeOperation?.toUpperCase()) {
      case 'INSERT':
        const existingEntity = newEntityCollection[entityToUpdate._id];
        if (!existingEntity) {
          newEntityCollection[updatedEntity._id] = entityToUpdate;
        } else {
          console.log(`Attempt to insert already existing ${entityType}!`);
          return prevManagedEntities;
        }
        break;
      case 'UPDATE':
        if (newEntityCollection[entityToUpdate._id]) {
          newEntityCollection[entityToUpdate._id] = Object.assign(
            {},
            newEntityCollection[entityToUpdate._id],
            entityToUpdate,
          );
        } else {
          console.log(`Attempt to update unknown (new?) ${entityType}!`);
          return prevManagedEntities;
        }
        break;
      case 'DELETE':
        if (newEntityCollection[entityToUpdate._id]) {
          delete newEntityCollection[entityToUpdate._id];
        } else {
          console.log(`Attempt to delete unknown ${entityType}!`);
          return prevManagedEntities;
        }
        break;
      default:
        Log.error(
          `Unhandled ${entityType} update operation: ${updatedEntity.modelChangeOperation}`,
          updatedEntity,
          LogCategory.DATA,
        );
    }

    if (['cores', 'hmms', 'bngs'].includes(collectionKey)) {
      updateSourceMap(newManagedEntities);
    }
    return newManagedEntities;
  }

  const processManagedEntityUpdate = useCallback(
    (
      updatedEntity: UpdatedEntity | null | undefined,
      collectionKey: keyof IEntityManagerData,
    ) => {
      if (updatedEntity) {
        setManagedEntities((prevManagedEntities) => {
          return updateManagedEntitiesCollection(
            updatedEntity,
            collectionKey,
            prevManagedEntities,
          );
        });
      } else {
        console.log(
          `Attempt to process empty managed ${singularize(collectionKey)} update.`,
        );
      }
    },
    [setManagedEntities],
  );

  const managedPropertySubscriptionHandler = (
    options: OnDataOptions<ManagedPropertyUpdatesSubscription>,
  ) => {
    processManagedEntityUpdate(options.data.data?.propertyEvents, 'properties');
  };

  const managedCoreSubscriptionHandler = (
    options: OnDataOptions<ManagedCoreUpdatesSubscription>,
  ) => {
    processManagedEntityUpdate(options.data.data?.coreEvents, 'cores');
  };

  const managedHMMSubscriptionHandler = (
    options: OnDataOptions<ManagedHmmUpdatesSubscription>,
  ) => {
    processManagedEntityUpdate(options.data.data?.hMMEvents, 'hmms');
  };

  const managedBNGSubscriptionHandler = (
    options: OnDataOptions<ManagedBngUpdatesSubscription>,
  ) => {
    processManagedEntityUpdate(options.data.data?.bNGEvents, 'bngs');
  };

  const managedBuildingSubscriptionHandler = (
    options: OnDataOptions<ManagedBuildingUpdatesSubscription>,
  ) => {
    processManagedEntityUpdate(options.data.data?.buildingEvents, 'buildings');
  };

  const managedFloorSubscriptionHandler = (
    options: OnDataOptions<ManagedFloorUpdatesSubscription>,
  ) => {
    processManagedEntityUpdate(options.data.data?.floorEvents, 'floors');
  };

  const managedUnitSubscriptionHandler = (
    options: OnDataOptions<ManagedUnitUpdatesSubscription>,
  ) => {
    processManagedEntityUpdate(options.data.data?.unitEvents, 'units');
  };

  const managedDeviceSubscriptionHandler = (
    options: OnDataOptions<ManagedDeviceUpdatesSubscription>,
  ) => {
    processManagedEntityUpdate(options.data.data?.deviceEvents, 'devices');
  };

  useManagedPropertyUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: managedPropertySubscriptionHandler,
  });

  useManagedCoreUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: managedCoreSubscriptionHandler,
  });

  useManagedHmmUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: managedHMMSubscriptionHandler,
  });

  useManagedBngUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: managedBNGSubscriptionHandler,
  });

  useManagedBuildingUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: managedBuildingSubscriptionHandler,
  });

  useManagedFloorUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: managedFloorSubscriptionHandler,
  });

  useManagedUnitUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: managedUnitSubscriptionHandler,
  });

  useManagedDeviceUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: managedDeviceSubscriptionHandler,
  });

  const value: IEntityManager = useMemo(
    () => managedEntities,
    [managedEntities],
  );

  Log.silly('rendering entity manager', null, LogCategory.RENDERING);

  return (
    <ManagedEntitiesContext.Provider value={value}>
      {children}
    </ManagedEntitiesContext.Provider>
  );
};

export const useEntityManager = (): IEntityManager => {
  const context = useContext(ManagedEntitiesContext);
  if (context === undefined) {
    throw new Error(
      'useEntityManager must be used within an ManagedEntitiesProvider',
    );
  }
  return context;
};

export const useProperties = (): {
  properties: ManagedProperty[];
  loading: boolean;
  error?: ApolloError;
} => {
  const { properties, loading, error } = useEntityManager();

  return useMemo(() => {
    return loading
      ? { loading: true, properties: [] }
      : error
        ? { error, properties: [], loading: false }
        : {
            properties: Object.values(properties),
            loading: false,
          };
  }, [properties, loading, error]);
};

export const useSources = (): {
  sources: ManagedSource[];
  loading: boolean;
  error?: ApolloError;
} => {
  const { sources, loading, error } = useEntityManager();

  return useMemo(() => {
    return loading
      ? { loading: true, sources: [] }
      : error
        ? { error, sources: [], loading: false }
        : {
            sources: Object.values(sources),
            loading: false,
          };
  }, [sources, loading, error]);
};

export interface EntityMap {
  properties: Record<string, ManagedProperty>;
  units: Record<string, ManagedUnit>;
  buildings: Record<string, ManagedBuilding>;
  floors: Record<string, ManagedFloor>;
  devices: Record<string, ManagedDevice>;
}

export interface BulkUpdateEntities {
  properties: EntityMap['properties'][string][];
  units: EntityMap['units'][string][];
  buildings: EntityMap['buildings'][string][];
  floors: EntityMap['floors'][string][];
  devices: EntityMap['devices'][string][];
  loading: boolean;
  error?: ApolloError;
}

export const useBulkUpdateEntities: (
  selectedPropertyId?: string | null | undefined,
) => BulkUpdateEntities = (selectedPropertyId: string | null | undefined) => {
  const entities = useEntityManager();

  return useMemo(() => {
    const { properties, units, buildings, floors, devices, loading, error } =
      entities;

    const defaultEntities: EntityMap = {
      properties: {},
      units: {},
      buildings: {},
      floors: {},
      devices: {},
    };

    if (loading || error) {
      return {
        properties: [],
        units: [],
        buildings: [],
        floors: [],
        devices: [],
        loading,
        error,
      };
    }

    const filteredDevices = Object.values(devices).filter(
      (dev) => dev.type === 'Thermostat',
    );

    const filteredEntities: EntityMap =
      loading || error
        ? defaultEntities
        : filteredDevices.reduce(
            (result, tStat) => {
              if (tStat) {
                const prop = properties[tStat.propertyId];
                if (prop) {
                  result.properties[tStat.propertyId] = prop;
                  const unit = units[tStat.unitId];
                  if (unit) {
                    result.units[unit._id] = unit;
                    const building = buildings[unit.buildingId];
                    if (building) {
                      result.buildings[building._id] = building;
                    }
                    if (unit.floorId) {
                      const floor = floors[unit.floorId];
                      if (floor) {
                        result.floors[floor._id] = floor;
                      }
                    }
                  }
                }
                result.devices[tStat._id] = tStat;
              }
              return result;
            },
            {
              properties: {},
              units: {},
              buildings: {},
              floors: {},
              devices: {},
            } as EntityMap,
          );

    return {
      properties: Object.values(filteredEntities.properties),
      units: selectedPropertyId
        ? Object.values(filteredEntities.units).filter(
            (unit) => unit.propertyId === selectedPropertyId,
          )
        : Object.values(filteredEntities.units),
      buildings: selectedPropertyId
        ? Object.values(filteredEntities.buildings).filter(
            (building) => building.propertyId === selectedPropertyId,
          )
        : Object.values(filteredEntities.buildings),
      floors: selectedPropertyId
        ? Object.values(filteredEntities.floors).filter(
            (floor) => floor.propertyId === selectedPropertyId,
          )
        : Object.values(filteredEntities.floors),
      devices: selectedPropertyId
        ? Object.values(filteredEntities.devices).filter(
            (device) => device.propertyId === selectedPropertyId,
          )
        : Object.values(filteredEntities.devices),
      loading,
      error,
    } as BulkUpdateEntities;
  }, [entities, selectedPropertyId]);
};

export const useUnitsByPropertyId = (propertyId: string) => {
  const { units, error, loading } = useEntityManager();

  return useMemo(() => {
    return {
      units:
        error || loading
          ? []
          : Object.values(units).filter(
              (unit) => unit.propertyId === propertyId,
            ),
      error,
      loading,
    };
  }, [propertyId, units, error, loading]);
};
