import * as React from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Tooltip } from '@mui/material';

/* TYPES */
import {
  useRemoveCoreMutation,
  useRemoveHmmMutation,
  useRemoveBngMutation,
  useInitializeSourceMutation,
} from '../../../types/generated-types';

/* SHARED COMPONENTS */
import { Notifier } from '../../system/services/notificationManager';

/* MRT Imports */
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

/* MUI */
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useMediaQuery,
  Typography,
  Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* ICONS */
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/SyncTwoTone';

import { MobileListCard } from '../shared/mobile-list-card';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../system/services/injectableComponentsManager';
import { Add } from '@mui/icons-material';
import { useCallback, useEffect } from 'react';
import { useSystemConnection } from '../../system/ConnectionManager';
import { useAuthorizer } from '../../auth/AuthorizationContext';
import { useSafePadding } from '../../ui/helpers/safe-padding';
import { SearchText } from '../units/ui/search-text';
import Box from '@mui/material/Box';
import { ManagedSource, useSources } from '../../system/EntityManager';

type SourceStatus = 'Connected' | 'Stale' | 'Offline' | 'Uninitialized';
type SourceRow = ManagedSource & {
  id: string;
  lastHeartbeat: string;
  text: SourceStatus;
};

interface RemoveSourceDialogProps {
  open: boolean;
  handleClose: () => void;
  removeSource: () => void;
}

function formatData(data: ManagedSource[]) {
  const myFormattedData: SourceRow[] = [];
  if (data && data.length) {
    data
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((row) => {
        const lastHeartbeat = row.timestamp
          ? moment(row.timestamp).format('MMM D, YYYY h:mm:ss A')
          : 'No heartbeats received';

        const url: string =
          row.url ??
          (row.__typename === 'BNG' ? row.localUrl : undefined) ??
          'No URL Available';
        let _status: SourceStatus = 'Uninitialized';
        if (lastHeartbeat !== 'No heartbeats received') {
          const timeDiff = Date.now() - new Date(lastHeartbeat).valueOf();
          if (timeDiff < 75000) {
            _status = 'Connected';
          } else if (timeDiff < 300000) {
            _status = 'Stale';
          } else {
            _status = 'Offline';
          }
        }
        myFormattedData.push({
          ...row,
          id: row._id,
          url,
          lastHeartbeat,
          text: _status,
        });
      });
  }
  return myFormattedData;
}

const RemoveSourceDialog = (props: RemoveSourceDialogProps) => {
  const { open, handleClose, removeSource } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Remove Source'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure? this action cannot be reversed
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            removeSource();
            handleClose();
          }}
          autoFocus
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SourceList() {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [filterParams, setFilterParams] = useSearchParams();
  const { pathname } = currentLocation;
  const theme = useTheme();
  const { safeTopPadding } = useSafePadding();

  const { sources, error } = useSources();

  useEffect(() => {
    if (error) {
      Notifier.error('Error loading sources. Please try refreshing the page');
      console.error(error);
    }
  }, [error]);

  const [formattedData, setFormatedData] = React.useState<SourceRow[]>([]);

  useEffect(() => {
    if (sources) {
      const type = filterParams.get('type') ?? null;
      const term = filterParams.get('term') ?? null;

      let typeKey: string | null = null;
      if (type) {
        switch (type.toLowerCase()) {
          case 'core':
            typeKey = 'Core';
            break;
          case 'bng':
            typeKey = 'BNG';
            break;
          case 'hmm':
            typeKey = 'HMM';
            break;
          default:
            typeKey = null;
        }
      }
      const filteredSources = sources.filter((source) => {
        const typeRes = type && typeKey ? source.type === typeKey : true;
        const searchRes = term ? source.name.includes(term) : true;

        return typeRes && searchRes;
      });

      const myFormattedData: SourceRow[] =
        filteredSources && filteredSources.length
          ? formatData(filteredSources)
          : [];

      console.log(
        'My filtered and formatted data',
        JSON.stringify(filteredSources),
      );

      setFormatedData(myFormattedData);
    } else {
      setFormatedData([]);
    }
  }, [sources, filterParams]);

  const [sourceId, setSelectedSourceId] =
    React.useState<ManagedSource['_id']>('');
  const [sourceType, setSelectedSourceType] = React.useState('');

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [deleteCore] = useRemoveCoreMutation();
  const [deleteHmm] = useRemoveHmmMutation();
  const [deleteBng] = useRemoveBngMutation();
  const [initializeSource] = useInitializeSourceMutation();

  const handleInitializeSource = useCallback(
    async (sourceId: string) => {
      if (!sourceId) {
        return;
      }

      try {
        await initializeSource({
          variables: {
            id: sourceId,
            options: { pushMeta: true, pushHistoricReadings: true },
          },
        });

        Notifier.success('Source initialized');
      } catch (e) {
        Notifier.error('Error initializing Source');
        console.error(e);
      }
    },
    [initializeSource],
  );

  const handleRemoveSource = async () => {
    switch (sourceType) {
      case 'Core':
        try {
          await deleteCore({ variables: { id: sourceId } });
          Notifier.success('Source deleted');
        } catch (e) {
          Notifier.error('Unable to delete Source');
        }
        break;
      case 'BNG':
        try {
          await deleteBng({ variables: { id: sourceId } });
          Notifier.success('Source deleted');
        } catch (e) {
          Notifier.error('Unable to delete Source');
        }
        break;
      case 'HMM':
        try {
          await deleteHmm({ variables: { id: sourceId } });
          Notifier.success('Source deleted');
        } catch (e) {
          Notifier.error('Unable to delete Source');
        }
        break;
      default:
    }
  };

  function getHeartbeatColor(
    timestamp: number | null | undefined,
    theme: Theme,
    _status: string,
  ): string {
    if (!timestamp) return `${_status} ${theme.palette.secondary.main}`;

    const timeDiff = Date.now() - timestamp;
    if (timeDiff < 75000) {
      return `${_status} ${theme.palette.success.main}`;
    } else if (timeDiff < 300000) {
      return `${_status} ${theme.palette.warning.main}`;
    } else {
      return `${_status} ${theme.palette.error.main}`;
    }
  }

  type SourceDataColumn = MRT_ColumnDef<SourceRow>;

  const newColumns: SourceDataColumn[] = [
    {
      accessorKey: 'deleteAction',
      header: 'Actions',
      Cell: ({ row }) => {
        return (
          <Box sx={{ width: '20px', padding: '2px' }}>
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClickOpen();
                setSelectedSourceType(row.original.type ?? '');
                setSelectedSourceId(row.original._id ?? '');
              }}
              disabled={row.original.inUse}
              style={{ margin: '0 10%' }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
      minSize: 90,
      size: 10,
    },
    {
      accessorKey: 'id',
      header: 'Source ID',
    },
    {
      accessorKey: 'name',
      header: 'Name',
      minSize: 200,
      size: 250,
      Cell: ({ row }) => {
        const localUrl =
          row.original.__typename === 'BNG' && row.original.localUrl;
        const heartbeatColor = getHeartbeatColor(
          row.original.timestamp,
          theme,
          '',
        );
        return isSmall ? (
          <MobileListCard>
            <Typography
              sx={{ fontSize: 14, fontWeight: 800 }}
              color="text.secondary"
              gutterBottom
            >
              {row.original.name}
            </Typography>
            {row.original.url && (
              <Typography variant="body2" gutterBottom noWrap>
                URL: {row.original.url}
              </Typography>
            )}
            {localUrl && (
              <Typography variant="body2" gutterBottom noWrap>
                Local URL: {localUrl}
              </Typography>
            )}
            <Typography variant="body2" noWrap>
              Connection Status:{' '}
              <span
                style={{ backgroundColor: heartbeatColor, padding: '1px 10px' }}
              >
                {row.original.text}
              </span>
            </Typography>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await handleInitializeSource(row.original._id ?? '');
              }}
              fullWidth={true}
              size="small"
              variant="contained"
              aria-label="initialize source"
              sx={{ marginTop: '4px', height: '24px' }}
            >
              Initialize Source
            </Button>
          </MobileListCard>
        ) : (
          row.original.name
        );
      },
    },
    {
      accessorKey: 'url',
      header: 'URL',
      minSize: 200,
      size: 350,
    },
    {
      accessorKey: 'lastHeartbeat',
      header: 'Connection Status',
      minSize: 100,
      size: 200,
      Cell: ({ row }) => {
        const heartbeatColor = getHeartbeatColor(
          row.original.timestamp,
          theme,
          '',
        );
        return (
          <Tooltip title={row.original.lastHeartbeat}>
            <Box
              sx={{
                backgroundColor: heartbeatColor,
                height: 'auto',
                width: '80%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderRadius: '4px',
                padding: '6px 0',
              }}
            >
              {row.original.text}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'initializeAction',
      header: 'Actions',
      minSize: 50,
      size: 100,
      Cell: ({ row }) => (
        <IconButton
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await handleInitializeSource(row.original._id ?? '');
          }}
          sx={{
            margin: '0 10%',
            width: 45,
            height: 45,
            '&:hover': {
              backgroundColor: theme.palette.grey[500],
              color: 'white',
            },
          }}
          aria-label="initialize source"
        >
          <SyncIcon sx={{ fontSize: '1.3em' }} />
        </IconButton>
      ),
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { setContextMenuItems } = useInjectableComponents();
  const { can } = useAuthorizer();

  // const pathComponent = pathname.split('/').slice(0, 5);
  const [columnVisibility, setColumnVisibility] = React.useState({});

  useEffect(() => {
    setColumnVisibility({
      initializeAction: !isSmall && can('initialize', 'Source'),
      deleteAction: false,
      type: !isSmall,
      id: false,
      url: !isSmall,
      lastHeartbeat: !isSmall,
    });
  }, [isSmall, can]);

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    defaultColumn: {
      minSize: 100,
      size: 100,
      enableColumnActions: false,
      sortingFn: 'alphanumeric',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    columns: newColumns,
    state: {
      columnVisibility,
    },
    data: (formattedData ?? []) as SourceRow[], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // enableColumnFilterModes: true,
    // enableColumnOrdering: true,
    // enableGrouping: true,
    // enableColumnPinning: true,
    // enableFacetedValues: true,
    // enableRowActions: true,
    // enableRowSelection: true,
    // enableFilters: true,
    enableStickyHeader: true,
    enableDensityToggle: true,
    initialState: {
      columnVisibility: {
        initializeAction: can('initialize', 'Source'),
        deleteAction: false,
        type: !isSmall,
        id: false,
        url: !isSmall,
        lastHeartbeat: !isSmall,
      },
      density: 'comfortable',
      showGlobalFilter: false,
      sorting: [
        {
          id: 'name', //sort by name by default on page load
          desc: false,
        },
      ],
      columnOrder: [
        'type',
        'name',
        'url',
        'lastHeartbeat',
        'initializeAction',
        'deleteAction',
      ],
    },
    renderEmptyRowsFallback: () => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
          }}
        >
          <Typography>No matching records found</Typography>
        </Box>
      );
    },
    renderTopToolbar: false,
    renderBottomToolbar: false,
    enablePagination: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiTableBodyProps: {
      sx: {
        '& tr:nth-of-type(even)': {
          backgroundColor: 'rgba(241, 241, 241, 0.3) !important',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (row.original?.id) {
          navigate(`${row.original.id}/edit-source`);
        }
      },
      // sx: {
      //   height: screenBreakLevel > 4 ? '37px' : undefined,
      // },
    }),
    muiTableHeadRowProps: {
      sx: {
        // height: screenBreakLevel > 4 ? '37px' : undefined,
        alignContent: 'center',
        '& .Mui-TableHeadCell-Content-Labels': {
          paddingLeft: '0px',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        '& .MuiTableHeadCell-Content': {
          minWidth: '100px',
        },
        '& .MuiTableHeadCell-Content-Labels': {
          paddingLeft: '0px',
        },
      },
    },
    muiTableBodyCellProps: {
      style: {
        padding: '4px 1rem',
      },
    },
    muiTableContainerProps: {
      style: {
        // height: `${Math.max(100, 2 + ((formattedData?.length ?? 0) + 1) * 37)}px`,
        maxHeight: `calc(100vh - ${
          (isSmall ? 145 + safeTopPadding : 135) + 60
        }px)`,
      },
    },
    //   // TODO: Peter ... are we going to use the bottom toolbar/pagination here? If not, remove this.
    // muiPaginationProps: {
    //   color: 'secondary',
    //   rowsPerPageOptions: [10, 20, 30],
    //   shape: 'rounded',
    //   variant: 'outlined',
    // },
  });

  const { client } = useSystemConnection();
  useEffect(() => {
    const items: BottomMenuItems = [
      {
        items: [
          {
            id: 'menu-item-add-source',
            label: 'Add Source',
            icon: <Add fontSize="small" />,
            action: () => navigate('/sources/create'),
            permit: {
              action: 'create',
              subject: 'Source',
            },
          },
          {
            id: 'menu-item-refresh-sources',
            label: 'Refresh Source List',
            icon: <Add fontSize="small" />,
            action: async () => {
              await client?.refetchQueries({ include: ['SourceList'] });
              Notifier.success('Sources refreshed');
            },
          },
        ],
      },
    ];

    setContextMenuItems(items);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [client, navigate, pathname, setContextMenuItems]);

  const handleSearchChange = (term: string) => {
    const newParams = new URLSearchParams(filterParams);
    if (term && term.trim().length > 0) {
      newParams.set('term', term.trim());
    } else {
      newParams.delete('term');
    }
    setFilterParams(newParams);
  };

  return (
    <div
      style={{
        display: 'flex',
        padding: '0px 5px',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
      }}
    >
      <SearchText
        handleChange={handleSearchChange}
        padding={isSmall ? '0 6px' : '20vw'}
        initialValue={filterParams.get('term')}
      />
      <MaterialReactTable table={table} />
      <RemoveSourceDialog
        open={open}
        handleClose={() => handleClose()}
        removeSource={() => handleRemoveSource()}
      />
    </div>
  );
}
