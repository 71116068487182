/**
 * DeviceInstallerDetail
 * See Readme for full documentation of different installer workflows.
 *
 * device:
 * - Local state representation of a partial device object containing installation relevant data.
 *
 * status -- [actions]:
 * - 0: Ready to install -- [Scan, Enter EUID, Back]
 * - 1: Network Open only exists for 60sec [progress indicator] [No Actions]
 * - 2: Network Open Fail -- [Rejoin, Restart Install]
 * - 3: Installed Correctly -- [Done, Rejoin, Replace - Scan, Replace EUID]
 * - 4: Join Fail -- [Rejoin Device, Replace Device, Restart Install]
 *        Normal (not replacing) options on failed device join
 *        Failed to join replacement device. User either needs to try to rejoin or to cancel the replacement (revert to previous device) -
 *        Allow them to just give up at this point and return later
 * - 5: No longer used
 * - 6: No longer used
 *  -7: No longer used (maybe never was)
 * - 8: Device has joined and now is being configured, after which it changes to status code 3.
 *      There is a 60-second timer on this state in client, in case the Core never sends message
 * - 9: Device configuration failed. It is joined but not properly configured. Encourage user to
 *      rejoin the device, since this is likely a transient error. But they can be 'done' and come
 *      back later
 *
 * replacingDevice:
 * - during device replacement, this field contains the id of the device that is in the process of being
 *   replaced. This field is purged once the replacement is completed.
 */

import React from 'react';
import { useParams, useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  Device,
  useInstallerToolsQuery,
  useInstallerToolsUpdatesSubscription,
  useRegisterDeviceMutation,
  useSetInstallationStatusMutation,
  useUnregisterDeviceMutation,
} from '../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';

import { StatusMessage } from './components/status-message';
import { ActionButtons } from './components/action-buttons';
import { ConnectionIndicator } from './components/connection-indicator';
import { StatusIndicator } from './components/status-indicator';
import { ProgressIndicator } from './components/progress-indicator';
import { Notifier } from '../../system/services/notificationManager';
import { QRScanner } from './components/qr-scanner';
import { EnterEuidDialog } from './components/enter-euid';
import FullWidthLoadingSkeleton from '../shared/fullWidthLoadingSkeleton';
import {
  checkPermissionStatus,
  startQrScan,
  stopQrScan,
  toggleTorch,
} from './lib/qr-scanner';
import { InstallationAction, InstallationStatus } from './types';
import { Log } from '../../system/services/logger';
import { useDeviceIsOnline } from '../../system/AlertsManager';

function run<T>(fn: () => T): T {
  return fn();
}

export function InstallerTools() {
  const { deviceId } = useParams<{ deviceId: string }>();
  const navigate = useNavigate();

  /* Query Data, Mutations, Subscription */
  const { data, loading, error } = useInstallerToolsQuery({
    variables: { id: deviceId || '' },
    fetchPolicy: 'network-only',
  });

  const [registerDevice, { error: registrationError }] =
    useRegisterDeviceMutation();
  const [forceSetStatus] = useSetInstallationStatusMutation();
  const [unregisterDevice] = useUnregisterDeviceMutation();

  useInstallerToolsUpdatesSubscription({
    variables: { ids: [deviceId ?? '0'] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
    onError: (e) => {
      Log.error('[installer-tools] error', error);
      setStatusMessage(e.message);
    },
  });

  /* State */
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [device, setDevice] = React.useState<Partial<Device>>();
  const [progress, setProgress] = React.useState<number>(); //* Current state of the progress timer */
  const [statusMessage, setStatusMessage] = React.useState<string>(); //* Additional information displayed in the status message */
  const [timerOn, setTimerOn] = React.useState(false); //* Countdown timer */
  const [showScanner, setShowScanner] = React.useState<boolean>(); //* Show or hide the scanner view */
  const [scanError, setScanError] = React.useState<string>();
  const [installationStatus, setInstallationStatus] =
    React.useState<InstallationStatus>(); //* See comments above for explanation */

  /* Action to be performed at timeout */
  const [timeoutAction, setTimeoutAction] = React.useState<() => void>(
    () => () => undefined,
  );

  /* ZigBee deviceId scanned from device QR code - ensure only lower-case hex digits [a-f] */
  const [euid, _setEuid] = React.useState<string>();
  const setEuid = (input?: string) => {
    const _input = input ?? '';
    _setEuid(_input.toLowerCase());
  };

  // TODO: Peter & Nathan: Is it a good idea to do the 'run' stuff below or is there a better way to do this?
  //   If we continue to do this, we should handle/do something with the promise returned by these 'run' things.

  /* Timeout Functions:  Run in the event that one of the timers expires*/
  function timeoutNetworkJoin() {
    /* This action is called if there is no response from the Core.  It's possible but unlikely that the server status
     * will still change.  If that happens, the client status will be updated by the data-effect handler.
     * In the meantime, or in case the core is actually unresponsive, use the forceSetStatus mutation to set the installation
     * status to 2.
     **/

    stopTimer();
    setInstallationStatus(InstallationStatus.NETWORK_OPEN_FAILED);
    setStatusMessage('');
    run(async () => {
      try {
        if (device?._id) {
          await forceSetStatus({
            variables: {
              options: {
                _id: device._id,
                status: 2,
                message: 'The join process has timed out. Please try again.',
                // rawDeviceId: euid,
              },
            },
          });
        }
      } catch (e) {
        Log.error(
          '[device-installer] [network-open-failed] [force-status-set] error',
          e,
        );
      }
    });
  }

  const timeoutDeviceConfiguration = React.useCallback(() => {
    /**
     * Run this function in the event that device configuration times out.  We don't always
     * get confirmation from the Core that configuration succeeded or failed, so after 60sec
     * we assume that the process is dead.  It's possible, but unlikely, that the Core will
     * still send a Config-SuccessOrFail message, in which case this state will be overwritten.
     * In the meantime the user has the ability to start over.
     */

    stopTimer();
    setInstallationStatus(InstallationStatus.CONFIGURATION_FAILED);
    setStatusMessage('');

    run(async () => {
      try {
        if (device?._id) {
          await forceSetStatus({
            variables: {
              options: {
                _id: device._id,
                status: 9,
                message: 'Device configuration has failed. Please try again.',
              },
            },
          });
        }
      } catch (e) {
        Log.error(
          `[device-installer] [configuration-failed] [force-status-set] error: ${e}`,
        );
      }
    });
  }, [device?._id, forceSetStatus]);

  /* Cancel Function:  See description... */
  function cancelNetworkJoin() {
    /**
     * This action does not effectively cancel the installation attempt.  Once the request is sent, the
     * Core will continue on with the installation process, eventually terminating in a status 4 (join failed),
     * or a status 2 (network failed to open).
     *
     * In fact, a user could press the cancel button and then press the join button on the device, resulting
     * in a successful join & installation.
     * Thus, the cancel button only serves as a navigational tool, allowing
     * the user to 'change course' and e.g. work on a different device.
     * The only meaningful result of this action is a status of 4, since this is the most likely outcome.
     *
     * We therefore set the status 4, but with a message of 'Installation Cancelled By Installer',
     * to distinguish from the final state that will be applied within less than 1 minute.
     *
     * Notes:
     *  - if the network opened, then any other join requests sent within the next 60 seconds will be
     * met with a 'ERR_BAD_REQUEST'.
     *  - this cancel command can be used to gain access to the 'Try With A New Device' action, which
     *    effectively uninstalls (un-registers) the device, so that the user can start fresh.
     *
     */
    stopTimer();

    run(async () => {
      try {
        setInstallationStatus(InstallationStatus.INSTALLATION_FAILED);

        if (device?._id) {
          await forceSetStatus({
            variables: {
              options: {
                _id: device._id,
                status: 4,
                message: `Installation cancelled by installer. Wait approximately 1 minute before the next join attempt.`,
              },
            },
          });
        }
      } catch (e) {
        Log.error(
          `[device-installer] [cancel-join] [force-status-set] --> error ${e}`,
        );
      }
    });
  }

  const dispatchAction = (action: InstallationAction) => {
    /* The central click handler for all ActionButtons */

    switch (action) {
      case InstallationAction.SCAN_START:
        setShowScanner(true);
        setInstallationStatus(InstallationStatus.SCAN_IN_PROGRESS);
        setStatusMessage('');

        run(async () => {
          try {
            setEuid(await startQrScan());
            setInstallationStatus(InstallationStatus.SCAN_SUCCESSFUL);
          } catch (e) {
            setInstallationStatus(InstallationStatus.SCAN_FAILED);
          } finally {
            setShowScanner(false);
          }
        });

        break;

      case InstallationAction.SCAN_CANCEL:
        setShowScanner(false);
        setInstallationStatus(InstallationStatus.READY_TO_INSTALL);

        run(async () => {
          try {
            await stopQrScan();
          } catch (e) {
            Log.error(`[device-installer] SCAN_CANCEL - error: ${e}`);
          }
        });

        break;

      case InstallationAction.TOGGLE_TORCH:
        run(async () => {
          await toggleTorch();
        });
        break;

      case InstallationAction.JOIN_INITIATE:
        /* Call registerDevice mutation to start 'Join Device'
         * Installation status will be updated by the effect handler for registerDevice
         */
        if (!device?._id || !euid) {
          setStatusMessage(
            "Unable to register device without a valid device ID.  Please scan this device's QR code or enter a valid 16 digit hexadecimal device ID.",
          );
          break;
        }
        setInstallationStatus(InstallationStatus.NETWORK_OPEN_REQUESTED);
        setStatusMessage('');
        startTimer(30, cancelNetworkJoin);
        run(async () => {
          try {
            if (device?._id && euid) {
              await registerDevice({
                variables: {
                  id: device._id,
                  euid: euid,
                },
              });
              startTimer(60, timeoutNetworkJoin);
            } else {
              Notifier.error('Invalid device EUID');
              Log.error(
                `[device-installer] - ERROR - attempting to join without a valid deviceId or EUID
                ${device?._id},
                ${euid},
                `,
              );
            }
          } catch (e) {
            const message = e instanceof Error && e.message;
            Log.error(
              `[device-installer] [register-device] Request Failed ${message} ${e}`,
            );
            setStatusMessage('');
          }
        });
        break;

      case InstallationAction.JOIN_CANCEL:
        cancelNetworkJoin();
        break;

      case InstallationAction.RESTART_INSTALLATION:
        /* Force set the installation status to 0 so that installation can be re-started
         * This should also unregister the device on the core, in case it has already been joined.
         */
        run(async () => {
          try {
            if (device?._id) {
              await unregisterDevice({ variables: { id: device._id } });
            }
            setEuid('');
            setStatusMessage('');
            setInstallationStatus(InstallationStatus.READY_TO_INSTALL);
          } catch (e) {
            Log.error(`[device-installer][restart-installation][error]: ${e}`);
          }
        });
        break;

      case InstallationAction.REPLACE_INITIATE:
        /* The user has selected to replace an existing device.
         * Display the view applicable to the 'Device Replace Workflow'.
         */
        setInstallationStatus(InstallationStatus.READY_TO_REPLACE);
        setStatusMessage('');
        break;

      case InstallationAction.INSTALLER_EXIT:
        /* Exit the installer and navigate to the list of zones and installable devices */
        if (device?.propertyId && device?.unitId) {
          navigate(
            `/properties/${device?.propertyId}/installer/${device?.unitId}`,
          );
        } else if (device?.propertyId) {
          navigate(`/properties/${device?.propertyId}/installer/`);
        } else {
          navigate('/properties');
        }
        break;

      case InstallationAction.ENTER_EUID:
        /* Primarily meant for non-mobile clients - display a dialog allowing an EUID to be keyed
         * instead of scanned
         */
        setDialogOpen(true);
        break;

      default:
        setInstallationStatus(InstallationStatus.ERROR_STATE);
    }
  };

  const handleInput = (input: string) => {
    setEuid(input);
    setDialogOpen(false);
    setInstallationStatus(InstallationStatus.SCAN_SUCCESSFUL);
  };

  const startTimer = (startTime = 60, action?: () => void) => {
    setProgress(startTime);
    if (action !== undefined) {
      setTimeoutAction(() => action);
    }
    setTimerOn(true);
  };

  const stopTimer = () => {
    setTimerOn(false);
    setTimeoutAction(() => () => undefined);
  };

  /**
   * Effect Handler - Ensure the user has granted scanner permissions.  Make sure the
   * scanner view is closed when the InstallerTools component is destroyed (e.g. the user
   * clicks the back button while the scanner is open)
   */
  React.useEffect(() => {
    run(async () => {
      try {
        await checkPermissionStatus();
      } catch (e) {
        if (e instanceof Error) {
          setScanError(e.message);
        } else {
          setScanError('SCANNER_PERMISSION_ERROR');
        }
      }
    });

    /* Clean up scanner view on exit in case the scanner is still open */
    return () => {
      setShowScanner(false);
      run(async () => {
        try {
          await stopQrScan();
        } catch (e) {
          Log.error(`[device-installer] SCAN_CANCEL - error: ${e}`);
        }
      });
    };
  }, []);

  /* Effect Handler - Timer Management */
  React.useEffect(() => {
    let countdownTimer: string | number | NodeJS.Timeout | undefined =
      undefined;

    if (timerOn) {
      countdownTimer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress === 0) {
            run(timeoutAction);
            stopTimer();
          }
          return prevProgress && prevProgress - 1;
        });
      }, 1000);
    } else {
      clearInterval(countdownTimer);
    }

    return () => clearInterval(countdownTimer);
  }, [timeoutAction, timerOn]);

  /**
   * Query & Subscription Effects
   * This is the central effect handler where data updates are propagated to the local state
   **/
  React.useEffect(() => {
    if (error) {
      Log.error(`[device-installer][subscription] error: ${error}`);
      setStatusMessage(error.message);
    } else if (data?.deviceById) {
      setDevice(data.deviceById as Partial<Device>);
    }
  }, [data, error]);

  const deviceIsOnline = useDeviceIsOnline(device);

  React.useEffect(() => {
    if (device) {
      const { status, message, rawDeviceId } = device.installation ?? {};

      Log.debug(`[installer-tools] --> Installation Status ${status}`);

      /* update EUID with rawDeviceId if the value exists, otherwise skip the update in case
         the value exists on the client but not yet on the server */
      rawDeviceId && setEuid(rawDeviceId as string);

      /* copy installation status to local state so that the correct view is generated */
      setInstallationStatus(status as InstallationStatus);
      setStatusMessage(message ?? '');

      /**
       * Evaluate current installation status and start or stop timers accordingly
       */
      if (status === InstallationStatus.NETWORK_OPEN) {
        /* Network is open - show 60sec timer with progress
         * the server-side status message that would get displayed here can be confusing.
         * rather display nothing. Previously the timer was set here, but it is better to set
         * the timer when the promise returns, so this event is now no-op.
         */
      }

      if (status === InstallationStatus.INSTALLATION_SUCCESSFUL) {
        setStatusMessage('');
        stopTimer();
      }

      if (
        status === InstallationStatus.NETWORK_OPEN_FAILED ||
        status === InstallationStatus.INSTALLATION_FAILED ||
        status === InstallationStatus.CONFIGURATION_FAILED
      ) {
        /* Clear timer if join succeeds or fails */
        stopTimer();
      }

      if (status === InstallationStatus.CONFIGURATION_IN_PROGRESS) {
        startTimer(60, timeoutDeviceConfiguration);
        setStatusMessage('');
      }

      if (!deviceIsOnline) {
        setStatusMessage(
          'This device has lost connection to its Core.  Please rejoin the device.',
        );
      }

      if (registrationError) {
        Log.error(
          `[device-installer][subscription] error: ${registrationError}`,
        );
        setStatusMessage(registrationError.message);
      }
    }
  }, [device, deviceIsOnline, registrationError, timeoutDeviceConfiguration]);

  return (
    <Box
      display="flex"
      justifyContent={'center'}
      marginX="auto"
      sx={{
        width: {
          xs: '100%',
          sm: '75%',
          md: '60%',
          lg: '50%',
          xl: '40%',
        },
        height: '100%',
      }}
    >
      {data ? (
        <Card
          variant={'outlined'}
          sx={{
            m: 1,
            background: 'inherit',
            width: '100%',
          }}
        >
          <CardHeader
            action={
              <Stack direction={'row'} spacing={2}>
                <ConnectionIndicator
                  show={
                    device?.installation?.status ===
                    InstallationStatus.INSTALLATION_SUCCESSFUL
                  }
                  deviceOffline={!deviceIsOnline}
                />
                <StatusIndicator status={device?.installation?.status} />
              </Stack>
            }
            title={`${device?.name} - ${device?.type}`}
            subheader={
              <Stack>
                <Box>{`Current Device Id: ${device?.deviceId}`}</Box>
                <Box>{`Scanned Id: ${euid ?? 'none'}`}</Box>
              </Stack>
            }
            sx={{
              background: '#fff',
            }}
          />
          <CardContent sx={{ background: 'inherit' }}>
            <Box
              sx={{ background: 'inherit' }}
              alignItems={'center'}
              justifyContent={'center'}
              display={'flex'}
            >
              <Stack alignItems={'center'} justifyContent={'center'}>
                <StatusMessage
                  status={installationStatus}
                  message={statusMessage}
                />
                <ProgressIndicator
                  show={timerOn}
                  progress={progress}
                  status={installationStatus}
                />
                <QRScanner show={showScanner} error={scanError} />
                <EnterEuidDialog
                  show={dialogOpen}
                  handleInput={handleInput}
                  handleClose={() => setDialogOpen(false)}
                />
              </Stack>
            </Box>
            <ActionButtons
              status={installationStatus}
              hasEuid={!!euid}
              handleClick={dispatchAction}
            />
          </CardContent>
        </Card>
      ) : error ? (
        <Typography>Error: {error.message}</Typography>
      ) : loading ? (
        <FullWidthLoadingSkeleton padding={1} />
      ) : (
        <Typography>Unknown Data Error</Typography>
      )}
    </Box>
  );
}
