import React, { useEffect, useState } from 'react';
import { Log } from '../../system/services/logger';
import { Notifier } from '../../system/services/notificationManager';
import { useProperties, ManagedProperty } from '../../system/EntityManager';
import { useTestPropertyListUpdateSubscription } from '../../../types/generated-types';
import { handleModelUpdateEvent } from '../../../helpers/subscriptionUtils';

export const PropertyList = () => {
  const [propertyEventsErrorMessage, setPropertyEventsErrorMessage] = useState<
    string | null
  >(null);

  const { properties, error: propertiesError } = useProperties();
  const [selectedProperty, selectProperty] = useState<ManagedProperty | null>(
    null,
  );

  const {
    error: propertyEventsError,
    data: propertyEventData,
    // loading: propertyEventsLoading,
    // variables: propertyEventsVariables,
  } = useTestPropertyListUpdateSubscription({
    variables: { _ids: [selectedProperty?._id || ''] },
    fetchPolicy: 'no-cache',
    onData: handleModelUpdateEvent(({ data: subscriptionData }) => {
      const { data } = subscriptionData;
      if (data.propertyEventsByIds._id === selectedProperty?._id) {
        selectProperty(data.propertyEventsByIds);
      }
    }),
  });

  // What follows is an alternative way to do subscription-based real-time updates
  //  using 'subscribeToMore' rather than employing a generated react-hook wrapped
  //  subscription callback factory.
  //
  //  {} = usePropertiesQuery({ fetchPolicy: 'no-cache' });
  //
  // useEffect(() => {
  //   subscribeToMore<Property>({
  //     document: PropertyListUpdateDocument,
  //     updateQuery: (prev, res) => {
  //       const { subscriptionData, variables } = res;
  //
  //       if (!subscriptionData.data) return prev;
  //
  //       const newVal: typeof prev = { ...prev };
  //       newVal.properties = [...prev.properties];
  //       return newVal;
  //     },
  //   });
  // }, []);

  useEffect(() => {
    if (propertyEventsError) {
      const message = propertyEventsError.message;
      Log.log('property error', message);
      setPropertyEventsErrorMessage(message);
      if (selectedProperty) {
        Notifier.error(
          `[Live Data Error] Unable to provide real-time updates for property events for ${selectedProperty?.title}: ${message}.`,
        );
      }
    } else {
      setPropertyEventsErrorMessage(null);
    }
  }, [propertyEventsError, selectedProperty]);

  useEffect(() => {
    if (propertiesError) {
      Notifier.error(
        `[Data Error] Unable to display properties data. You are not logged in. ${propertiesError}`,
      );
    }
  }, [propertiesError]);

  return (
    <>
      <div className="Subscriptions-col">
        <p>Properties</p>
        <div className="Subscriptions-col">
          <ul>
            {properties ? (
              properties.map((property) => (
                <li
                  key={property._id}
                  style={{
                    cursor: 'pointer',
                    color:
                      property._id === selectedProperty?._id
                        ? 'red'
                        : 'inherit',
                  }}
                  onClick={() =>
                    selectProperty(
                      selectedProperty?._id === property._id ? null : property,
                    )
                  }
                >
                  {property.title}
                </li>
              ))
            ) : (
              <p>{propertiesError?.message || 'No Data'}</p>
            )}
          </ul>
        </div>
      </div>
      <div className="Subscriptions-col">
        <p>Property Related Events</p>
        {selectedProperty ? (
          <>
            <p style={{ fontSize: '14px' }}>
              {propertiesError ? (
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  Error&nbsp;
                </span>
              ) : (
                ''
              )}
              Monitoring Property:
              <br />
              &nbsp; &nbsp;
              {selectedProperty?.title || 'Nothing'}
            </p>
            <div className="Subscriptions-col">
              {propertyEventsErrorMessage ? (
                <p>{propertyEventsErrorMessage}</p>
              ) : (
                selectedProperty && (
                  <pre className="Subscriptions-data">
                    {JSON.stringify(
                      propertyEventData?.propertyEventsByIds ||
                        '--- No Events Received Yet ---',
                      null,
                      2,
                    )}
                  </pre>
                )
              )}
            </div>
          </>
        ) : (
          <div>No Property Selected.</div>
        )}
      </div>
    </>
  );
};
