import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  // AlertsManagerDevicesQuery,
  AlertsManagerAlertsQuery,
  Maybe,
  Panel,
  useAlertsManagerAlertsQuery,
  // useAlertsManagerDevicesQuery,
  useAlertsManagerAlertUpdateSubscription,
  // useAlertsManagerDeviceUpdateSubscription,
  Zone,
} from '../../types/generated-types';

import { Log, LogCategory } from './services/logger';

import { OnDataOptions } from '@apollo/client/react/types/types';
import { ApolloError } from '@apollo/client';

export type ActiveAlert = NonNullable<
  NonNullable<AlertsManagerAlertsQuery>
>['alerts'][number];

// export type ActiveDevice = NonNullable<
//   NonNullable<AlertsManagerDevicesQuery>
// >['devices'][number];

export type AlertsMap = Record<string, ActiveAlert>;
export type EntityAlertsMap = Record<string, AlertsMap>;
export type ActiveAlertMap = {
  alert: AlertsMap;
  property: EntityAlertsMap;
  source: EntityAlertsMap;
  unit: EntityAlertsMap;
  zone: EntityAlertsMap;
  panel: EntityAlertsMap;
  device: EntityAlertsMap;
};

// In the UI, historically called these minor alerts "notices" and the major alerts "alerts"
// kevin fowler proposed categorizing alerts into critical, major, minor and info categories.
// These two are still mapped in the UI two notices and alerts, for now.
// export const MinorAlertTypes = [
//   'LOW_RH',
//   'HIGH_RH',
//   'LOW_TEMP',
//   'HIGH_TEMP',
//   'LOW_SUPPLY_TEMP',
//   'HIGH_SUPPLY_TEMP',
//   'LOW_RETURN_TEMP',
//   'HIGH_RETURN_TEMP',
//   'LEAK_DETECTED',
// ];

export const MajorAlertTypes = ['DEVICE_OFFLINE', 'EQUIPMENT_ALARM'];

export interface IAlertsManager {
  // Alerts is an Alert map by entity type, then entity id then alertId
  alerts: ActiveAlertMap;
  error?: ApolloError | undefined | null;
  loading: boolean;
}

const defaultAlertsMap: ActiveAlertMap = {
  alert: {},
  property: {},
  source: {},
  unit: {},
  zone: {},
  panel: {},
  device: {},
} as ActiveAlertMap;

const defaultValue: IAlertsManager = {
  alerts: defaultAlertsMap,
  loading: false,
};

interface AlertsProviderProps {
  children: React.ReactNode;
}

const AlertsContext = createContext<IAlertsManager>(defaultValue);

AlertsContext.displayName = 'AlertsContext';

export const AlertsProvider: FC<AlertsProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<ActiveAlertMap>(defaultValue.alerts);

  const {
    data: alertsData,
    loading: alertsLoading,
    error: alertsError,
  } = useAlertsManagerAlertsQuery();

  // const { data: devicesData } = useAlertsManagerDevicesQuery();

  useEffect(() => {
    if (alertsData) {
      const newAlerts: ActiveAlertMap = {
        alert: {},
        property: {},
        source: {},
        unit: {},
        zone: {},
        panel: {},
        device: {},
      };

      alertsData.alerts.forEach((alert) => {
        newAlerts.alert[alert._id] = alert;
        if (alert.property?.id) {
          (newAlerts.property[alert.property.id] ||= {})[alert._id] = alert;
        }

        // if (alert.unit?.id) {
        //   (newAlerts.unit[alert.unit.id] ||= {})[alert._id] = alert;
        // }
        //
        // if (alert.device?.id) {
        //   (newAlerts.device[alert.device.id] ||= {})[alert._id] = alert;
        // }
      });

      setAlerts(newAlerts);
    }
  }, [alertsData]);

  const alertSubscriptionHandler = useCallback(() => {
    return (
      options: OnDataOptions<ActiveAlert & { modelChangeOperation: string }>,
    ) => {
      if (options.data?.data) {
        const alertToUpdate: ActiveAlert = options.data.data;

        switch (alertToUpdate.modelChangeOperation) {
          case 'INSERT':
            setAlerts((prevAlerts) => {
              const newAlerts = { ...prevAlerts };

              const existingAlert = prevAlerts.alert[alertToUpdate._id];
              if (!existingAlert) {
                newAlerts.alert[alertToUpdate._id] = alertToUpdate;
                if (alertToUpdate.property?.id) {
                  (newAlerts.property[alertToUpdate.property.id] ||= {})[
                    alertToUpdate._id
                  ] = alertToUpdate;
                }
              } else {
                console.log('Attempt to insert already existing alert!');
              }
              return newAlerts;
            });
            break;
          case 'UPDATE':
            setAlerts((prevAlerts) => {
              const newAlerts = { ...prevAlerts };
              if (newAlerts.alert[alertToUpdate._id]) {
                Object.assign(
                  newAlerts.alert[alertToUpdate._id],
                  alertToUpdate as unknown as [value: any],
                );
              } else {
                newAlerts.alert[alertToUpdate._id] = alertToUpdate;
                if (alertToUpdate.property?.id) {
                  (newAlerts.property[alertToUpdate.property.id] ||= {})[
                    alertToUpdate._id
                  ] = alertToUpdate;
                }
              }
              return newAlerts;
            });
            break;
          case 'DELETE':
            setAlerts((prevAlerts) => {
              const newAlerts = { ...prevAlerts };
              delete newAlerts.alert[alertToUpdate._id];
              if (alertToUpdate.property?.id) {
                delete (newAlerts.property[alertToUpdate.property.id] ||= {})[
                  alertToUpdate._id
                ];
              }
              return newAlerts;
            });
            break;
          default:
            Log.error(
              `Unhandled alert update operation: ${alertToUpdate.modelChangeOperation}`,
              alertToUpdate,
              LogCategory.DATA,
            );
        }
      } else {
        console.log('Detected empty alert update operation');
      }
    };
  }, [setAlerts]);

  // const deviceSubscriptionHandler = useCallback(() => {
  //   return (
  //     options: OnDataOptions<ActiveAlert & { modelChangeOperation: string }>,
  //   ) => {
  //     if (options.data?.data) {
  //       const alertToUpdate: ActiveAlert = options.data
  //         .data as unknown as ActiveAlert;
  //
  //       switch (alertToUpdate.modelChangeOperation) {
  //         case 'INSERT':
  //           setAlerts((prevAlerts) => {
  //             const newAlerts = { ...prevAlerts };
  //
  //             const existingAlert = prevAlerts.alert[alertToUpdate._id];
  //             if (!existingAlert) {
  //               newAlerts.alert[alertToUpdate._id] = alertToUpdate;
  //               if (alertToUpdate.property?.id) {
  //                 (newAlerts.property[alertToUpdate.property.id] ||= {})[
  //                   alertToUpdate._id
  //                 ] = alertToUpdate;
  //               }
  //             } else {
  //               console.log('Attempt to insert already existing alert!');
  //             }
  //             return newAlerts;
  //           });
  //           break;
  //         case 'UPDATE':
  //           setAlerts((prevAlerts) => {
  //             const newAlerts = { ...prevAlerts };
  //             if (newAlerts.alert[alertToUpdate._id]) {
  //               Object.assign(
  //                 newAlerts.alert[alertToUpdate._id],
  //                 alertToUpdate as unknown as [value: any],
  //               );
  //             } else {
  //               newAlerts.alert[alertToUpdate._id] = alertToUpdate;
  //               if (alertToUpdate.property?.id) {
  //                 (newAlerts.property[alertToUpdate.property.id] ||= {})[
  //                   alertToUpdate._id
  //                 ] = alertToUpdate;
  //               }
  //             }
  //             return newAlerts;
  //           });
  //           break;
  //         case 'DELETE':
  //           setAlerts((prevAlerts) => {
  //             const newAlerts = { ...prevAlerts };
  //             delete newAlerts.alert[alertToUpdate._id];
  //             if (alertToUpdate.property?.id) {
  //               delete (newAlerts.property[alertToUpdate.property.id] ||= {})[
  //                 alertToUpdate._id
  //               ];
  //             }
  //             return newAlerts;
  //           });
  //           break;
  //         default:
  //           Log.error(
  //             `Unhandled alert update operation: ${alertToUpdate.modelChangeOperation}`,
  //             alertToUpdate,
  //             LogCategory.DATA,
  //           );
  //       }
  //     } else {
  //       console.log('Detected empty alert update operation');
  //     }
  //   };
  // }, [setAlerts]);

  useAlertsManagerAlertUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: alertSubscriptionHandler,
  });

  // useAlertsManagerDeviceUpdateSubscription({
  //   fetchPolicy: 'no-cache',
  //   onData: deviceSubscriptionHandler,
  // });

  const value: IAlertsManager = useMemo(
    () => ({
      alerts: alerts,
      loading: alertsLoading,
      error: alertsError,
    }),
    [alerts, alertsLoading, alertsError],
  );

  Log.silly('rendering alerts manager', null, LogCategory.RENDERING);

  return (
    <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>
  );
};

export const useAlertsManager = (): IAlertsManager => {
  const context = useContext(AlertsContext);
  if (context === undefined) {
    throw new Error('useAlertsManager must be used within an AlertsProvider');
  }
  return {
    alerts: context.alerts,
    error: context.error,
    loading: context.loading,
  };
};

export const useAllAlerts = (): ActiveAlert[] => {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    return Object.values(alerts.alert);
  }, [alerts.alert]);
};

export const usePropertyAlerts = (
  propertyId: string | Maybe<string> | undefined,
): {
  alerts: ActiveAlert[];
  error?: ApolloError | undefined | null;
  loading: boolean;
} => {
  const { alerts, error, loading } = useAlertsManager();

  return useMemo(() => {
    if (!propertyId) return { alerts: [], loading: false };

    // Get all alerts from the alerts.alert map
    return {
      alerts: Object.values(alerts.alert).filter(
        (alert) => alert.property?.id === propertyId,
      ),
      loading,
      error,
    };
  }, [alerts, loading, error, propertyId]);
};

// export const useUnitAlerts = (
//   unitId: string | Maybe<string> | undefined,
// ): ActiveAlert[] => {
//   const { alerts } = useAlertsManager();
//
//   return useMemo(() => {
//     if (!unitId) return [];
//
//     return Object.values(alerts.alert).filter(
//       (alert) => alert.unit?.id === unitId,
//     );
//   }, [alerts.alert, unitId]);
// };

export function useDeviceAlerts<
  D extends
    | {
        deviceId?: string | Maybe<string> | undefined;
        sourceId?: string | Maybe<string> | undefined;
      }
    | undefined,
>(device: D) {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    if (!device?.deviceId?.trim()) return [];

    return Object.values(alerts.alert).filter(
      (alert) =>
        alert.device?.id === device.deviceId ||
        (alert.sourceId === device.sourceId &&
          alert.type?.id === 'SOURCE_OFFLINE'),
    );
  }, [alerts.alert, device]);
}

export function useDeviceHasAlerts<
  D extends
    | {
        deviceId?: string | Maybe<string> | undefined;
        sourceId?: string | Maybe<string> | undefined;
      }
    | undefined,
>(device: D) {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    if (!device?.deviceId?.trim()) return false;

    return Object.values(alerts.alert).some(
      (alert) =>
        alert.device?.id === device.deviceId ||
        (alert.sourceId === device.sourceId &&
          alert.type?.id === 'SOURCE_OFFLINE'),
    );
  }, [alerts.alert, device]);
}

export function useDeviceIsOnline<
  D extends
    | {
        deviceId?: string | Maybe<string> | undefined;
        sourceId?: string | Maybe<string> | undefined;
      }
    | undefined,
>(device: D) {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    if (!device?.deviceId?.trim()) return false;

    return !Object.values(alerts.alert).some(
      (alert) =>
        (alert.device?.id === device.deviceId &&
          alert.type?.id === 'DEVICE_OFFLINE') ||
        (alert.sourceId === device.sourceId &&
          alert.type?.id === 'SOURCE_OFFLINE'),
    );
  }, [alerts.alert, device]);
}

export function useZoneAlerts(zoneId?: string | Maybe<string>) {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    if (!zoneId?.trim()) return [];

    return Object.values(alerts.alert).filter(
      (alert) => alert.zone?.id === zoneId,
    );
  }, [alerts.alert, zoneId]);
}

export function usePanelAlerts(panelId?: string | Maybe<string>) {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    if (!panelId?.trim()) return [];

    return Object.values(alerts.alert).filter(
      (alert) => alert.panel?.id === panelId,
    );
  }, [alerts.alert, panelId]);
}

// export function usePropertyHasAlerts(
//   propertyId?: string | Maybe<string> | null | undefined,
// ) {
//   const { alerts } = useAlertsManager();
//
//   return useMemo(() => {
//     if (!propertyId?.trim()) return false;
//
//     return Object.values(alerts.alert).some(
//       (alert) => alert.property?.id === propertyId,
//     );
//   }, [alerts.alert, propertyId]);
// }
//
// export function useUnitHasAlerts(
//   unitId?: string | Maybe<string> | null | undefined,
// ) {
//   const { alerts } = useAlertsManager();
//
//   return useMemo(() => {
//     if (!unitId?.trim()) return false;
//
//     return Object.values(alerts.alert).some(
//       (alert) => alert.unit?.id === unitId,
//     );
//   }, [alerts.alert, unitId]);
// }

export interface IAlertsSummary {
  majorAlerts: number;
  minorAlerts: number;
}

export function usePropertyAlertSummary(
  propertyId?: string | Maybe<string>,
): IAlertsSummary {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    let res: IAlertsSummary = {
      majorAlerts: -1,
      minorAlerts: -1,
    };

    if (!propertyId?.trim()) return res;

    res.majorAlerts = 0;
    res.minorAlerts = 0;

    res = Object.values(alerts.property[propertyId] ?? {}).reduce(
      (result, alert) => {
        if (alert.type?.id) {
          if (MajorAlertTypes.includes(alert.type?.id)) {
            result.majorAlerts += 1;
          } else {
            result.minorAlerts += 1;
          }
        }
        return result;
      },
      res,
    );

    return res;
  }, [alerts.property, propertyId]);
}

export interface IAlertedEntities {
  major: Record<string, boolean>;
  minor: Record<string, boolean>;
  sources: Record<string, boolean>;
}

export const getDefaultAlertedEntities: () => IAlertedEntities = () => ({
  major: {},
  minor: {},
  sources: {},
});

export function useAlertedEntitiesByProperty(
  propertyId?: string | Maybe<string>,
): IAlertedEntities {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    if (!propertyId?.trim()) return getDefaultAlertedEntities();

    return Object.values(alerts.property[propertyId] ?? {}).reduce(
      (result, alert) => {
        if (alert?.type?.id) {
          if (alert.type.id === 'SOURCE_OFFLINE') {
            if (alert.sourceId) {
              result.sources[alert.sourceId] = true;
            }
          } else if (MajorAlertTypes.includes(alert.type.id)) {
            if (alert.device?.id) {
              result.major[alert.device.id] = true;
            }
          } else {
            if (alert.device?.id) {
              result.minor[alert.device.id] = true;
            }
          }
        }

        return result;
      },
      getDefaultAlertedEntities(),
    );
  }, [alerts.property, propertyId]);
}

export function useContainerHasAlerts(
  container:
    | Partial<Zone>
    | Partial<Panel>
    | Maybe<Zone>
    | Maybe<Partial<Zone>>
    | Maybe<Panel>
    | Maybe<Partial<Panel>>
    | undefined,
) {
  const { alerts } = useAlertsManager();

  return useMemo(() => {
    if (!container?._id) return false;

    return Object.values(alerts.alert).some((alert) =>
      container.__typename === 'Panel'
        ? alert.panel?.id === container._id
        : container.__typename === 'Zone'
          ? alert.zone?.id === container._id
          : false,
    );
  }, [alerts.alert, container]);
}
