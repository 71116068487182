import React, { useEffect, useState } from 'react';
/* MUI */
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
/* MUI Icons */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import OpacityOutlinedIcon from '@mui/icons-material/OpacityOutlined';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
/* Gen Types */
import {
  LeakDetector,
  useLeakDetectorDetailQuery,
  useLeakDetectorDetailUpdateSubscription,
} from '../../../../types/generated-types';

/* Shared */
import moment from 'moment';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import {
  DeviceAlerts,
  ToggleAlertDetailsButton,
} from '../shared-ui/alerts-info';
import { DeviceInfo, DeviceInformation } from '../shared-ui/device-info';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { useDeviceHasAlerts } from '../../../system/AlertsManager';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function LeakDetectorSensorIcon(leakStatus: any, deviceType: any) {
  let color;
  if (leakStatus === 'Leak Detected') {
    color = '#36AFA5';
  } else {
    color = 'rgba(0, 0, 0, 0.63)';
  }
  switch (deviceType) {
    case 'LeakDetector':
      return <OpacityOutlinedIcon sx={{ color, fontSize: '1.3rem' }} />;
    default:
      return <HelpCenterOutlinedIcon sx={{ color, fontSize: '1.3rem' }} />;
  }
}

export function LeakDetectorSensorComponent({
  deviceId,
}: {
  deviceId: string;
}) {
  const {
    data,
    // TODO: Loading_error: handle loading/error for leak detector detail query.
    // loading,
    // error
  } = useLeakDetectorDetailQuery({
    variables: { id: deviceId },
  });
  const [device, setDevice] = useState<Partial<LeakDetector>>();
  const [alertsExpanded, setAlertsExpanded] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformation[]>([]);

  const hasAlerts = useDeviceHasAlerts(device);

  useLeakDetectorDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });
  useEffect(() => {
    if (data?.leakDetectorById) {
      setDevice(data.leakDetectorById as any);
    }
  }, [data]);

  useEffect(() => {
    if (device) {
      const panelInfo: DeviceInformation = {
        Zone: device.zone?.name ?? 'Unnamed',
        'Zone ID': device.zone?._id ?? 'Unidentified',
        Source: device.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] = [
        {
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          firmwareInfo: {
            Model:
              device.meta?.model || device.meta?.modelNumber || 'Unknown model',
            Version: device.meta?.version || 'Unknown version',
          },
          'Last Updated': moment(device.timestamp).fromNow(),
        },
      ];

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [device]);

  const sensor1Triggered =
    device?.leakStatus1?.value === true ||
    device?.leakStatus1?.value?.toString() === 'true';
  const sensor2Triggered =
    device?.leakStatus2?.value === true ||
    device?.leakStatus2?.value?.toString() === 'true';
  const leakDetected = sensor1Triggered || sensor2Triggered;
  const deviceType = device?.type || 'unknown';
  const statusText = leakDetected
    ? `${device?.name || 'Unnamed sensor'} - Leak${
        sensor1Triggered && sensor2Triggered ? 's' : ''
      } detected on ${
        sensor1Triggered && sensor2Triggered
          ? 'both sensors'
          : 'sensor ' + (sensor1Triggered ? '1' : '2')
      }`
    : `${device?.name || 'Unnamed sensor'} is Dry`;
  const icon = LeakDetectorSensorIcon(leakDetected, deviceType);
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return device ? (
    <div
      style={{
        width: '100%',
      }}
    >
      <Card
        sx={{
          backgroundColor: hasAlerts ? '#ffd5d5' : '#ebf7f6',
          border: hasAlerts ? '1px solid red' : '',
          boxShadow: 1,
        }}
      >
        <CardHeader
          sx={{
            paddingTop: '0px',
            paddingLeft: '10px',
            paddingRight: '10ox',
            paddingBottom: '0px',
          }}
          title={<Typography variant="h5">{statusText}</Typography>}
          avatar={icon}
          action={
            <List dense={false}>
              <ListItem
                sx={{
                  '&:hover': { backgroundColor: 'InfoBackground' },
                  padding: 0,
                }}
              >
                <ListItemIcon>
                  <ToggleAlertDetailsButton
                    device={device}
                    expand={alertsExpanded}
                    buttonLabel="Show alerts"
                    onClick={(event) => {
                      event.preventDefault();
                      setAlertsExpanded(!alertsExpanded);
                    }}
                  />
                  {/*{hasBatterVoltage ? (*/}
                  {/*  <IconButton edge="end" aria-label="delete">*/}
                  {/*    <Battery*/}
                  {/*      charge={getBatteryLevel(device.voltage?.value)}*/}
                  {/*    />*/}
                  {/*  </IconButton>*/}
                  {/*) : null}*/}
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </ListItemIcon>
              </ListItem>
            </List>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <DeviceInfo infoEntries={deviceInfo} />
          </CardContent>
        </Collapse>
        <DeviceAlerts device={device} alertsExpanded={alertsExpanded} />
      </Card>
    </div>
  ) : (
    <DeviceLoadingSkeleton />
  );
}
