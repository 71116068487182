/**
 * ThermostatComponent
 **/

import _ from 'lodash';
import React from 'react';
import './thermostat.css';

/* MUI */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

/* Icons */
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { ThermostatIcon } from './components/status-icons/thermostat-icon';

/* Sub Components */
import {
  DisplayScreen,
  MenuButtons,
  StatusIcons,
  SystemEdit,
  UpdatePending,
} from './components';

/* Types */
import {
  dailyScheduleDefault as _dailyScheduleDefault,
  CurrentAttributes,
  DayOfWeek,
  DefaultSchedule,
  DeviceInfo,
  EmptyWeeklySchedule,
  InputMaybe,
  MyDailySchedule,
  MyScheduleObject,
  MyScheduleSetting,
  MyThermostat,
  MyWeeklySchedule,
  ThermostatComponentProps,
  ThermostatUpdateFields,
  TimeOfDay,
  useThermostatDetailQuery,
  useThermostatDetailUpdateSubscription,
  useUpdateThermostatMutation,
} from './types';

/* Lib Imports (component specific functions) */
import {
  areSchedulesEqual,
  formatHoldEnd,
  updateInfoBox,
  updateSubscribedValues,
} from './lib';

/* Util */
import { convertToTempUnits, cToF, formatTemperatureString } from '../util';
import { applySetpointLimitToWeeklyScheduleValues } from '../util/apply-setpoint-limit-to-schedule-values';

import { Celsius, Fahrenheit } from '../../../system/models/temperatureUnits';
import { Notifier } from '../../../system/services/notificationManager';

import {
  DeviceAlerts,
  ToggleAlertDetailsButton,
} from '../shared-ui/alerts-info';
import { DeviceInfo as DeviceInfoBox } from '../shared-ui/device-info';

import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import { defaultAbsoluteLimits } from '../../helpers';

import {
  EnumScheduleType,
  Property,
  TemperatureUnit,
  Unit,
  UpdateThermostatMutationVariables,
} from '../../../../types/generated-types';
import { useAuthenticator } from '../../../auth/AuthenticationContext';
import {
  useDeviceHasAlerts,
  useDeviceIsOnline,
} from '../../../system/AlertsManager';
import { BLANK } from '../shared-ui/constants';
import { convertToPreferredWeeklyScheduleUnits } from '../util/convert-to-preferred-schedule-units';
import { ThermostatScheduleUpdateDialog } from './components/dialogs/thermostat-schedule-update-dialog';
import { ThermostatSchedule } from './components/schedule/thermostat-schedule';
import { SmartPowerOutletLink } from './components/status-icons/smart-power-outlet-link';
export enum ViewMode {
  NORMAL = 'normal',
  EDIT_UNCHANGED = 'edit_unchanged',
  EDIT = 'edit',
  SCHEDULE_UNCHANGED = 'schedule-unchanged',
  SCHEDULE = 'schedule',
  UPDATING = 'updating',
  AWAITING = 'awaiting',
  OFFLINE = 'offline',
}

export enum MenuAction {
  EDIT_SCHEDULE = 'edit_schedule',
  EDIT_VALUES = 'edit_values',
  SAVE_CHANGES = 'save_changes',
  REVERT_CHANGES = 'revert_changes',
  CANCEL = 'cancel',
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return (
    <div
      style={{
        borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
        height: '100%',
        display: 'flex',
        padding: expand ? '0px 0px 0px 0px' : '0px 0px 0px 5px',
        margin: expand ? '0px 12px 0px 0px' : '0px 6px 0px 0px',
      }}
    >
      <IconButton
        style={{
          padding: expand ? '0px 6px 0px 3px' : '0px 9px 0px 1px',
          margin: '0px 0px 0px 4px',
        }}
        {...other}
      />
    </div>
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function ThermostatComponent(props: ThermostatComponentProps) {
  const {
    id,
    propertySetpointProfile,
    unitSetpointProfile,
    pairedSensor,
    property,
    unit,
    smartPowerOutlet,
    preferredUnits = Fahrenheit,
  } = props;

  const {
    data,
    loading,
    // TODO: Loading_error: should we handle error here for this query?
    // error,
  } = useThermostatDetailQuery({
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [updateThermostat] = useUpdateThermostatMutation();

  useThermostatDetailUpdateSubscription({
    variables: { ids: [id] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  /* State */
  const [device, setDevice] = React.useState<MyThermostat>();
  const [deviceInfo, setDeviceInfo] =
    React.useState<Record<string, DeviceInfo>>();
  const [statusMessage, setStatusMessage] = React.useState(BLANK);
  const [originalValues, setOriginalValues] =
    React.useState<CurrentAttributes>();
  const [newValues, setNewValues] = React.useState<CurrentAttributes>();
  // schedule: original schedule pulled from the device and convert to preferred units
  const [schedule, setSchedule] = React.useState<MyScheduleObject>(
    _.cloneDeep(DefaultSchedule),
  );
  // newSchedule: new schedule set by the user or default/placeholder schedule
  const [newSchedule, setNewSchedule] = React.useState<MyScheduleObject>(
    _.cloneDeep(DefaultSchedule),
  );
  const [scheduleType, setScheduleType] = React.useState<EnumScheduleType>();
  const [viewMode, setViewMode] = React.useState(ViewMode.NORMAL);
  const [expanded, setExpanded] = React.useState(false);
  const [alertsExpanded, setAlertsExpanded] = React.useState(false);
  const [showResumeSchedule, setShowResumeSchedule] = React.useState(false);
  const [briefScheduleStatus, setBriefScheduleStatus] = React.useState<
    string | undefined
  >(undefined);
  const { user } = useAuthenticator();
  const [updateThermostatVariables, setUpdateThermostatVariables] =
    React.useState<UpdateThermostatMutationVariables | undefined>(undefined);
  const [
    openThermostatScheduleUpdateDialog,
    setOpenThermostatScheduleUpdateDialog,
  ] = React.useState(false);

  const applySetpointLimits = React.useCallback(
    (
      settings: CurrentAttributes,
      usesCustomLimits?: boolean | null,
      device?: MyThermostat,
    ): CurrentAttributes => {
      const myDefaultAbsoluteLimits = defaultAbsoluteLimits[preferredUnits];
      let newSettings: CurrentAttributes;
      if (usesCustomLimits) {
        newSettings = {
          ...settings,
          maxCoolSetpointLimit:
            convertToTempUnits(
              device?.maxCoolSetpointLimit?.value,
              preferredUnits,
            ) ?? myDefaultAbsoluteLimits.cool.max,
          maxHeatSetpointLimit:
            convertToTempUnits(
              device?.maxHeatSetpointLimit?.value,
              preferredUnits,
            ) ?? myDefaultAbsoluteLimits.heat.max,
          minCoolSetpointLimit:
            convertToTempUnits(
              device?.minCoolSetpointLimit?.value,
              preferredUnits,
            ) ?? myDefaultAbsoluteLimits.cool.min,
          minHeatSetpointLimit:
            convertToTempUnits(
              device?.minHeatSetpointLimit?.value,
              preferredUnits,
            ) ?? myDefaultAbsoluteLimits.heat.min,
        };
      } else {
        newSettings = {
          ...settings,
          maxCoolSetpointLimit:
            settings.baseMaxCoolSetpointLimit ??
            myDefaultAbsoluteLimits.cool.max,
          maxHeatSetpointLimit:
            settings.baseMaxHeatSetpointLimit ??
            myDefaultAbsoluteLimits.heat.max,
          minCoolSetpointLimit:
            settings.baseMinCoolSetpointLimit ??
            myDefaultAbsoluteLimits.cool.min,
          minHeatSetpointLimit:
            settings.baseMinHeatSetpointLimit ??
            myDefaultAbsoluteLimits.heat.min,
        };
      }
      return newSettings as CurrentAttributes;
    },
    [preferredUnits],
  );

  /* Effects */

  /**
   * Respond to changes of query & subscription 'data', as well as component parameters
   * of 'preferredUnits', 'propertySetpointProfile', 'unitSetpointProfile'.
   *
   * Initialize or Update Device Readings
   * - Create a map of current values that can be edited within this interface.
   * - Apply unit or property level setpoint limit profiles.
   * - Apply preferred temperature units to temperature data.
   * - Initialize schedule information.
   * - Determine the viewMode for the interface based on updates from query data.
   *
   * Initialize or Update Schedule
   * - create a schedule object matching the structure of the incoming schedule data
   * - use a new object and initialize set temperatures using the preferred unit of measure
   * - store the schedule object in local state
   *
   **/
  React.useEffect(() => {
    const myDevice = data && (data.thermostatById as MyThermostat);

    if (myDevice) {
      setDevice(myDevice);
      setSchedule((current) => {
        const scheduleValue: MyScheduleObject['value'] =
          _.cloneDeep(EmptyWeeklySchedule);
        const { schedule } = myDevice;

        Object.entries(schedule?.value || {}).forEach(
          ([dayOfWeek, dailySchedule]) => {
            const _dayOfWeek = dayOfWeek as unknown as DayOfWeek;
            const _dailySchedule = dailySchedule as unknown as MyDailySchedule;
            scheduleValue[_dayOfWeek] = {};
            Object.entries(_dailySchedule).forEach(
              ([timeOfDay, scheduleSetting]: [string, MyScheduleSetting]) => {
                const _timeOfDay = timeOfDay as unknown as TimeOfDay;
                scheduleValue[_dayOfWeek][_timeOfDay] = {};

                scheduleValue[_dayOfWeek][_timeOfDay].minimum =
                  preferredUnits === Fahrenheit
                    ? cToF(scheduleSetting.minimum)
                    : scheduleSetting.minimum;

                scheduleValue[_dayOfWeek][_timeOfDay].maximum =
                  preferredUnits === Fahrenheit
                    ? cToF(scheduleSetting.maximum)
                    : scheduleSetting.maximum;
              },
            );
          },
        );

        const initialSchedule: MyScheduleObject = {
          id: schedule?.id as string,
          units: schedule?.units as string,
          timestamp: schedule?.timestamp as number,
          value: scheduleValue,
          updatePending: schedule?.updatePending as boolean,
        };

        return { ...current, ...initialSchedule };
      });

      // Set schedule type when data first loads and the thermostat has a schedule set

      if (!scheduleType && myDevice.scheduleType) {
        setScheduleType(myDevice.scheduleType);
      } else {
        if (myDevice?.schedule) {
          if (
            _.isEqual(myDevice.schedule.value, EmptyWeeklySchedule) &&
            !scheduleType
          ) {
            setScheduleType(EnumScheduleType.None);
          }
        }
      }

      let initialValues = updateSubscribedValues(
        myDevice,
        preferredUnits,
        unitSetpointProfile,
        propertySetpointProfile,
      );

      initialValues = applySetpointLimits(
        initialValues,
        myDevice.useCustomSetpointLimits,
        myDevice,
      );

      if (myDevice.hasPendingUpdates) {
        if (viewMode === ViewMode.UPDATING) {
          setViewMode(ViewMode.AWAITING);
        } else if (viewMode !== ViewMode.AWAITING) {
          Notifier.warn('Thermostat being updated on another device');
          setViewMode(ViewMode.AWAITING);
          setNewValues(undefined);
        }
      } else {
        if (ViewMode.AWAITING === viewMode) {
          setOriginalValues(initialValues);
          setNewValues(initialValues);
          setTimeout(() => {
            setNewValues(undefined);
            Notifier.info('Thermostat update completed');
            setViewMode(ViewMode.NORMAL);
          }, 1000);
        } else {
          setOriginalValues(initialValues);
        }
      }
    }
  }, [
    applySetpointLimits,
    data,
    preferredUnits,
    propertySetpointProfile,
    scheduleType,
    unitSetpointProfile,
    viewMode,
  ]);

  const isOffline = !useDeviceIsOnline(device);
  const hasAlerts = useDeviceHasAlerts(device);

  React.useEffect(() => {}, [device]);

  React.useEffect(() => {
    if (
      viewMode !== ViewMode.NORMAL &&
      originalValues !== undefined &&
      newValues === undefined
    ) {
      setNewValues({ ...originalValues });
    }
  }, [newValues, originalValues, viewMode]);

  /**
   * Respond to changes in the 'isOffline' state flag
   */
  React.useEffect(() => {
    isOffline ? setViewMode(ViewMode.OFFLINE) : setViewMode(ViewMode.NORMAL);
  }, [isOffline]);

  /* Update information displayed in the info box */
  React.useEffect(() => {
    if (device !== undefined) {
      const deviceInfo = updateInfoBox(props, device, newValues);
      setDeviceInfo(deviceInfo);
    }
  }, [props, device, setDeviceInfo, newValues]);

  React.useEffect(() => {
    const holdForever = device?.isHoldForever ?? false;
    const onHold =
      (device?.hasSchedule ?? false) && (device?.isOffSchedule ?? false);
    setShowResumeSchedule((holdForever || onHold) && !isOffline);
  }, [device, isOffline]);

  React.useEffect(() => {
    let displayString: string | undefined = 'No schedule: Unknown device';
    if (device) {
      if (device.isHoldForever) {
        displayString = 'Schedule is set to hold forever';
      } else {
        const holdExpiryTimeTimestamp = device?.holdExpiryTime?.timestamp || 0;
        const holdStateTimestamp = device?.holdState?.timestamp || 0;

        if (holdExpiryTimeTimestamp > holdStateTimestamp) {
          displayString = `Schedule hold ends ${formatHoldEnd(device)}`;
        } else {
          displayString = undefined;
        }
      }
    }

    setBriefScheduleStatus(displayString);
  }, [device]);

  /**
   * handleChange: make changes to setTemperature values
   /* - handle edits of setpoints, operating mode, fan mode
   * - use a copy of current values to avoid being overwritten by subscription updates
   */
  const handleChange = React.useCallback(
    (changes: Record<string, number | string | boolean>) => {
      const myChanges: Record<
        keyof CurrentAttributes,
        number | string | boolean | string[]
      > = changes;
      const myValues = { ...newValues, ...myChanges } as CurrentAttributes;

      setNewValues((current) => {
        return { ...current, ...myChanges } as CurrentAttributes;
      });

      let changesDetected = false;
      if (newValues && originalValues) {
        const changedKeys = Object.keys(originalValues).find((key) => {
          const myKey: keyof CurrentAttributes =
            key as unknown as keyof CurrentAttributes;
          return myValues[myKey] !== originalValues[myKey];
        });
        if (changedKeys) {
          changesDetected = true;
        }
      }

      if (changesDetected) {
        setViewMode(ViewMode.EDIT);
      } else {
        setViewMode(ViewMode.EDIT_UNCHANGED);
      }
    },
    [newValues, originalValues],
  );

  const handleUseCustomSetpointLimitsChange = (
    useCustomSetpointLimits: boolean,
  ) => {
    let myValues = {
      ...newValues,
      useCustomSetpointLimits,
    } as CurrentAttributes;

    // If switching from custom setpoint limits to non-custom limits, applySetpointLimits will adjust
    // the limits based on the assigned unit or property setpoint limit profile, if one exists.
    // If switching from non-custom limits to custom limits, applySetpointLimits will set the limits
    // to the default absolute setpoint limits - the most permissive set of limits.
    myValues = applySetpointLimits(
      myValues,
      useCustomSetpointLimits,
      undefined,
    );

    // Update setpoints if they fall outside the range of the new setpoint limits
    const conformingChanges = conformToSetpointLimits(myValues);

    // Construct the set of all new changes, based on current device attributes
    let newChanges: Record<string, number | string | boolean> = {
      useCustomSetpointLimits,
    };
    // Include any setpoint limit changes
    if (myValues.minHeatSetpointLimit !== device?.minHeatSetpointLimit) {
      newChanges.minHeatSetpointLimit = myValues.minHeatSetpointLimit;
    }
    if (myValues.maxHeatSetpointLimit !== device?.maxHeatSetpointLimit) {
      newChanges.maxHeatSetpointLimit = myValues.maxHeatSetpointLimit;
    }
    if (myValues.minCoolSetpointLimit !== device?.minCoolSetpointLimit) {
      newChanges.minCoolSetpointLimit = myValues.minCoolSetpointLimit;
    }
    if (myValues.maxCoolSetpointLimit !== device?.maxCoolSetpointLimit) {
      newChanges.maxCoolSetpointLimit = myValues.maxCoolSetpointLimit;
    }
    // Include any setpoint changes
    if (conformingChanges) {
      newChanges = {
        ...newChanges,
        ...conformingChanges,
      };
    }

    handleChange(newChanges);
    return newChanges;
  };

  const conformToSetpointLimits = React.useCallback(
    (
      values?: CurrentAttributes,
      mode?: string,
      limit?: string,
      direction?: number,
      enforce?: boolean,
    ) => {
      if (!enforce && newValues === undefined) {
        return {};
      }

      const baseValues = values
        ? values
        : newValues
          ? newValues
          : ({} as CurrentAttributes);

      const myDefaultAbsoluteLimits = defaultAbsoluteLimits[preferredUnits];
      const myDirection = direction ?? 1;
      const myMode = mode ?? 'heat';

      const myValues: CurrentAttributes = { ...baseValues };

      const isAutoMode = myValues.operatingMode === 'Auto';

      const deadband = preferredUnits === Celsius ? 1 : 2;

      // If Auto mode, could be converting from non-Auto mode, where the relationship
      // between heat and cool setpoints was ignored. So, need first of all to enforce
      // the deadband. Choosing to modify cool setpoint to correct for deadband.
      if (isAutoMode) {
        // check absolute limits
        if (
          myDefaultAbsoluteLimits.heat.min + deadband >
          myDefaultAbsoluteLimits.cool.max
        ) {
          Notifier.error(
            'Unable to honor deadband. Please contact Embue support.',
          );
          console.log(
            '[thermostat-conformToSetpointLimits] deadband impossible to honor',
          );
          return null;
        }

        if (mode === 'heat') {
          if (limit === 'max') {
            if (direction === 1) {
              if (
                myValues.maxHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max
              ) {
                myValues.maxHeatSetpointLimit =
                  myDefaultAbsoluteLimits.heat.max;
                // return null;
              }
            } else {
              if (
                myValues.maxHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min
              ) {
                myValues.maxHeatSetpointLimit =
                  myDefaultAbsoluteLimits.heat.min;
                // return null;
              }
            }
          } else if (limit === 'min') {
            if (direction === 1) {
              if (
                myValues.minHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max
              ) {
                myValues.minHeatSetpointLimit =
                  myDefaultAbsoluteLimits.heat.max;
                // return null;
              }
            } else {
              if (
                myValues.minHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min
              ) {
                myValues.minHeatSetpointLimit =
                  myDefaultAbsoluteLimits.heat.min;
                // return null;
              }
            }
          }
        } else if (mode === 'cool') {
          if (limit === 'max') {
            if (direction === 1) {
              if (
                myValues.maxCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max
              ) {
                myValues.maxCoolSetpointLimit =
                  myDefaultAbsoluteLimits.cool.max;
                // return null;
              }
            } else {
              if (
                myValues.maxCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min
              ) {
                myValues.maxCoolSetpointLimit =
                  myDefaultAbsoluteLimits.cool.min;
                // return null;
              }
            }
          } else if (limit === 'min') {
            if (direction === 1) {
              if (
                myValues.minCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max
              ) {
                myValues.minCoolSetpointLimit =
                  myDefaultAbsoluteLimits.cool.max;
                // return null;
              }
            } else {
              if (
                myValues.minCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min
              ) {
                myValues.minCoolSetpointLimit =
                  myDefaultAbsoluteLimits.cool.min;
                // return null;
              }
            }
          }
        }

        // Check absolute and internal relational values for heat limits.
        // min < a_min
        if (myValues.minHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min) {
          myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
        }

        // max > a_max
        if (myValues.maxHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max) {
          myValues.maxHeatSetpointLimit = myDefaultAbsoluteLimits.heat.max;
        }

        // min > a_max
        if (myValues.minHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max) {
          myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
        }

        // max < a_min
        if (myValues.maxHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min) {
          myValues.maxHeatSetpointLimit = myDefaultAbsoluteLimits.heat.max;
        }

        // max < min
        if (myValues.maxHeatSetpointLimit < myValues.minHeatSetpointLimit) {
          myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
        }

        // Check absolute and internal relational values for cool limits.
        // min < a_min
        if (myValues.minCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min) {
          myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.min;
        }

        // max > a_max
        if (myValues.maxCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max) {
          myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
        }

        // min > a_max
        if (myValues.minCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max) {
          myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.min;
        }

        // max < a_min
        if (myValues.maxCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min) {
          myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
        }

        // max < min
        if (myValues.minCoolSetpointLimit > myValues.maxCoolSetpointLimit) {
          myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
        }

        // we have to adjust the whole range as there is not enough room for the deadband.
        if (
          myValues.minHeatSetpointLimit >
          myValues.maxCoolSetpointLimit - deadband
        ) {
          // dominant movement: to the right
          if (myDirection > 0) {
            // not enough room for deadband to shove everything to the right.
            if (
              myValues.minHeatSetpointLimit + deadband >
              myDefaultAbsoluteLimits.cool.max
            ) {
              // move min heat to left of abs max cool by deadband.
              myValues.minHeatSetpointLimit =
                myDefaultAbsoluteLimits.cool.max - deadband;
              myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
              myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
              myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
            } else {
              // enough room to shove to the right.
              myValues.maxCoolSetpointLimit =
                myValues.minHeatSetpointLimit + deadband;
            }
          } else {
            // not enough room for deadband to shove everything to the left.
            if (
              myValues.maxCoolSetpointLimit - deadband <
              myDefaultAbsoluteLimits.heat.min
            ) {
              // move max cool to right of abs min heat by deadband.
              myValues.maxCoolSetpointLimit =
                myDefaultAbsoluteLimits.heat.min + deadband;
              myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
              myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
              myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
            } else {
              // enough room to shove to the left.
              myValues.minHeatSetpointLimit =
                myValues.maxCoolSetpointLimit - deadband;
            }
          }
        }

        // min heat and min cool not honoring deadband
        if (
          myValues.minHeatSetpointLimit >
          myValues.minCoolSetpointLimit - deadband
        ) {
          if (myDirection > 0) {
            // need to shove min cool to right
            if (myMode === 'heat') {
              // if min cool + deadband > abs max cool (no room to shove it to the right)
              if (
                myValues.minCoolSetpointLimit + deadband >
                myDefaultAbsoluteLimits.cool.max
              ) {
                myValues.minCoolSetpointLimit =
                  myDefaultAbsoluteLimits.cool.max;
                myValues.maxCoolSetpointLimit = myValues.minCoolSetpointLimit;
                myValues.minHeatSetpointLimit =
                  myDefaultAbsoluteLimits.cool.max - deadband;
                myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
              } else {
                myValues.minCoolSetpointLimit =
                  myValues.minHeatSetpointLimit + deadband;
              }
            } else {
              // no room to shove min heat to left
              console.log(
                '[Thermostat Setpoint AutoAdjust][Min heat at abs min] >>>>>>>>>>>>>> should not happen',
              );
              if (
                myValues.minHeatSetpointLimit - deadband <
                myDefaultAbsoluteLimits.heat.min
              ) {
                myValues.minHeatSetpointLimit =
                  myDefaultAbsoluteLimits.heat.min;
                myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
                myValues.minCoolSetpointLimit =
                  myDefaultAbsoluteLimits.heat.min + deadband;
                myValues.maxCoolSetpointLimit = myValues.minCoolSetpointLimit;
              } else {
                myValues.minHeatSetpointLimit =
                  myValues.minCoolSetpointLimit - deadband;
              }
            }
          } else {
            // need to shove min cool to right
            if (myMode === 'heat') {
              // if min cool + deadband > abs max cool (no room to shove it to the right)
              console.log(
                '[Thermostat Setpoint AutoAdjust][Min cool at abs max] >>>>>>>>>>>>>> should not happen',
              );
              if (
                myValues.minCoolSetpointLimit + deadband >
                myDefaultAbsoluteLimits.cool.max
              ) {
                myValues.minCoolSetpointLimit =
                  myDefaultAbsoluteLimits.cool.max;
                myValues.maxCoolSetpointLimit = myValues.minCoolSetpointLimit;
                myValues.minHeatSetpointLimit =
                  myDefaultAbsoluteLimits.cool.max - deadband;
                myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
              } else {
                myValues.minCoolSetpointLimit =
                  myValues.minHeatSetpointLimit + deadband;
              }
            } else {
              // no room to shove min heat to left
              if (
                myValues.minHeatSetpointLimit - deadband <
                myDefaultAbsoluteLimits.heat.min
              ) {
                myValues.minHeatSetpointLimit =
                  myDefaultAbsoluteLimits.heat.min;
                myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
                myValues.minCoolSetpointLimit =
                  myDefaultAbsoluteLimits.heat.min + deadband;
                myValues.maxCoolSetpointLimit = myValues.minCoolSetpointLimit;
              } else {
                myValues.minHeatSetpointLimit =
                  myValues.minCoolSetpointLimit - deadband;
              }
            }
          }
        }

        // max heat and max cool not honoring deadband
        if (
          myValues.maxHeatSetpointLimit >
          myValues.maxCoolSetpointLimit - deadband
        ) {
          if (myDirection > 0) {
            // need to shove max cool to right
            if (myMode === 'heat') {
              // if max heat + deadband > abs max cool (no room to shove it to the right)
              if (
                myValues.maxHeatSetpointLimit + deadband >
                myDefaultAbsoluteLimits.cool.max
              ) {
                myValues.maxCoolSetpointLimit =
                  myDefaultAbsoluteLimits.cool.max;
                myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
                myValues.maxHeatSetpointLimit =
                  myDefaultAbsoluteLimits.cool.max - deadband;
                myValues.minHeatSetpointLimit = myValues.maxHeatSetpointLimit;
              } else {
                myValues.maxCoolSetpointLimit =
                  myValues.maxHeatSetpointLimit + deadband;
              }
            } else {
              console.warn(
                '[Thermostat Setpoint AutoAdjust][Max heat at abs max] >>>>>>>>>>>>>> this should not happen',
              );
            }
          } else {
            // need to shove max heat to left
            if (myMode === 'heat') {
              console.warn(
                '[Thermostat Setpoint AutoAdjust][Max heat at abs max] this should not happen',
              );
            } else {
              // no room to shove max heat to left
              if (
                myValues.maxHeatSetpointLimit - deadband <
                myDefaultAbsoluteLimits.heat.min
              ) {
                myValues.maxHeatSetpointLimit =
                  myDefaultAbsoluteLimits.heat.min;
                myValues.minHeatSetpointLimit = myValues.maxHeatSetpointLimit;
                myValues.maxCoolSetpointLimit =
                  myDefaultAbsoluteLimits.heat.min + deadband;
                myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
              } else {
                myValues.maxHeatSetpointLimit =
                  myValues.maxCoolSetpointLimit - deadband;
              }
            }
          }
        }
        // at this point, the min / max values are properly aligned within the absolute limits
        // and respect the deadband. Now for the setpoints.

        if (myValues.heatSetpoint < myValues.minHeatSetpointLimit) {
          myValues.heatSetpoint = myValues.minHeatSetpointLimit;
        }
        if (myValues.heatSetpoint > myValues.maxHeatSetpointLimit) {
          myValues.heatSetpoint = myValues.maxHeatSetpointLimit;
        }

        if (myValues.coolSetpoint < myValues.minCoolSetpointLimit) {
          myValues.coolSetpoint = myValues.minCoolSetpointLimit;
        }
        if (myValues.coolSetpoint > myValues.maxCoolSetpointLimit) {
          myValues.coolSetpoint = myValues.maxCoolSetpointLimit;
        }

        // setpoints are within respective scale limits. Now for the deadband ...

        if (myValues.heatSetpoint > myValues.coolSetpoint - deadband) {
          // can I move the cool setpoint without hitting the max cool limit?
          if (
            myValues.heatSetpoint + deadband >
            myValues.maxCoolSetpointLimit
          ) {
            // nope ... so, set cool to max cool limit and adjust heat setpoint
            if (
              myValues.maxCoolSetpointLimit - deadband <
              myValues.minHeatSetpointLimit
            ) {
              // this is an error condition. Should not happen. But ... so just set heat
              // to min heat setpoint limit, disregarding the deadband ... but log the issue.
              Notifier.warn(
                'Unsolvable setpoint configuration. Violating deadband to set config.',
              );
              myValues.coolSetpoint = myValues.maxCoolSetpointLimit;
              myValues.heatSetpoint = myValues.minHeatSetpointLimit;
            } else {
              // we have room to move the heat setpoint once we move the cool setpoint so do it.
              myValues.coolSetpoint = myValues.maxCoolSetpointLimit;
              myValues.heatSetpoint = myValues.maxCoolSetpointLimit - deadband;
            }
          } else {
            // yes... so, set cool setpoint to heat setpoint + deadband.
            myValues.coolSetpoint = myValues.heatSetpoint + deadband;
          }
        }
      } else if (myValues.operatingMode === 'Heat') {
        // Check absolute and internal relational values for heat limits.
        // min < a_min
        if (myValues.minHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min) {
          myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
        }

        // max > a_max
        if (myValues.maxHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max) {
          myValues.maxHeatSetpointLimit = myDefaultAbsoluteLimits.heat.max;
        }

        // min > a_max
        if (myValues.minHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max) {
          myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
        }

        // max < a_min
        if (myValues.maxHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min) {
          myValues.maxHeatSetpointLimit = myDefaultAbsoluteLimits.heat.max;
        }

        // max < min
        if (myValues.maxHeatSetpointLimit < myValues.minHeatSetpointLimit) {
          myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
        }

        // adjust heat setpoint to ensure it is within setpoint limits.
        if (myValues.heatSetpoint < myValues.minHeatSetpointLimit) {
          myValues.heatSetpoint = myValues.minHeatSetpointLimit;
        }
        if (myValues.heatSetpoint > myValues.maxHeatSetpointLimit) {
          myValues.heatSetpoint = myValues.maxHeatSetpointLimit;
        }
      } else if (myValues.operatingMode === 'Cool') {
        // Check absolute and internal relational values for cool limits.
        // min < a_min
        if (myValues.minCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min) {
          myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.min;
        }

        // max > a_max
        if (myValues.maxCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max) {
          myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
        }

        // min > a_max
        if (myValues.minCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max) {
          myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.min;
        }

        // max < a_min
        if (myValues.maxCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min) {
          myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
        }

        // max < min
        if (myValues.minCoolSetpointLimit > myValues.maxCoolSetpointLimit) {
          myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
        }

        // adjust cool setpoint to ensure it is within setpoint limits.
        if (myValues.coolSetpoint < myValues.minCoolSetpointLimit) {
          myValues.coolSetpoint = myValues.minCoolSetpointLimit;
        }
        if (myValues.coolSetpoint > myValues.maxCoolSetpointLimit) {
          myValues.coolSetpoint = myValues.maxCoolSetpointLimit;
        }
      }

      const changes: Record<string, number | string | boolean> = {};
      [
        'maxHeatSetpointLimit',
        'minHeatSetpointLimit',
        'maxCoolSetpointLimit',
        'minCoolSetpointLimit',
        'heatSetpoint',
        'coolSetpoint',
      ].forEach((key) => {
        const myKey = key as keyof CurrentAttributes;
        if (baseValues[myKey] !== myValues[myKey]) {
          changes[myKey] = myValues[myKey] as number;
        }
      });

      return changes;
    },
    [newValues, preferredUnits],
  );

  const handleSetpointLimitChange = React.useCallback(
    (
      key: string,
      newValue: number,
    ): Record<string, number | string | boolean> => {
      if (newValues !== undefined) {
        const myKey = key as keyof CurrentAttributes;
        let direction = 0;
        let mode;
        let limit;

        switch (key) {
          case 'maxCoolSetpointLimit':
            mode = 'cool';
            limit = 'max';
            break;
          case 'minCoolSetpointLimit':
            mode = 'cool';
            limit = 'min';
            break;
          case 'maxHeatSetpointLimit':
            mode = 'heat';
            limit = 'max';
            break;
          case 'minHeatSetpointLimit':
            mode = 'heat';
            limit = 'min';
            break;
          default:
            console.log(
              '[thermostat-handleSetpointLimitChange] unknown key:',
              key,
            );
            return {};
        }

        if (newValues[myKey] === newValue) {
          return {};
        } else if (((newValues[myKey] as number) ?? 0) < newValue) {
          direction = 1;
        } else {
          direction = -1;
        }

        const changes = { [key]: newValue };
        const myValues = { ...newValues, ...changes };

        const conformingChanges = conformToSetpointLimits(
          myValues,
          mode,
          limit,
          direction,
        );

        if (conformingChanges !== null) {
          const newChanges = { ...changes, ...conformingChanges };

          handleChange(newChanges);
          return newChanges;
        } else {
          return {};
        }
      } else {
        return {};
      }
    },
    [conformToSetpointLimits, handleChange, newValues],
  );

  const changeTarget = (targetMode: 'heat' | 'cool', delta: number) => {
    const myDefaultAbsoluteLimits = defaultAbsoluteLimits[preferredUnits];
    const myValues = { ...newValues };
    const isAutoMode = myValues.operatingMode === 'Auto';

    const mode = targetMode;
    const deadband = preferredUnits === Celsius ? 1 : 2;
    const usesCustomLimits = myValues.useCustomSetpointLimits ?? false;

    const limits = {
      heat: {
        min:
          (usesCustomLimits
            ? myValues.minHeatSetpointLimit
            : myValues.baseMinHeatSetpointLimit) ??
          myDefaultAbsoluteLimits.heat.min,
        max:
          (usesCustomLimits
            ? myValues.maxHeatSetpointLimit
            : myValues.baseMaxHeatSetpointLimit) ??
          myDefaultAbsoluteLimits.heat.max,
      },
      cool: {
        min:
          (usesCustomLimits
            ? myValues.minCoolSetpointLimit
            : myValues.baseMinCoolSetpointLimit) ??
          myDefaultAbsoluteLimits.cool.min,
        max:
          (usesCustomLimits
            ? myValues.maxCoolSetpointLimit
            : myValues.baseMaxCoolSetpointLimit) ??
          myDefaultAbsoluteLimits.cool.max,
      },
    };

    const newSetpoint = {
      heat:
        myValues.heatSetpoint ?? defaultAbsoluteLimits[preferredUnits].heat.min,
      cool:
        myValues.coolSetpoint ?? defaultAbsoluteLimits[preferredUnits].cool.max,
    };
    newSetpoint[mode] = newSetpoint[mode] + delta;

    // Compare new setpoint to the limits for the selected properties/units/tstats
    // Only allow the change if within limits
    if (
      newSetpoint[mode] > limits[mode]?.max ||
      newSetpoint[mode] < limits[mode]?.min
    ) {
      // New setpoint value exceeds the limits - reject the change
      if (newSetpoint[mode] > limits[mode]?.max) {
        newSetpoint[mode] = limits[mode]?.max;
      } else {
        newSetpoint[mode] = limits[mode]?.min;
      }
      let warning = `minimum: ${formatTemperatureString(
        limits[mode].min,
        preferredUnits,
      )}`;
      if (newSetpoint[mode] > limits[mode].max) {
        warning = `maximum: ${formatTemperatureString(
          limits[mode].max,
          preferredUnits,
        )}`;
      }
      Notifier.warn(`${warning}`, `${mode} setpoint limit exceeded`);
    }
    // Ok, the new setpoint value is within the limits

    // If operatingMode is Auto, ensure the deadband of cool - heat <= deadband
    if (isAutoMode) {
      if (newSetpoint.cool - newSetpoint.heat < deadband) {
        if (mode === 'cool') {
          // push down heat setpoint
          if (newSetpoint.cool - deadband < limits.heat.min) {
            // Deadband shift of heat setpoint exceeds min heat limit - reject the change
            newSetpoint.heat = limits.heat.min;
            Notifier.warn(
              `Deadband Violation: Heat setpoint minimum: ${formatTemperatureString(
                limits.heat.min,
                preferredUnits,
              )}`,
            );
          } else {
            newSetpoint.heat = newSetpoint.cool - deadband;
          }
        } else {
          // push up cool setpoint
          if (newSetpoint.heat + deadband > limits.cool.max) {
            // Deadband shift of cool setpoint exceeds max cool limit - reject the change
            newSetpoint.cool = limits.cool.max;

            Notifier.warn(
              `Deadband Violation: Cool setpoint maximum: ${formatTemperatureString(
                limits.cool.max,
                preferredUnits,
              )}`,
            );
          } else {
            newSetpoint.cool = newSetpoint.heat + deadband;
          }
        }
      }
    }

    // New setpoint(s) preserve the deadband (if necessary) and are within the
    // limits - save and update
    const changes: Record<string, number | string> = {};
    if (myValues.coolSetpoint !== newSetpoint.cool) {
      changes.coolSetpoint = newSetpoint.cool;
    }
    if (myValues.heatSetpoint !== newSetpoint.heat) {
      changes.heatSetpoint = newSetpoint.heat;
    }

    // Check whether the hasChanges flag needs to be updated
    handleChange(changes);
  };

  const handleScheduleChange = (
    operation: 'edit_setpoints' | 'edit_time',
    dayOfWeek: DayOfWeek,
    timeOfDay: TimeOfDay,
    newTimeOfDay?: TimeOfDay,
    scheduleSetting?: MyScheduleSetting,
  ) => {
    // Create a copy of the current schedule object, or a new schedule object if none exists
    const _newSchedule = _.cloneDeep(newSchedule);
    if (newSchedule && newSchedule.value) {
      _newSchedule.value = _.cloneDeep(newSchedule.value);
    } else {
      _newSchedule.value = _.cloneDeep(EmptyWeeklySchedule);
    }

    // Perform the specified update, for the specified day of the week
    switch (operation) {
      case 'edit_setpoints':
        _newSchedule.value[dayOfWeek] = {
          ..._newSchedule.value[dayOfWeek],
          [timeOfDay]: scheduleSetting ?? {},
        };
        break;
      case 'edit_time':
        if (newTimeOfDay && scheduleSetting) {
          const timeKeys = Object.keys(_newSchedule.value[dayOfWeek])
            .map((timeString) => parseInt(timeString, 10))
            .sort((a, b) => a - b);
          const currentTimeIndex = timeKeys.indexOf(parseInt(timeOfDay, 10));
          const minTimeLimit =
            currentTimeIndex === 0 ? -1 : timeKeys[currentTimeIndex - 1];
          const maxTimeLimit =
            currentTimeIndex === timeKeys.length - 1
              ? 24 * 60 * 60 * 1000
              : timeKeys[currentTimeIndex + 1];

          const proposedNewTimeOfDay = parseInt(newTimeOfDay, 10);
          if (proposedNewTimeOfDay <= minTimeLimit) {
            // New timeOfDay value is less than the previous timeOfDay value - reject the change
            Notifier.warn(
              `You must choose a time value between the times set for the previous and next events in the schedule.`,
            );
            throw new Error('Out of sequence time value');
          } else if (proposedNewTimeOfDay >= maxTimeLimit) {
            // New timeOfDay value is greater than the next timeOfDay value - reject the change
            Notifier.warn(
              `You must choose a time value between the times set for the previous and next events in the schedule.`,
            );
            throw new Error('Out of sequence time value');
          } else {
            delete _newSchedule.value[dayOfWeek][timeOfDay];
            _newSchedule.value[dayOfWeek][newTimeOfDay] =
              _.cloneDeep(scheduleSetting);
          }
        }
        break;
    }

    // Ensure the update is applied to all appropriate days of the week, according to the schedule type
    const updatedDailySchedule = _newSchedule.value[dayOfWeek];
    if (scheduleType === EnumScheduleType.SameEveryDay) {
      // Apply update to Sunday's schedule to all other days of the week
      _newSchedule.value[DayOfWeek.MO] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.TU] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.WE] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.TH] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.FR] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.SA] = updatedDailySchedule;
    } else if (scheduleType === EnumScheduleType.WeekdayWeekend) {
      if (dayOfWeek === DayOfWeek.SU) {
        // Apply update to Sunday's schedule to the rest of the weekend
        _newSchedule.value[DayOfWeek.SA] = updatedDailySchedule;
      } else if (dayOfWeek === DayOfWeek.MO) {
        // Apply update to Monday's schedule to the rest of the weekdays
        _newSchedule.value[DayOfWeek.TU] = updatedDailySchedule;
        _newSchedule.value[DayOfWeek.WE] = updatedDailySchedule;
        _newSchedule.value[DayOfWeek.TH] = updatedDailySchedule;
        _newSchedule.value[DayOfWeek.FR] = updatedDailySchedule;
      }
    }

    const hasChanges = !areSchedulesEqual(
      schedule?.value ?? EmptyWeeklySchedule,
      _newSchedule.value,
    );

    setNewSchedule(_newSchedule as MyScheduleObject);
    setViewMode(hasChanges ? ViewMode.SCHEDULE : ViewMode.SCHEDULE_UNCHANGED);
  };

  const handleScheduleTypeChange = (scheduleType: EnumScheduleType) => {
    const _newScheduleClone = {
      ..._.cloneDeep(newSchedule),
      value: convertToPreferredWeeklyScheduleUnits(
        _.cloneDeep(newSchedule.value),
        TemperatureUnit.C,
        user?.preferredUnits ?? TemperatureUnit.C,
      ),
    };

    const _scheduleClone = {
      ..._.cloneDeep(schedule),
      value: convertToPreferredWeeklyScheduleUnits(
        _.cloneDeep(schedule.value),
        TemperatureUnit.C,
        user?.preferredUnits ?? TemperatureUnit.C,
      ),
    };

    const _dailyScheduleDefaultClone = _.cloneDeep(_dailyScheduleDefault);

    if (scheduleType === EnumScheduleType.SameEveryDay) {
      // Set all daily schedules to the same value
      let _dailySchedule: MyDailySchedule;

      if (
        _.isEqual(_scheduleClone.value, EmptyWeeklySchedule) &&
        _.isEqual(_newScheduleClone.value, EmptyWeeklySchedule)
      ) {
        _dailySchedule = _dailyScheduleDefaultClone;
      } else if (
        !_.isEqual(_scheduleClone.value, EmptyWeeklySchedule) &&
        _.isEqual(_newScheduleClone.value, EmptyWeeklySchedule)
      ) {
        _dailySchedule = _scheduleClone.value[DayOfWeek.SU];
      } else {
        _dailySchedule = _newScheduleClone.value[DayOfWeek.SU];
      }

      const dailySchedule = _dailySchedule;

      _newScheduleClone.value[DayOfWeek.SU] = _.cloneDeep(dailySchedule);
      _newScheduleClone.value[DayOfWeek.MO] = _.cloneDeep(dailySchedule);
      _newScheduleClone.value[DayOfWeek.TU] = _.cloneDeep(dailySchedule);
      _newScheduleClone.value[DayOfWeek.WE] = _.cloneDeep(dailySchedule);
      _newScheduleClone.value[DayOfWeek.TH] = _.cloneDeep(dailySchedule);
      _newScheduleClone.value[DayOfWeek.FR] = _.cloneDeep(dailySchedule);
      _newScheduleClone.value[DayOfWeek.SA] = _.cloneDeep(dailySchedule);
    } else if (scheduleType === EnumScheduleType.WeekdayWeekend) {
      let _weekendSchedule: MyDailySchedule;
      let _weekdaySchedule: MyDailySchedule;

      // Set all weekday and weekend schedules to the same values
      if (
        _.isEqual(_scheduleClone.value, EmptyWeeklySchedule) &&
        _.isEqual(_newScheduleClone.value, EmptyWeeklySchedule)
      ) {
        _weekendSchedule = _dailyScheduleDefaultClone;
        _weekdaySchedule = _dailyScheduleDefaultClone;
      } else if (
        !_.isEqual(_scheduleClone.value, EmptyWeeklySchedule) &&
        _.isEqual(_newScheduleClone.value, EmptyWeeklySchedule)
      ) {
        _weekendSchedule = _scheduleClone.value[DayOfWeek.SU];
        _weekdaySchedule = _scheduleClone.value[DayOfWeek.MO];
      } else {
        _weekendSchedule = _newScheduleClone.value[DayOfWeek.SU];
        _weekdaySchedule = _newScheduleClone.value[DayOfWeek.MO];
      }

      _newScheduleClone.value[DayOfWeek.MO] = _.cloneDeep(_weekdaySchedule);
      _newScheduleClone.value[DayOfWeek.TU] = _.cloneDeep(_weekdaySchedule);
      _newScheduleClone.value[DayOfWeek.WE] = _.cloneDeep(_weekdaySchedule);
      _newScheduleClone.value[DayOfWeek.TH] = _.cloneDeep(_weekdaySchedule);
      _newScheduleClone.value[DayOfWeek.FR] = _.cloneDeep(_weekdaySchedule);
      _newScheduleClone.value[DayOfWeek.SA] = _.cloneDeep(_weekendSchedule);
      _newScheduleClone.value[DayOfWeek.SU] = _.cloneDeep(_weekendSchedule);
    } else if (scheduleType === EnumScheduleType.Custom) {
      const defaultWeeklySchedule: MyWeeklySchedule = {
        [DayOfWeek.SU]: _.cloneDeep(_dailyScheduleDefault),
        [DayOfWeek.MO]: _.cloneDeep(_dailyScheduleDefault),
        [DayOfWeek.TU]: _.cloneDeep(_dailyScheduleDefault),
        [DayOfWeek.WE]: _.cloneDeep(_dailyScheduleDefault),
        [DayOfWeek.TH]: _.cloneDeep(_dailyScheduleDefault),
        [DayOfWeek.FR]: _.cloneDeep(_dailyScheduleDefault),
        [DayOfWeek.SA]: _.cloneDeep(_dailyScheduleDefault),
      };

      let _weeklySchedule: MyWeeklySchedule;

      if (
        _.isEqual(_scheduleClone.value, EmptyWeeklySchedule) &&
        _.isEqual(_newScheduleClone, EmptyWeeklySchedule)
      ) {
        _weeklySchedule = defaultWeeklySchedule;
      } else if (
        !_.isEqual(_scheduleClone.value, EmptyWeeklySchedule) &&
        _.isEqual(_newScheduleClone.value, EmptyWeeklySchedule)
      ) {
        _weeklySchedule = _scheduleClone.value;
      } else {
        _weeklySchedule = _newScheduleClone.value;
      }

      _newScheduleClone.value = _weeklySchedule;
    } else if (scheduleType === EnumScheduleType.None) {
      _newScheduleClone.value = _.cloneDeep(EmptyWeeklySchedule);
    }

    const hasRecentChanges = !areSchedulesEqual(
      _scheduleClone.value,
      _newScheduleClone.value,
    );

    if (device) {
      const limits = {
        cool: {
          min: device.minCoolSetpointLimit?.value as number | undefined, // Types must be a number or undefined
          max: device.maxCoolSetpointLimit?.value as number | undefined,
        },
        heat: {
          min: device.minHeatSetpointLimit?.value as number | undefined,
          max: device.maxHeatSetpointLimit?.value as number | undefined,
        },
      };

      const adjustedSchedule = _.cloneDeep(_newScheduleClone);

      adjustedSchedule.value = applySetpointLimitToWeeklyScheduleValues(
        _newScheduleClone.value as MyWeeklySchedule | undefined,
        limits,
      ) as MyWeeklySchedule;

      if (user) {
        if (adjustedSchedule.value !== undefined) {
          adjustedSchedule.value = convertToPreferredWeeklyScheduleUnits(
            adjustedSchedule.value,
            user.preferredUnits,
            TemperatureUnit.C,
          );
        }
      }

      setNewSchedule(adjustedSchedule);
    } else {
      setNewSchedule(_newScheduleClone);
    }

    setViewMode(
      hasRecentChanges ? ViewMode.SCHEDULE : ViewMode.SCHEDULE_UNCHANGED,
    );

    setScheduleType(scheduleType);
  };

  /**
   * handleMenuClick: set the ViewMode based on a given MenuAction
   */
  const handleMenuClick = (action: MenuAction) => {
    switch (action) {
      case MenuAction.CANCEL:
        setViewMode(ViewMode.NORMAL);
        setNewValues(undefined);
        break;
      case MenuAction.EDIT_SCHEDULE:
        setViewMode(ViewMode.SCHEDULE_UNCHANGED);
        const scheduleClone = _.cloneDeep(schedule);
        const newScheduleClone = _.cloneDeep(newSchedule);
        if (device?.useCustomSchedule) {
          if (!device?.thermostatScheduleTemplateId) {
            if (newSchedule && newSchedule.value) {
              newScheduleClone.value = (scheduleClone && {
                ..._.cloneDeep(scheduleClone.value),
              }) || {
                ..._.cloneDeep(EmptyWeeklySchedule),
              };
              setNewSchedule(newScheduleClone);
              if (_.isEqual(newScheduleClone.value, EmptyWeeklySchedule)) {
                setScheduleType(EnumScheduleType.None);
              } else if (device.scheduleType) {
                setScheduleType(device.scheduleType);
              } else {
                setScheduleType(EnumScheduleType.None);
              }
            } else {
              setNewSchedule({ ...scheduleClone } as MyScheduleObject);
            }
          }
        }
        break;
      case MenuAction.EDIT_VALUES:
        const changes = conformToSetpointLimits(
          originalValues,
          undefined,
          undefined,
          undefined,
          true,
        );
        if (Object.keys(changes ?? {}).length > 0) {
          Notifier.warn(
            'Current values have been adjusted in the editor to comply with pre-set limits and rules.',
          );
        }
        setNewValues({ ...originalValues, ...changes } as CurrentAttributes);
        setViewMode(ViewMode.EDIT_UNCHANGED);
        break;
      case MenuAction.REVERT_CHANGES:
        setViewMode(ViewMode.NORMAL);
        setNewValues(undefined);
        setNewSchedule(DefaultSchedule);
        setScheduleType(data?.thermostatById?.scheduleType ?? undefined);
        break;
      case MenuAction.SAVE_CHANGES:
        if (device && originalValues && newValues) {
          setViewMode(ViewMode.UPDATING);
          setTimeout(() => {
            // Revert to normal view mode if update doesn't complete after 30s.
            // While most thermostat updates should complete in a matter of seconds,
            // occasionally the zigbee network is slow and the update takes longer.
            // If we revert too soon while in AWAITING view mode, this might trigger
            // the "Thermostat being updated on another device" warning, because the
            // `hasPendingUpdates` flag hasn't been cleared on the server yet.

            const updates: {
              showFanButton?: InputMaybe<boolean>;
              fanMode?: InputMaybe<string>;
              systemType?: InputMaybe<string>;
              schedule?: InputMaybe<any>;
              operatingMode?: InputMaybe<string>;
              heatSetpoint?: InputMaybe<number>;
              coolSetpoint?: InputMaybe<number>;
              minHeatSetpointLimit?: InputMaybe<number>;
              maxHeatSetpointLimit?: InputMaybe<number>;
              minCoolSetpointLimit?: InputMaybe<number>;
              maxCoolSetpointLimit?: InputMaybe<number>;
              progMode?: InputMaybe<string>;
              holdState?: InputMaybe<string>;
              keypadLockoutLevel?: InputMaybe<number>;
            } = {};

            if (
              newValues.keypadLockoutLevel !== originalValues.keypadLockoutLevel
            ) {
              updates.keypadLockoutLevel = parseInt(
                newValues.keypadLockoutLevel ?? '0',
              ) as InputMaybe<number>;
            }

            if (newValues.operatingMode !== originalValues.operatingMode) {
              updates.operatingMode =
                newValues.operatingMode as InputMaybe<string>;
            }

            if (
              newValues.minHeatSetpointLimit !==
              originalValues.minHeatSetpointLimit
            ) {
              updates.minHeatSetpointLimit = convertToTempUnits(
                newValues.minHeatSetpointLimit,
                Celsius,
                preferredUnits,
                2,
              ) as InputMaybe<number>;
            }

            if (
              newValues.maxHeatSetpointLimit !==
              originalValues.maxHeatSetpointLimit
            ) {
              updates.maxHeatSetpointLimit = convertToTempUnits(
                newValues.maxHeatSetpointLimit,
                Celsius,
                preferredUnits,
                2,
              ) as InputMaybe<number>;
            }

            if (
              newValues.minCoolSetpointLimit !==
              originalValues.minCoolSetpointLimit
            ) {
              updates.minCoolSetpointLimit = convertToTempUnits(
                newValues.minCoolSetpointLimit,
                Celsius,
                preferredUnits,
                2,
              ) as InputMaybe<number>;
            }

            if (
              newValues.maxCoolSetpointLimit !==
              originalValues.maxCoolSetpointLimit
            ) {
              updates.maxCoolSetpointLimit = convertToTempUnits(
                newValues.maxCoolSetpointLimit,
                Celsius,
                preferredUnits,
                2,
              ) as InputMaybe<number>;
            }

            if (newValues.heatSetpoint !== originalValues.heatSetpoint) {
              updates.heatSetpoint = convertToTempUnits(
                newValues.heatSetpoint,
                Celsius,
                preferredUnits,
                2,
              ) as InputMaybe<number>;
            }

            if (newValues.coolSetpoint !== originalValues.coolSetpoint) {
              updates.coolSetpoint = convertToTempUnits(
                newValues.coolSetpoint,
                Celsius,
                preferredUnits,
                2,
              ) as InputMaybe<number>;
            }

            if (newValues.progMode !== originalValues.progMode) {
              updates.progMode = newValues.progMode as InputMaybe<string>;
            }

            if (newValues.holdState !== originalValues.holdState) {
              updates.holdState = newValues.holdState as InputMaybe<string>;
            }

            if (
              device.supportsShowFanButton &&
              newValues.showFanButton !== originalValues.showFanButton
            ) {
              updates.showFanButton =
                newValues?.showFanButton as InputMaybe<boolean>;
            }

            if (
              device.supportsFanOperations &&
              newValues.fanMode !== originalValues.fanMode
            ) {
              updates.fanMode = newValues?.fanMode as InputMaybe<string>;
            }

            if (
              device.supportsSystemType &&
              newValues.systemType !== originalValues.systemType
            ) {
              updates.systemType = newValues?.systemType as InputMaybe<string>;
            }

            if (newSchedule && schedule) {
              const hasChange = !areSchedulesEqual(
                schedule.value || EmptyWeeklySchedule,
                newSchedule.value || EmptyWeeklySchedule,
              );
              if (hasChange) {
                // Ensure all temperature values are converted to Celsius before saving
                const scheduleUpdate: MyWeeklySchedule =
                  _.cloneDeep(EmptyWeeklySchedule);
                for (const day in newSchedule.value) {
                  const dailySchedule = newSchedule.value[day as DayOfWeek];
                  for (const transition in dailySchedule) {
                    const scheduleSetting = dailySchedule[transition];
                    scheduleUpdate[day as DayOfWeek][transition] = {};
                    if (scheduleSetting.maximum !== undefined) {
                      scheduleUpdate[day as DayOfWeek][transition].maximum =
                        convertToTempUnits(
                          scheduleSetting.maximum,
                          Celsius,
                          preferredUnits,
                          2,
                        );
                    }

                    if (scheduleSetting.minimum !== undefined) {
                      scheduleUpdate[day as DayOfWeek][transition].minimum =
                        convertToTempUnits(
                          scheduleSetting.minimum,
                          Celsius,
                          preferredUnits,
                          2,
                        );
                    }
                  }
                }
                updates.schedule = scheduleUpdate;
              }
            }

            // Fields that can be modified in the DB, but are not attributes
            // that can be configured on the physical thermostat
            const fields: ThermostatUpdateFields = {};
            if (
              !_.isNil(newValues.useCustomSetpointLimits) &&
              newValues.useCustomSetpointLimits !==
                originalValues.useCustomSetpointLimits
            ) {
              fields.useCustomSetpointLimits =
                newValues.useCustomSetpointLimits;
            }

            // Check if schedule object contains custom values
            // If so then use custom schedule is applied
            fields.useCustomSchedule = !_.isEqual(
              newSchedule?.value,
              EmptyWeeklySchedule,
            );

            if (device?.deviceId) {
              if (
                Object.keys(updates).some((a) =>
                  [
                    'maxCoolSetpointLimit',
                    'minCoolSetpointLimit',
                    'maxHeatSetpointLimit',
                    'minHeatSetpointLimit',
                  ].includes(a),
                )
              ) {
                if (device?.schedule) {
                  if (!_.isEqual(device.schedule.value, EmptyWeeklySchedule)) {
                    updates.schedule = device.schedule.value;
                    fields.useCustomSchedule = true;
                    fields.thermostatScheduleTemplateId = '';

                    setUpdateThermostatVariables({
                      id: device.deviceId,
                      updates,
                      fields,
                      options: {},
                    });
                    setOpenThermostatScheduleUpdateDialog(true);
                  } else {
                    handleUpdateThermostat(
                      {
                        id: device.deviceId,
                        updates,
                        fields,
                        options: {},
                      },
                      undefined,
                    );
                  }
                }
              } else {
                handleUpdateThermostat(
                  {
                    id: device.deviceId,
                    updates,
                    fields,
                    options: {},
                  },
                  undefined,
                );
              }
            }
          }, 500);
        }
        break;
    }
  };

  //TODO: Calvin, refactor this method to include adjustScheduleToConformToSetpointLimits in variables state
  const handleUpdateThermostat = (
    variables: UpdateThermostatMutationVariables | undefined,
    adjustScheduleToConformToSetpointLimits: boolean | undefined,
  ) => {
    const updateTimer = setTimeout(() => setViewMode(ViewMode.NORMAL), 30000);
    if (variables !== undefined) {
      updateThermostat({
        variables: {
          id: variables.id,
          updates: variables.updates,
          fields: {
            ...variables.fields,
            adjustScheduleToConformToSetpointLimits,
          },
          options: {},
        },
      })
        .then((result) => {
          if (result.errors) {
            // An error occurred, throw it and handle it below
            throw new Error(result.errors[0].message);
          } else if (result.data?.updateThermostat.hasPendingUpdates) {
            // Thermostat has pending updates, clear the timer and wait for
            // those updates to complete
            clearTimeout(updateTimer);
          } else {
            // Thermostat has no pending updates. This is rare, but can happen
            // if the server deems that all given attributes are already in the
            // correct state, in which case it will return without error, but
            // skip sending any request to configure the thermostat. In this case,
            // we can consider the update to have completed successfully.
            Notifier.info('Thermostat update completed');
            clearTimeout(updateTimer);
            setNewValues(undefined);
            // setNewSchedule(DefaultSchedule);
            setViewMode(ViewMode.NORMAL);
          }
        })
        .catch((error) => {
          console.log(
            '[Update Thermostat Values] Got error during update:',
            error,
          );
          Notifier.error(
            'Received Error while updating Thermostat. Please try again or contact support if the problem persists.',
          );
          clearTimeout(updateTimer);
          setNewValues(undefined);
          // setSchedule(undefined);
          // setNewSchedule(DefaultSchedule);
          setViewMode(ViewMode.NORMAL);
        });
    }
  };

  const handleClearSchedule = () => {
    if (device?.deviceId) {
      const updateTimer = setTimeout(() => setViewMode(ViewMode.NORMAL), 30000);
      setViewMode(ViewMode.UPDATING);
      updateThermostat({
        variables: {
          id: device.deviceId,
          updates: {
            schedule: EmptyWeeklySchedule, // Pass in an empty schedule to clear the current one
          },
          fields: {
            useCustomSchedule: false,
            thermostatScheduleTemplateId: '',
          },
          options: {},
        },
      })
        .then((result) => {
          if (result.errors) {
            // An error occurred, throw it and handle it below
            throw new Error(result.errors[0].message);
          } else if (result.data?.updateThermostat.hasPendingUpdates) {
            // Thermostat has pending updates, clear the timer and wait for
            // those updates to complete
            clearTimeout(updateTimer);
            setNewValues(undefined);
            setNewSchedule(DefaultSchedule);
            setScheduleType(EnumScheduleType.None);
          } else {
            // Thermostat has no pending updates. This is rare, but can happen
            // if the server deems that all green attributes are already in the
            // correct state, in which case it will return without error, but
            // skip sending any request to configure the thermostat. In this case,
            // we can consider the update to have completed successfully.
            Notifier.info('Thermostat update completed');
            clearTimeout(updateTimer);
            setNewValues(undefined);
            setNewSchedule(DefaultSchedule);
            setScheduleType(EnumScheduleType.None);
            setViewMode(ViewMode.NORMAL);
          }
        })
        .catch((error) => {
          console.log(
            '[Update Thermostat Values] Got error during update:',
            error,
          );
          Notifier.error(
            'Received Error while updating Thermostat. Please try again or contact support if the problem persists.',
          );
          clearTimeout(updateTimer);
          setNewValues(undefined);
          // setSchedule(undefined);
          setNewSchedule(DefaultSchedule);
          setViewMode(ViewMode.NORMAL);
        });
    }
  };

  /**
   * display the status message when hovering over the status icon
   */
  const showStatusMessage = (msg: string) => {
    setStatusMessage(msg);
  };

  const handleExpandClick = () => {
    const changes = conformToSetpointLimits(
      originalValues,
      undefined,
      undefined,
      undefined,
      true,
    );
    setNewValues({ ...originalValues, ...changes } as CurrentAttributes);
    setExpanded(!expanded);
  };

  return originalValues && device ? (
    loading ? (
      <Card className="thermostat-loading-container">
        <CardContent className="thermostat-loading-2">
          <CircularProgress />
        </CardContent>
      </Card>
    ) : (
      <Card
        sx={{
          border: hasAlerts ? '1px solid red' : '1px solid lightgray',
          boxShadow: 2,
          p: 0,
          width: '100%',
        }}
      >
        <CardHeader
          style={{
            backgroundColor: hasAlerts ? '#ffd5d5' : '#5eb85ead',
            padding: '4px',
          }}
          avatar={
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                padding: '0px',
                width: '25px',
                height: '25px',
              }}
            >
              <ThermostatIcon />
            </Avatar>
          }
          action={
            <div
              style={{
                margin: '0px',
                padding: '0px',
                display: 'flex',
              }}
            >
              {smartPowerOutlet?._id ? (
                <SmartPowerOutletLink id={smartPowerOutlet?._id} />
              ) : null}
              {pairedSensor ? (
                <div
                  className="MuiButtonGroup-root MuiButtonGroup-text"
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    borderRight: '1px solid rgba(0, 0, 0, 0.23)',
                    minWidth: '34px',
                  }}
                >
                  {device.isPairingActive ? (
                    <LinkIcon style={{ margin: '0px', height: '42px' }} />
                  ) : (
                    <LinkOffIcon
                      color={hasAlerts ? 'error' : 'warning'}
                      style={{
                        margin: '0px',
                        height: '42px',
                      }}
                    />
                  )}
                </div>
              ) : null}
              <MenuButtons viewMode={viewMode} handleClick={handleMenuClick} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: '40px',
                }}
                className="MuiButtonGroup-root MuiButtonGroup-text"
              >
                <ToggleAlertDetailsButton
                  device={device}
                  showDivider={true}
                  expand={alertsExpanded}
                  buttonLabel="Show alerts"
                  onClick={(event) => {
                    event.preventDefault();
                    setAlertsExpanded(!alertsExpanded);
                  }}
                />
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </div>
            </div>
          }
          title={
            <Typography
              variant="h4"
              sx={{ color: '#1E3D1D', marginLeft: '10px' }}
            >
              {originalValues.name}
            </Typography>
          }
          sx={{
            borderBottom: '1px solid lightgray',
            px: 2,
            py: 1,
            backgroundColor: '#ebf7f6',
          }}
        />
        <CardContent
          sx={{
            px: 0,
            py: 0,
            '&.MuiCardContent-root:last-child': { pb: 1 },
            ...(expanded || alertsExpanded
              ? {
                  paddingBottom: '10px',
                  borderBottom: '1px solid lightgray',
                  marginBottom: '10px',
                }
              : {}),
          }}
        >
          <DisplayScreen
            preferredUnits={preferredUnits}
            temperature={originalValues.temperature as number}
            heatSetpoint={
              (newValues?.heatSetpoint as number) ||
              ((newValues ? newValues : originalValues).heatSetpoint as number)
            }
            coolSetpoint={
              (newValues?.coolSetpoint as number) ||
              ((newValues ? newValues : originalValues).coolSetpoint as number)
            }
            humidity={originalValues.humidity as number}
            systemType={originalValues.systemType as string}
            operatingMode={
              (newValues ? newValues : originalValues).operatingMode as string
            }
            operatingState={
              (newValues ? newValues : originalValues).operatingState as string
            }
            timestamp={
              device.timestamp ? (device.timestamp as number) : undefined
            }
            viewMode={viewMode}
            handleTargetChange={changeTarget}
            handleChange={handleChange}
          />
          <hr
            style={{
              padding: '0px',
              margin: '0px 0px 0px 0px',
              border: '0.5px solid lightgray',
            }}
          />
          {((viewMode === ViewMode.EDIT ||
            viewMode === ViewMode.EDIT_UNCHANGED) && (
            <SystemEdit
              thermostat={device}
              currentValues={newValues ? newValues : originalValues}
              handleUseCustomSetpointLimitsChange={
                handleUseCustomSetpointLimitsChange
              }
              handleChange={handleChange}
              handleSetpointLimitChange={handleSetpointLimitChange}
              targetUnits={preferredUnits}
            />
          )) ||
            (viewMode === ViewMode.NORMAL && (
              <StatusIcons
                thermostat={device}
                statusMessage={statusMessage}
                showStatusMessage={showStatusMessage}
                showResumeSchedule={showResumeSchedule}
                briefScheduleStatus={briefScheduleStatus}
              />
            )) ||
            ((viewMode === ViewMode.SCHEDULE ||
              viewMode === ViewMode.SCHEDULE_UNCHANGED) &&
              schedule && (
                <div style={{ paddingTop: '10px' }}>
                  <ThermostatSchedule
                    property={property as Partial<Property>}
                    unit={unit as Partial<Unit>}
                    thermostat={device}
                    schedule={newSchedule}
                    setViewMode={setViewMode}
                    scheduleType={scheduleType}
                    handleChange={handleScheduleChange}
                    handleClearSchedule={() => {
                      handleClearSchedule();
                    }}
                    handleTypeChange={handleScheduleTypeChange}
                    temperatureUnit={preferredUnits}
                    lastModified={schedule.timestamp}
                  />
                </div>
              )) ||
            (viewMode === ViewMode.UPDATING && (
              <UpdatePending status={'submitted'} />
            )) ||
            (viewMode === ViewMode.AWAITING && (
              <UpdatePending status={'awaiting'} />
            ))}
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <DeviceInfoBox infoEntries={deviceInfo} />
        </Collapse>
        <DeviceAlerts device={device} alertsExpanded={alertsExpanded} />
        <ThermostatScheduleUpdateDialog
          thermostat={device}
          setpointLimits={{
            maxCoolSetpointLimit: updateThermostatVariables?.updates
              ?.maxCoolSetpointLimit as number,
            minCoolSetpointLimit: updateThermostatVariables?.updates
              ?.minCoolSetpointLimit as number,
            maxHeatSetpointLimit: updateThermostatVariables?.updates
              ?.maxHeatSetpointLimit as number,
            minHeatSetpointLimit: updateThermostatVariables?.updates
              ?.minHeatSetpointLimit as number,
          }}
          open={openThermostatScheduleUpdateDialog}
          handleCloseThermostatScheduleUpdateDialog={() =>
            setOpenThermostatScheduleUpdateDialog(false)
          }
          handleUpdateThermostat={(autoAdjust: boolean) =>
            handleUpdateThermostat(updateThermostatVariables, autoAdjust)
          }
          handleSetViewModeToNormal={() => setViewMode(ViewMode.NORMAL)}
        />
      </Card>
    )
  ) : (
    <Card className="thermostat-loading-container">
      <CardContent className="thermostat-loading-2">
        <CircularProgress />
      </CardContent>
    </Card>
  );
}
