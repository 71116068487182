import React, { useEffect } from 'react';

import {
  useTestBoilerListUpdateSubscription,
  useTestBoilersQuery,
} from '../../../types/generated-types';

import { Notifier } from '../../system/services/notificationManager';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';

export const BoilerList = () => {
  useTestBoilerListUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const {
    data: boilersData,
    error: boilersError,
    loading: boilersLoading,
    // subscribeToMore: boilersSubscribeToMore,
    // client: boilersClient,
    // variables: boilersVariables,
  } = useTestBoilersQuery();

  useEffect(() => {
    if (boilersError) {
      Notifier.error(
        `[Data Error] Unable to display properties data. You are not logged in. ${boilersError}`,
      );
    }
  }, [boilersError]);

  return (
    <div className="Subscriptions-col">
      <p>Boilers</p>
      {boilersLoading ? (
        <p> Boiler List Loading </p>
      ) : boilersData ? (
        <div className="Subscriptions-col">
          <ul>
            {boilersData.boilers.map((boiler) => (
              <li key={boiler._id}>
                {boiler.name || boiler.meta?.name || 'No name provided'}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p> {boilersError?.message || 'No Boilers Found'} </p>
      )}
    </div>
  );
};
