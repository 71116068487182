import dayjs from 'dayjs';
import { convertToTempUnits } from '../../devices/util';
import { SetpointLimits } from '../../../../types/generated-types';
import { TStatConfig } from '../types';
import { TemperatureUnit } from '../../../system/models/temperatureUnits';

/**
 * Estimate for how long it will take to commit bulk config changes
 */
export function getTStatConfigurationEstimate(numTStats: number) {
  // Assuming approximately 5 seconds per TStat config. In reality, it's probably less,
  // but it's better to display a conservative estimate to the user
  const seconds = (numTStats || 0) * 5;
  return dayjs(seconds * 1000).format('m[m]:s[s]'); // e.g '2m:30s'
}

/**
 * Convert the given setpoints to the desired target units
 */
export function convertSetpoints(
  setpoints: TStatConfig['setpoints'],
  targetUnits: TemperatureUnit,
): TStatConfig['setpoints'] {
  const { heat, cool, units } = setpoints;
  return {
    ...setpoints,
    units: targetUnits,
    heat: convertToTempUnits(heat, targetUnits, units, 0) || heat,
    cool: convertToTempUnits(cool, targetUnits, units, 0) || cool,
  };
}

/**
 * Convert the given setpoint limits to the desired target units
 */
export function convertSetpointLimits(
  setpointLimits: SetpointLimits,
  targetUnits: TemperatureUnit,
  currentUnits: TemperatureUnit,
): SetpointLimits {
  const { heat, cool } = setpointLimits;
  return {
    heat: {
      min:
        convertToTempUnits(heat.min, targetUnits, currentUnits, 0) || heat.min,
      max:
        convertToTempUnits(heat.max, targetUnits, currentUnits, 0) || heat.max,
    },
    cool: {
      min:
        convertToTempUnits(cool.min, targetUnits, currentUnits, 0) || cool.min,
      max:
        convertToTempUnits(cool.max, targetUnits, currentUnits, 0) || cool.max,
    },
  };
}
