import { Property } from '../../../../types/generated-types';
import { useFormik } from 'formik';
import { FormValues } from '../types/userDetailFormValues';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import BorderedSection from '../../shared/borderedSection';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useProperties } from '../../../system/EntityManager';

interface ViewerState {
  properties: Partial<Property>[];
  selectedPropertyIds: string[];
}

export function UserViewerTab(props: {
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) {
  const {
    formik,
    // TODO: Peter: if we don't need onChange as a prop, delete it.
    // onChange,
  } = props;
  const [viewerState, setViewerState] = useState<ViewerState>({
    properties: [],
    selectedPropertyIds: [],
  });
  const { properties } = useProperties();

  useEffect(() => {
    let selectedPropertyIds: string[] = [];
    if (properties?.length) {
      selectedPropertyIds = properties
        .filter((prop) => formik.values.viewerProperties.includes(prop._id))
        .map((prop) => prop._id);
    }
    setViewerState({ properties, selectedPropertyIds });
  }, [properties, formik]);

  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div
        style={{
          width: '100%',
        }}
      >
        <h2 style={{ margin: 0, marginBottom: '20px' }}>Viewer Settings</h2>
        <div>
          <BorderedSection
            title="Properties managed by this user"
            error={!!formik.errors.viewerProperties}
            hint={formik.errors.viewerProperties || ''}
          >
            <FormGroup style={{ marginTop: '-6px', marginLeft: '6px' }}>
              {viewerState.properties.length
                ? viewerState.properties.map((property) => {
                    return property._id ? (
                      <FormControlLabel
                        key={property._id}
                        onChange={(e, checked) => {
                          e.preventDefault();
                          formik.setFieldTouched('viewerProperties');
                          const newPropIds = checked
                            ? [...formik.values.viewerProperties, property._id]
                            : formik.values.viewerProperties.filter(
                                (id) => id !== property._id,
                              );
                          formik.setFieldValue('viewerProperties', newPropIds);
                        }}
                        control={
                          <Checkbox
                            id={property._id}
                            value={property._id}
                            checked={viewerState.selectedPropertyIds.includes(
                              property._id,
                            )}
                          />
                        }
                        label={property.title || 'no title property'}
                      />
                    ) : null;
                  })
                : null}
            </FormGroup>
          </BorderedSection>
        </div>
      </div>
    </Fade>
  );
}
