import React from 'react';
import { AlertsList } from './alerts-list';
import { useMediaQuery, useTheme } from '@mui/material';

export function AlertsMain() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        margin: isSmall ? '0px' : '10px 4px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <AlertsList />
    </div>
  );
}
