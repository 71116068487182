import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import BulkConfigStep from '../ui/bulk-config-step';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import Check from '@mui/icons-material/Check';
import { BulkConfigValidationResult } from '../../../../types/generated-types';
import { getTStatConfigurationEstimate } from '../utils/bulk-utils';

export interface BulkVerifyProps {
  validationResult?: BulkConfigValidationResult;
  error?: string;
  onBack: () => void;
  onNext: () => void;
}

// Step to verify the bulk config changes to be made, before committing them
export default function BulkVerify(props: BulkVerifyProps) {
  const { error, validationResult, onBack, onNext } = props;

  const bottomActions = [
    { name: 'Back', action: onBack, startIcon: <ArrowLeft /> },
    { name: 'Commit', action: onNext, endIcon: <Check /> },
  ];

  // Display a simple progress indicator while validation is pending
  if (!validationResult) {
    return (
      <BulkConfigStep
        title="Verify Configuration"
        topActions={[]}
        bottomActions={bottomActions}
        error={error}
      >
        {!error && <LinearProgress color="secondary" />}
      </BulkConfigStep>
    );
  }

  const { numTStats, numOnline, numOffline, numSupported, numConfigurable } =
    validationResult;

  const allTStatsOffline = numOffline === numTStats;
  const someTStatsOffline = numSupported !== numOnline;

  // TStats that already match the desired target state
  const numMatchingTStats = numSupported - numConfigurable;
  const hasMatchingTStats = numMatchingTStats > 0;

  const noSupportedTStats = !allTStatsOffline && numSupported === 0;
  const allTStatsMatch =
    !allTStatsOffline && !noSupportedTStats && numConfigurable === 0;
  const hasConfigurableTStats = numConfigurable > 0;

  return (
    <BulkConfigStep
      title="Verify Configuration"
      topActions={[]}
      bottomActions={bottomActions}
      error={error}
    >
      <p>Thermostat Count: {numTStats}</p>
      <p>Online Thermostats: {numOnline}</p>
      {someTStatsOffline && <p>Supporting Thermostats: {numSupported}</p>}
      {hasMatchingTStats && <p>Match Template: {numMatchingTStats}</p>}
      {hasConfigurableTStats && (
        <>
          <p>Need Configuring: {numConfigurable}</p>
          <p>
            Configuration Estimate:{' '}
            {getTStatConfigurationEstimate(numConfigurable)}
          </p>
        </>
      )}
      {allTStatsMatch && (
        <p>
          All online thermostats on selected properties already match the
          configuration template.
        </p>
      )}
    </BulkConfigStep>
  );
}
