import React, { useEffect } from 'react';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import {
  SmartPowerOutlet,
  useSmartPowerOutletDetailQuery,
  useSmartPowerOutletDetailUpdateSubscription,
} from '../../../../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../../../../helpers/subscriptionUtils';
import { useDeviceIsOnline } from '../../../../../system/AlertsManager';

export const SmartPowerOutletLink = ({ id }: { id: string }) => {
  const [device, setDevice] = React.useState<
    Partial<SmartPowerOutlet> | undefined
  >(undefined);
  const [isSmartPowerOutletActive, setIsSmartPowerOutletActive] =
    React.useState<boolean>(false);

  const { data } = useSmartPowerOutletDetailQuery({
    variables: { id: id ?? 'no-id' },
  });

  useSmartPowerOutletDetailUpdateSubscription({
    variables: { ids: [id] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (data?.smartPowerOutletById) {
      setDevice(data.smartPowerOutletById as Partial<SmartPowerOutlet>);
    }
  }, [data]);

  useEffect(() => {
    if (device) {
      setIsSmartPowerOutletActive(!!device.isPoweredOn);
    }
  }, [device]);

  const isDeviceOnline = useDeviceIsOnline(device);

  return device ? (
    <div
      className="MuiButtonGroup-root MuiButtonGroup-text"
      style={{
        display: 'inline-flex',
        borderRight: '1px solid rgba(0, 0, 0, 0.23)',
        height: '100%',
        marginTop: '4px',
      }}
    >
      {isSmartPowerOutletActive ? (
        <PowerIcon
          color={isDeviceOnline ? 'error' : 'warning'}
          style={{ marginRight: '10px', height: '42px' }}
        />
      ) : (
        <PowerOffIcon
          color={isDeviceOnline ? 'error' : 'warning'}
          style={{
            marginRight: '10px',
            height: '42px',
          }}
        />
      )}
    </div>
  ) : null;
};
