import {
  ApolloClient,
  ApolloLink,
  // HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  from,
  split,
} from '@apollo/client';

import { CapacitorHttp } from '@capacitor/core';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
// import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';

import {
  AUTH_ID_TOKEN_NAME,
  AUTH_REMEMBER_ME_TOKEN_NAME,
  AUTH_SESSION_EXPIRATION_TOKEN_NAME,
  AUTH_SESSION_TOKEN_NAME,
  AUTH_STATE_TOKEN_NAME,
  CommunicationAddresses,
  SERVER_GQL_SUBSCRIPTIONS_URI_SELECTOR,
  SERVER_GQL_URI_SELECTOR,
} from './config';

import { IServerToken } from '../app/auth/models';
import { LocalState } from '../app/system/services/localStateManager';
import { Log, LogCategory } from '../app/system/services/logger';
import { TokenFactoryMap } from '../app/system/SessionStateManager';

import {
  HttpHeaders,
  HttpOptions,
  HttpResponse,
} from '@capacitor/core/types/core-plugins';
import EJSON from 'ejson';
import { UnsecuredJWT } from 'jose';

// function jsonToBase64(jsonObj: any) {
//   const jsonString = JSON.stringify(jsonObj);
//   return Buffer.from(jsonString).toString('base64');
// }
//
// function base64_encode(s: string) {
//   return btoa(unescape(encodeURIComponent(s)));
// }
//
// function base64_decode(s: string) {
//   return decodeURIComponent(escape(atob(s)));
// }

export function encodeAuthorizationHeader(
  headers: Record<string, string>,
  tokenNames?: string[],
  fillWithEmptyString?: boolean,
) {
  const authHeaders: Record<string, string> = {};
  (
    tokenNames ?? [
      AUTH_REMEMBER_ME_TOKEN_NAME,
      AUTH_SESSION_EXPIRATION_TOKEN_NAME,
      AUTH_ID_TOKEN_NAME,
      AUTH_STATE_TOKEN_NAME,
      AUTH_SESSION_TOKEN_NAME,
    ]
  ).forEach((key: string) => {
    const val = LocalState.getItem<IServerToken>(key);
    if (val) {
      authHeaders[key] = EJSON.stringify(val);
    } else if (fillWithEmptyString ?? false) {
      authHeaders[key] = '';
    }
  });

  // return the headers to the context so httpLink can send them
  // TODO: Peter: URGENT: should this be less than 2 hours expiration?
  // I think it could be as low as seconds and still be effective but much more secure ...
  const token = new UnsecuredJWT(authHeaders)
    .setIssuedAt()
    .setIssuer('urn:example:issuer')
    .setAudience('urn:example:audience')
    .setExpirationTime('2h')
    .encode();

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
}

export function getAuthHeaders(
  initialHeaders: Record<string, string>,
  tokenNames?: string[],
  fillWithEmptyStrings?: boolean,
): Record<string, string> {
  const headers: Record<string, string> = encodeAuthorizationHeader(
    initialHeaders,
    tokenNames,
    fillWithEmptyStrings,
  ).headers;

  return headers;
}

/**
 * Encode the server tokens currently being tracked in local state into a single
 * 'authorization' token and add it to any existing headers for the current request.
 */
const authLink = setContext((_req, res) => {
  const { headers } = res;
  return encodeAuthorizationHeader(headers);
});

/**
 * Decode any server tokens included in the current response headers and place
 * their values into local state. Once that operation is complete, execute any
 * callback which was passed in during creation of this link.
 *
 * response for any GQL request. This callback is executed AFTER the local state
 * is updated with any server tokens included in the response headers
 * @param callback
 */
const tokenUpdaterLinkFactory = (callback?: () => void): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const headers = context.response.headers;

      Object.keys(TokenFactoryMap).forEach((key) => {
        const possibleVal = headers.get(key);
        const val = possibleVal ? EJSON.parse(possibleVal) : undefined;

        if (val) {
          LocalState.setItem(key, val);
        }
      });

      if (callback) {
        callback();
      }
      return response;
    });
  });
};

/**
 * A wrapped 'fetch' implementation which utilizes the Capacitor Http Plugin
 * to engage the native fetch APIs on mobile but use the regular fetch API on
 * desktop browsers.
 * @param input
 * @param init
 */
const capacitorWrappedFetch: typeof fetch = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  if (init) {
    const headers: HttpHeaders = (init.headers ?? {}) as HttpHeaders;

    const method = init.method?.toLowerCase();
    const data = init.body;
    const url = input as string;

    const newOptions: HttpOptions = {
      data: data,
      headers: headers,
      method: method,
      url: url,
    };

    const res: HttpResponse = await CapacitorHttp.request(newOptions);

    const newRes = new Response(JSON.stringify(res.data), {
      status: res.status,
      headers: res.headers,
    });

    return newRes;
  } else {
    return new Response('', { status: 500, statusText: 'Server Error' });
  }
};

export const generateLink = (
  addresses: CommunicationAddresses,
  callback?: () => void,
): { link: ApolloLink; wsLink: GraphQLWsLink } => {
  const { serverUrl, wsUrl } = addresses;
  const baseHttpLink = createHttpLink({
    uri: `${serverUrl}/${SERVER_GQL_URI_SELECTOR}`,
    fetch: capacitorWrappedFetch,
  });

  // TODO: Peter: leaving this here to remind me to think about if we need to or if it
  //   it would be advantageous to use a retry link strategy for if requests fail?
  // const retryLink = new RetryLink({
  //   delay: {
  //     initial: 0,
  //     max: 0,
  //     jitter: true,
  //   },
  //   attempts: {
  //     max: 0,
  //     retryIf: (error, operation) => {
  //       Log.silly('operation:', operation);
  //       if (error) {
  //         Log.error('error:', error);
  //       }
  //       return true;
  //     },
  //   },
  // });
  //
  // ...
  // const httpLink = from([authLink, tokenUpdaterLink, retryLink, baseHttpLink]);

  const tokenUpdaterLink = tokenUpdaterLinkFactory(callback);

  const httpLink = from([authLink, tokenUpdaterLink, baseHttpLink]);
  Log.silly(
    'client url: ',
    `${wsUrl}/${SERVER_GQL_URI_SELECTOR}/${SERVER_GQL_SUBSCRIPTIONS_URI_SELECTOR}`,
    LogCategory.CONNECTION,
  );

  const wsLink = new GraphQLWsLink(
    createClient({
      retryAttempts: 0,
      // keepAlive: 30000,
      // retryAttempts: Infinity,
      // retryWait: async function waitForServerHealthyBeforeRetry(retryCount) {
      //   await new Promise((resolve) => {
      //     Log.silly(`reconnect attempt #${retryCount} `);
      //     setTimeout(resolve, 1000 + 2 ** retryCount * 100);
      //   });
      // },
      // isFatalConnectionProblem: (errOrCloseEvent) => {
      //   // Log.error('error or close event', errOrCloseEvent);
      //   return false;
      // },
      url: `${wsUrl}/${SERVER_GQL_URI_SELECTOR}/${SERVER_GQL_SUBSCRIPTIONS_URI_SELECTOR}`,
      connectionParams: {
        authorization: new UnsecuredJWT({
          [AUTH_SESSION_TOKEN_NAME]: EJSON.stringify(
            LocalState.getItem<IServerToken>(AUTH_SESSION_TOKEN_NAME),
          ),
          [AUTH_SESSION_EXPIRATION_TOKEN_NAME]: EJSON.stringify(
            LocalState.getItem<IServerToken>(
              AUTH_SESSION_EXPIRATION_TOKEN_NAME,
            ),
          ),
        })
          .setIssuedAt()
          .setIssuer('urn:example:issuer')
          .setAudience('urn:example:audience')
          .setExpirationTime('2h')
          .encode(),
      },

      // TODO: Peter: remove if not needed any more. Used to listen in and respond to
      //   various events related to the web socket connection.
      // on: {
      //   message: (message) => {
      //     // Log.silly('message', message);
      //   },
      //   pong: (received, payload) => {
      //     // Log.silly('pong');
      //   },
      //   ping: (received) => {
      //     // Log.silly('ping');
      //   },
      //   closed: (event) => {
      //     // Log.silly('closed', event);
      //     // connectionClosedHandler(event);
      //   },
      //   opened: (socket) => {
      //     Log.silly('opened', socket);
      //     // connectionOpenedHandler(socket);
      //   },
      //   connected: (socket) => {
      //     // Log.silly('connected');
      //     // connectionOpenedHandler(socket);
      //   },
      //   connecting: () => {
      //     // Log.silly('connecting');
      //   },
      //   error: (error) => {
      //     Log.silly('error', error);
      //   },
      // },
    }),
  );

  // TODO: Peter: remove this at some point when we are sure we don't need this anymore.
  //    This can be used to respond to connect/reconnect events on the web socket.
  //    Can't recall why I pulled these out or tried to connect this way (outside
  //    of the WS link creation code above) as these same events COULD be listened
  //    to and responded as per the code in the link creation code above.
  // wsLink.client.on('closed', (event) => {
  //   Log.silly('on closed called');
  //   connectionClosedHandler(event);
  // });
  // wsLink.client.on('opened', (event) => {
  //   Log.silly('on opened called');
  //   connectionClosedHandler(event);
  // });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );
  return { link: splitLink, wsLink };
};

export const generateConnectedClient = (
  addresses: CommunicationAddresses,
  callback?: () => void,
  // TODO: Peter: originally had these next two as parameters to the generateConnectedClient function
  //   but it seems that they were not ultimately needed. Can remove these once we are sure
  //   that we don't need these anymore.
  // connectionClosedHandler: (event: any) => void,
  // connectionOpenedHandler: (socket: any) => void,
): {
  client: ApolloClient<NormalizedCacheObject>;
  link: GraphQLWsLink;
  addresses: CommunicationAddresses;
} => {
  Log.silly('Creating client', null, LogCategory.RENDERING);
  const { wsLink, link } = generateLink(addresses, callback);

  // Create Apollo client. Import it from here when you need to access it unless it is
  // already provided to you as a parameter to a convenient callback in your execution stack.
  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link, // splitLink,
    cache: new InMemoryCache({
      typePolicies: {
        DoorSensor: {
          fields: {
            installation: {
              merge: true,
            },
          },
        },
        IndoorSensor: {
          fields: {
            installation: {
              merge: true,
            },
          },
        },
        OccupancySensor: {
          fields: {
            installation: {
              merge: true,
            },
          },
        },
        LeakDetector: {
          fields: {
            installation: {
              merge: true,
            },
          },
        },
        Property: {
          fields: {
            address: {
              merge: true,
            },
            owner: {
              merge: true,
            },
            contact: {
              merge: true,
            },
            propMgr: {
              merge: true,
            },
          },
        },
        Thermostat: {
          fields: {
            installation: {
              merge: true,
            },
            meta: {
              merge: true,
            },
          },
        },
        Unit: {
          fields: {
            property: {
              merge: true,
            },
          },
        },
        WindowSensor: {
          fields: {
            installation: {
              merge: true,
            },
          },
        },
      },
    }),
    name: 'Super',
    version: '2.0',
  });

  // Log.silly('returning client to caller');
  // console.dir(apolloClient);

  // TODO: Peter: leaving this in for now as we may want to do some re-fetching upon reconnection.
  // apolloClient.reFetchObservableQueries(true).catch((e) => {
  //   Log.error('got error from re-fetch', e);
  // });

  return { client: apolloClient, link: wsLink, addresses };
};
