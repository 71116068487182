import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { getTStatConfigurationEstimate } from '../utils/bulk-utils';
import BulkConfigStep from '../ui/bulk-config-step';
import { BulkCommitProgress } from '../types';

export interface BulkCommitProps {
  commitProgress: BulkCommitProgress;
  error?: string;
  onTerminate: () => void;
  onRestart: () => void;
}

// Step to commit the bulk config changes and watch the progress
export default function BulkCommit(props: BulkCommitProps) {
  const {
    commitProgress: { deviceIds, numConfigured, numFailed, terminated },
    error,
    onTerminate,
    onRestart,
  } = props;
  const numRemaining = deviceIds.length - numConfigured - numFailed;

  const navigate = useNavigate();

  const done = numRemaining === 0 || terminated;

  let title = 'Committing Bulk Configuration';
  if (done && !terminated) {
    title = 'Completed Bulk Configuration';
  } else if (terminated) {
    title = 'Terminated Bulk Configuration';
  }

  const bottomActions = [
    done
      ? { name: 'Configure Another Property', action: onRestart }
      : { name: 'Terminate', action: onTerminate },
    { name: 'Done', action: () => navigate('/properties') },
  ];

  return (
    <BulkConfigStep
      title={title}
      bottomActions={bottomActions}
      topActions={[]}
      error={error}
    >
      <p>
        {deviceIds.length} Thermostats: {numConfigured} Configured, {numFailed}{' '}
        Failed, {numRemaining} {terminated ? 'Skipped' : 'Remaining'}
      </p>
      {!done && (
        <p>
          Estimated time remaining:{' '}
          {getTStatConfigurationEstimate(numRemaining)}
        </p>
      )}
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={((numConfigured + numFailed) / deviceIds.length) * 100}
      />
    </BulkConfigStep>
  );
}
